import axios from 'axios'

import { promiseReject, base64Encode } from "../utils/misc"
import { authUsernameStatic, authPasswordStatic } from "../constants/authConstants"
import { apiBaseUrl } from '../utils/config'
import errorResponse from "./errorHandlerService"

class BackendClient {

    generateToken = _ => base64Encode(`${authUsernameStatic}:${authPasswordStatic}`)

    getRequestHeader = (tokenType, excludeAccessToken) => {
        let header = {
            'Authorization': excludeAccessToken ? '' : (tokenType === 'Bearer' ? `Bearer ${localStorage.getItem('accessToken')}` : `Basic ${this.generateToken()}`)
        }
        if (excludeAccessToken) { delete header.authorization }
        return header
    }

    enableRequestInterceptors = (tokenType, excludeAccessToken) => {
        axios.interceptors.request.use(
            config => {
                config.headers = this.getRequestHeader(tokenType, excludeAccessToken)
                return config
            },
            error => promiseReject(error))
    }

    get = (path, excludeAccessToken = false, params = null) => {
        // this.enableRequestInterceptors('Bearer', false)
        return axios.get(apiBaseUrl + path + this.setQueryParams(params),
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                }
            }
        )
            .then(response => (response.data))
            .catch((error) => { errorResponse.handle(error); return error.response.data })
    }

    downloadRequestHeader = _ => {
        let header = {
            'responseType': 'blob',
            'headers': {
                "authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                'Accept': 'application/txt,application/json',
            },
        }
        return header
    }

    getDownload = (path, excludeAccessToken = false, params = null, filename) => {
        return axios.get(apiBaseUrl + path + this.setQueryParams(params), this.downloadRequestHeader())
            .then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                return response.status
            })
            .catch((error) => { errorResponse.handle(error); return error.response.data })
    }

    postDownload = (path, excludeAccessToken = false, params = null, filename) => {
        
        return axios.post(apiBaseUrl + path, params, {
            headers: { "authorization": `Bearer ${localStorage.getItem('accessToken')}` },
            responseType: 'blob'
        })
        .then(response => {
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] }); 
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', filename); 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); 
            }
            return response.status; 
        })
        .catch((error) => {
            errorResponse.handle(error); 
            return error.response ? error.response.data : { error: 'An error occurred' }; 
        });
    }

    delete = path => {
        // this.enableRequestInterceptors()
        return axios.delete(apiBaseUrl + path, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
        })
            .then(response => (response.data))
            .catch((error) => { errorResponse.handle(error); return error.response.data })
    }

    put = (path, data, stringify = true, dataAsParam = true) => {
        // this.enableRequestInterceptors()
        return axios.put(apiBaseUrl + path, dataAsParam ? { data: stringify ? JSON.stringify(data) : data } : data, { headers: this.getRequestHeader(false) })
            .then(response => response)
            .catch(error => promiseReject(error))
    }

    post = (path, body, isUploadRequest = false, tokenType = 'Bearer', isAuthFirstCall = false) => {
        const defaultFormHeader = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }
        // this.enableRequestInterceptors(tokenType)
        if (isUploadRequest) {
            const formData = new FormData()
            formData.append("file", body.file)
            return axios.post(apiBaseUrl + path, formData, { headers: defaultFormHeader })
                .then(response => response.data)
                .catch(error => (error.response && error.response.data) ? error.response.data : error)
        } else {
            if (isAuthFirstCall) {
                const formData = new FormData()
                Object.keys(body).map(key => formData.append(key, body[key]))
                return axios.post(apiBaseUrl + path, formData,
                    {
                        headers: {
                            Authorization: `Basic ${this.generateToken()}`
                        }
                    }
                )
                    .then(response => response)
                    .catch(error => error.response)
            } else {
                return axios.post(apiBaseUrl + path, body, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
                    }
                })
                    .then(response => response)
                    .catch((error) => { errorResponse.handle(error); return error.response.data })
            }
        }
    }

    openPut = (path, data, stringify = true, dataAsParam = true) => {
        // this.enableRequestInterceptors()
        return axios.put(apiBaseUrl + path, dataAsParam ? { data: stringify ? JSON.stringify(data) : data } : data, { headers: {} })
            .then(response => response)
            .catch(error => promiseReject(error))
    }

    openPost = (path, body, isUploadRequest = false, tokenType = 'Bearer', isAuthFirstCall = false) => {
        return axios.post(apiBaseUrl + path, body, {
            headers: {}
        })
            .then(response => response)
            .catch((error) => { errorResponse.handle(error); return error.response.data })
    }

    openGet = (path, excludeAccessToken = false, params = null) => {
        return axios.get(apiBaseUrl + path + this.setQueryParams(params), { headers: {} })
            .then(response => (response.data))
            .catch((error) => { errorResponse.handle(error); return error.response.data })
    }

    openPatch = (path, data, stringify = true, dataAsParam = true) => {
        // this.enableRequestInterceptors()
        return axios.patch(apiBaseUrl + path, data, {
            headers: {}
        })
            .then(response => (response.data))
            .catch((error) => { errorResponse.handle(error); return error.response.data })
    }

    patch = (path, data, stringify = true, dataAsParam = true) => {
        // this.enableRequestInterceptors()
        return axios.patch(apiBaseUrl + path, data, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
        })
            .then(response => (response.data))
            .catch((error) => { errorResponse.handle(error); return error.response.data })
    }

    authPost = (path, body) => {

        const formData = new FormData()

        Object.keys(body).map(key => formData.append(key, body[key]))

        return axios.post(apiBaseUrl + path, formData,
            {
                'headers': {
                    "authorization": `Basic ${this.generateToken()}`
                }
            }
        )
            .then(response => response)
            .catch((error) => { console.log(error) })
    }

    setQueryParams(params) {
        let query = ''

        if (params) {
            Object.keys(params).map(function (key, index) {

                if (params[key]) {
                    if (typeof params[key] === "object") {

                        Object.keys(params[key]).map(function (key2, index2) {
                            if (params[key][key2]) {
                                query += query.length > 0 ? '&' + key2 + '=' + params[key][key2] : '?' + key2 + '=' + params[key][key2]
                            }
                            return true;
                        });
                    } else {
                        if (params[key]) {
                            query += query.length > 0 ? '&' + key + '=' + params[key] : '?' + key + '=' + params[key]
                        }
                    }
                }
                return true;
            });
        }

        return query;

    }

}

export default new BackendClient()