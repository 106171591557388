export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

// export const generateId = _ => {
//     let d = new Date().getTime()
//     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
//             let r = (d + Math.random() * 16) % 16 | 0
//             d = Math.floor(d / 16)
//             return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
//         })
// }

export const isValidJsonString = input => {
    try { 
        JSON.parse(input)
    } catch (e) {
        return false
    }
    return true
}