import * as actions from "../actions/navActions"

const initialState = {
    open: true,
    moduleName: ""
}

export const navReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.DRAWER_OPEN:
            return {
                ...state,
                open: true
            }
        case actions.DRAWER_CLOSE:
            return {
                ...state,
                open: false
            }
        case actions.UPDATE_MODULE_LINK:
            return {
                ...state,
                moduleName: action.moduleName
            }
        default:
            return state
    }
}
