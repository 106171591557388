import { combineReducers } from "redux"

import { sessionReducer as session } from "./sessionReducer"
import { notificationReducer as notification } from "./notificationReducer"
import { navReducer as navigation } from "./navReducer"
import { generalReducer as general } from "./generalReducer"

export const rootReducer = combineReducers({
    general,
    session,
    navigation,
    notification
})