import React from "react"

export const RecentServiceIcon = _ => (
    <svg
        className="prefix__jss85 prefix__jss22"
        viewBox="0 0 24 24"
        aria-hidden="true"
    >
        <path d="M20 7h-4V5c0-.55-.22-1.05-.59-1.41C15.05 3.22 14.55 3 14 3h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zM10 5h4v2h-4V5zm1 13.5l-1-1 3-3-3-3 1-1 4 4-4 4z" />
    </svg>
)