import React from "react";

export const MERIcon = _ => (
    <svg
        className="prefix__jss85 prefix__jss22"
        viewBox="0 0 24 24"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Diagonal slash */}
        <line
            x1="5"
            y1="19"
            x2="19"
            y2="5"
            stroke="white"
            strokeWidth="2"
        />
        {/* Top dot */}
        <circle
            cx="7.5"
            cy="7.5"
            r="2.5"
            fill="white"
        />
        {/* Bottom dot */}
        <circle
            cx="16.5"
            cy="16.5"
            r="2.5"
            fill="white"
        />
    </svg>
);
