import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import React from 'react'
import { Form } from 'react-final-form';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {t} from 'i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ThemeButton } from '../../constants/themeComponents';
import CaseloadInfoDataTable from '../../reusable/MBTDataTable/CaseloadInfoDataTable';
import TableSkeltonScreen from '../../reusable/SkeltonScreens/TableSkeltonScreen';
import TableSkeltonScreenMini from '../../reusable/SkeltonScreens/TableSkeltonScreenMini';

export const MERCalculatorDialog = ({
    state,
    props,
    handleInfoClose,
    handleAutoCompleateInputChange,
    handleAutoCompleateChange,
    isModalOpen,
    handleYearDropdownChange,
    handleOnSearch,
    handleDownloadClick
}) => (
    < Dialog disableEnforceFocus={true} open={isModalOpen} onClose={handleInfoClose} aria-labelledby="form-dialog-title" fullWidth maxWidth='lg' >
        <DialogTitle id="form-dialog-title" className='dialog-head'>
            <div className='heading-tab'>
                <span>{t('LABEL_CALCULATE_MER')}</span>
                <IconButton edge="end" color="inherit" onClick={handleInfoClose} aria-label="close">
                    <HighlightOffIcon />
                </IconButton>
            </div>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5} lg={5}>
                    <Autocomplete
                        size='small'
                        value={state.client}
                        disableClearable={true}
                        loadingText={t('LABEL_LOADING')}
                        className='autocompletes-type-wrapper'
                        loading={state.clientSuggestionsLoading}
                        renderOption={(option, { selected }) => (<> {option.name} </>)}
                        onInputChange={(event, value) => handleAutoCompleateInputChange(value)}
                        onChange={(event, value) => handleAutoCompleateChange('client', value)}
                        getOptionLabel={option => (option && option.name) ? option.name : ''}
                        options={state.clientSuggestionsLoading ? [] : state.client != null ? state.clientsSuggestions.filter(x  => x != state.client) : state.clientsSuggestions}
                        noOptionsText={(state.clientKeyword.length > 0 && state.clientsSuggestions.length < 1) ? t('LABEL_NO_OPTIONS') : ''}
                        renderInput={params => <TextField autoFocus required {...params} label={t('LABEL_CLIENT')} margin="normal" fullWidth />}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1} lg={1}>
                    <Autocomplete
                        size='small'
                        disableClearable
                        className='autocompletes-type-wrapper'
                        options={props.loadingPractRefData ? [] : state.selectedYear != null ? state.merYearSuggestions.filter(x => x != state.selectedYear) : state.merYearSuggestions}
                        noOptionsText={(state.merYearSuggestions.length < 1) ? t('LABEL_NO_OPTIONS') : ''}
                        loadingText={t('LABEL_LOADING')}
                        loading={props.loadingPractRefData}
                        getOptionLabel={option => (option && option.label) ? option.label : ''}
                        value={state.selectedYear}
                        onChange={(event, values) => handleYearDropdownChange(values)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label='Year'
                                placeholder="Search Options"
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                    <Autocomplete
                        size='small'
                        disableClearable
                        className='autocompletes-type-wrapper'
                        options={state.selectedMonth != null ? state.merMonthSuggestions.filter(x => x != state.selectedMonth) : state.merMonthSuggestions}
                        noOptionsText={(state.merMonthSuggestions.length < 1) ? t('LABEL_NO_OPTIONS') : ''}
                        loadingText={t('LABEL_LOADING')}
                        disabled={state.selectedYear == null}
                        getOptionLabel={option => (option && option.label) ? option.label : ''}
                        value={state.selectedMonth ? state.selectedMonth : null}
                        onChange={(event, values) => handleAutoCompleateChange('selectedMonth', values)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label='Month'
                                placeholder="Search Options"
                                fullWidth
                                required
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1} lg={1} style={{ marginTop: 1}}>
                    <Button
                        type='submit'
                        color="primary"
                        onClick={handleOnSearch}
                        disabled={state.selectedYear == null || state.client == null || state.selectedMonth == null}
                        variant="outline"
                        className='add-button'
                    >
                        {state.merDataLoading ? t('LABEL_WAIT') : t('BUTTON_SUBMIT')}
                    </Button>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: -10 }}>
                    {state.merDataLoading ? <div style={{marginBottom: 10}}><TableSkeltonScreenMini /></div>  : <Typography
                        className='tab-panel'
                        component="div"
                        role="tabpanel"
                        hidden={false}
                        id={`scrollable-force-tabpanel-${1}`}
                    >
                        {state.merData.length > 0 && <CaseloadInfoDataTable
                            data={state.merData}
                            columns={state.MERTableColumns}
                            pagination={null}
                            handleDownloadClick={handleDownloadClick}
                            filterInformation={{year: state.selectedYear.value, month: state.selectedMonth.value, client: state.client.id}}
                            isMERTable={true}
                        />}
                        {(state.noMERData) && (
                            <p className='no-records'>No Records Found</p>
                        )}

                    </Typography>}
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>);