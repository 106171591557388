import React, { Component } from 'react'
import { connect } from "react-redux"
import { compose } from 'redux'
import Snackbar from '@material-ui/core/Snackbar'

import './Snackbar.scss'

import { showNotification, hideNotification } from '../../actions/notificationActions'

// import { messageTypes } from '../../constants/mediaConstants'

// const styles = {
//     error: {
//         background: '#DC143C'
//     },
//     success: {
//         background: '#43a047'
//     },
//     warning: {
//         background: '#ffa000'
//     },
//     info: {
//         background: '#3E64CB'
//     }
// }

class SnackBar extends Component {

    state = {
        vertical: 'top',
        horizontal: 'center',
    }

    openSnackBar = _ => _ => {
        this.props.showNotification()
        this.setState({ open: true })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.props.open && nextProps.open) {
            setTimeout(() => this.props.hideNotification(), 3500)
        }
    }

    handleClose = _ => this.props.hideNotification()

    render() {

        const {
            open,
            message,
            // classes,
            // messageType
        } = this.props
        let snackBarBGColor = ""
        const { vertical, horizontal } = this.state

        // switch (messageType) {
        //     case messageTypes.error:
        //         snackBarBGColor = classes.error
        //         break
        //     case messageTypes.success:
        //         snackBarBGColor = classes.success
        //         break
        //     case messageTypes.info:
        //         snackBarBGColor = classes.info
        //         break
        //     case messageTypes.warning:
        //         snackBarBGColor = classes.warning
        //         break
        //     default:
        //         snackBarBGColor = classes.info
        //         break
        // }

        return (
            <div>
                <Snackbar
                    open={open}
                    ContentProps={{
                        classes: {
                            root: snackBarBGColor
                        }
                    }}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    bodyStyle={{ color: 'white' }}
                    anchorOrigin={{ vertical, horizontal }}
                    message={<span id="message-id">{message && message}</span>}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ notification }) => ({
    message: notification.message,
    open: notification.showNotification,
    messageType: notification.messageType
})

const mapDispatchToProps = dispatch => ({
    hideNotification: _ => dispatch(hideNotification()),
    showNotification: message => dispatch(showNotification(message))
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(SnackBar)
