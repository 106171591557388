import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import { css } from 'aphrodite-jss'
import { Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { studentDocumentFileDeleteAction, studentDocumentAction } from '../../../actions/generalActions'
import { styles } from '../../../assets/ContainerStyles.jsx'
import { SuccessfullyDeleted } from '../../../constants/notifyConstants.jsx';

class DeleteStudentDocumentCellRenderer extends Component {

    state = {
        open: false,
        btnStatus: false
    }

    handleDelete = _ => {
        this.setState({ btnStatus: true });
        let params = {
            id: this.props.params.studentId,
            fileId: this.props.params.fileId
        }
        this.props.studentDocumentFileDeleteAction(params).then(response => {
            if (response && response.statusCode === 200) {
                this.props.studentDocumentAction({ studentId: this.props.params.studentId });
                toast.success(<SuccessfullyDeleted />);
                this.handleClose();
            }
            this.setState({ btnStatus: false });
        });
    }

    confirmDelete = _ => {
        this.setState({ open: true });
    }

    handleClose = _ => {
        this.setState({ open: false });
    }

    render() {
        return (
            <>
                {this.props.params.allowed &&
                    <Tooltip title="Delete">
                        <IconButton onClick={this.confirmDelete}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Tooltip>
                }
                <Dialog disableEnforceFocus={true} open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle className={css(styles.dialogTtl)} id="form-dialog-title">
                        <div className={css(styles.dialogHead)}>
                            <span>Delete Student Document</span>
                            <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
                                <HighlightOffIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText> Are you sure that you want to delete this document? </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.handleClose} color="primary"> Cancel </Button>
                        <Button onClick={this.handleDelete} disabled={this.state.btnStatus} color="primary"> Confirm </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = ({ general }) => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    studentDocumentFileDeleteAction: params => dispatch(studentDocumentFileDeleteAction(params)),
    studentDocumentAction: params => dispatch(studentDocumentAction(params))
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(DeleteStudentDocumentCellRenderer)