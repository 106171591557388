export const errors = [
    {
        code: 'requestBody.unreadable/requiredContentMissing',
        title: 'Error Occurred!',
        message: 'Please fill all required fields.'
    },
    {
        code: 'mbtp.auth.authToken.invalid',
        title: 'Error Occurred!',
        message: 'Invalid authentication token.'
    },
    {
        code: 'mbtp.auth.grantType.empty',
        title: 'Error Occurred!',
        message: 'Grant type is not set.'
    },
    {
        code: 'mbtp.auth.grantType.invalid',
        title: 'Error Occurred!',
        message: 'Grant type is not a valid value.'
    },
    {
        code: 'mbtp.user.retrieveFiles.fileProcessor.notEnabled',
        title: 'Error Occurred!',
        message: 'This client does not have file processor permissions.'
    },
    {
        code: 'mbtp.auth.credentials.invalid',
        title: 'Error Occurred!',
        message: 'User name or/and password is missing or credentials are invalid.'
    },
    {
        code: 'mbtp.auth.credentials.invalidAndCaptchaRequired',
        title: 'Error Occurred!',
        message: 'Invalid credentials and captcha required'
    },
    {
        code: 'mbtp.auth.captcha.empty',
        title: 'Error Occurred!',
        message: 'Empty captcha'
    },
    {
        code: 'mbtp.auth.captcha.failed',
        title: 'Error Occurred!',
        message: 'Captcha failed'
    },
    {
        code: 'mbtp.auth.refreshToken.expired',
        title: 'Error Occurred!',
        message: 'Refresh token expired'
    },
    {
        code: 'mbtp.auth.accessToken.expired',
        title: 'Error Occurred!',
        message: 'Access token expired'
    },
    {
        code: 'mbtp.auth.request.unauthorized',
        title: 'Error Occurred!',
        message: 'You do not have authority to access this service.'
    },
    {
        code: 'mbtp.auth.request.unauthenticated',
        title: 'Error Occurred!',
        message: 'Unauthenticated.'
    },
    {
        code: 'mbtp.auth.user.notActive',
        title: 'Error Occurred!',
        message: 'This user is not active.'
    },
    {
        code: 'mbtp.client.generalInfoUpdateRequest.name.empty',
        title: 'Error Occurred!',
        message: 'Name required.'
    },
    {
        code: "mbtp.leaDepartmentCreateRequest.name.exist",
        title: 'Error Occurred!',
        message: 'LEA Department Name already exist.'
    },
    {
        code: "mbtp.student.linkedStudentsUpdateRequest.studentLink.invalid",
        title: 'Error Occurred!',
        message: 'Invalid student link.'
    },
    {
        code: "mbtp.student.linkedStudentsUpdateRequest.primaryStudent.isSecondaryStudent",
        title: 'Error Occurred!',
        message: 'Invalid Primary Student link.'
    },
    {
        code: "mbtp.student.linkedStudentsUpdateRequest.secondaryStudent.selfLinking",
        title: 'Error Occurred!',
        message: 'Unable to link self account.'
    },
    {
        code: "mbtp.student.linkedStudentsUpdateRequest.secondaryStudent.hasPrimaryStudent",
        title: 'Error Occurred!',
        message: 'This student already has a primary student.'
    },
    {
        code: "mbtp.leaDepartmentUpdateRequest.name.exists",
        title: 'Error Occurred!',
        message: 'LEA Department Name already exist.'
    },
    {
        code: "mbtp.LeaSiteCreateRequest.name.exists",
        title: 'Error Occurred!',
        message: 'LEA Site Name already exist.'
    },
    {
        code: 'mbtp.client.clientCreateRequest.name.empty',
        title: 'Error Occurred!',
        message: 'Client Name required.'
    },
    {
        code: 'mbtp.client.generalInfoUpdateRequest.shortName.empty',
        title: 'Error Occurred!',
        message: 'Short name required.'
    },
    {
        code: 'mbtp.client.generalInfoUpdateRequest.name.invalid',
        title: 'Error Occurred!',
        message: 'Invalid name.'
    },
    {
        code: 'mbtp.client.generalInfoUpdateRequest.shortName.lengthExceeds',
        title: 'Error Occurred!',
        message: 'Short name length exceeded.'
    },
    {
        code: "mbtp.client.clientLeaInfoUpdateRequest.mediCalPin.invalid",
        title: 'Error Occurred!',
        message: 'Invalid medi cal PIN'
    },
    {

        code: "mbtp.client.clientLeaInfoUpdateRequest.npiNumber.invalid",
        title: 'Error Occurred!',
        message: 'Invalid NPI number'
    },
    {

        code: "mbtp.client.clientLeaInfoUpdateRequest.ptPrescType.empty",
        title: 'Error Occurred!',
        message: 'Empty PT prescription type'
    },
    {

        code: "mbtp.client.clientLeaInfoUpdateRequest.allowUserToSeeAllStudents.empty",
        title: 'Error Occurred!',
        message: 'Empty allow user to see all students'
    },
    {

        code: "mbtp.client.clientLeaInfoUpdateRequest.tamId.invalid",
        title: 'Error Occurred!',
        message: 'invalid Tax Id'
    },
    {

        code: "mbtp.client.clientLeaInfoUpdateRequest.otPrescType.empty",
        title: 'Error Occurred!',
        message: 'Empty OT prescription type'
    },
    {
        code: "mbtp.user.updateRequest.firstName.invalid",
        title: 'Error Occurred!',
        message: 'Invalid First Name.'
    },
    {
        code: "mbtp.client.leaInfoUpdateRequest.taxId.invalid",
        title: 'Error Occurred!',
        message: 'Invalid Tax Id.'
    },
    {
        code: "mbtp.user.supervisorCreateUpdateRequest.expiration.invalid",
        title: 'Error Occurred!',
        message: 'Invalid Expiration Date.'
    },
    {
        code: "mbtp.student.generalInfoUpdateRequest.middleName.invalid",
        title: 'Error Occurred!',
        message: 'Invalid Middle Name.'

    },
    {
        code: "mbtp.mbtAgent.generalInfoUpdateRequest.cellPhoneNumber.invalid",
        title: 'Error Occurred!',
        message: 'Invalid cell phone number.'
    },
    {
        code: "mbtp.PractitionerCredentialServiceType.ServiceTypePractCredentialCreateRequest.PractitionerCredentialServiceType.alreadyExist",
        title: 'Error Occurred!',
        message: 'Practitioner Credential Service Type already exist.'
    },
    {
        code: "mbtp.mbtAgent.clientMbtAgentDeleteRequest.clientMbtAgent.notExist",
        title: 'Error Occurred!',
        message: 'Client MBT Agent not exist.'
    },
    {
        code: "mbtp.user.searchRequest.email.invalid",
        title: 'Error Occurred!',
        message: 'Invalid email.'
    },
    {
        code: "mbtp.mbtAgent.searchRequest.name.invalid",
        title: 'Error Occurred!',
        message: 'Invalid name.'
    },
    {
        code: "mbtp.client.searchRequest.name.invalid",
        title: 'Error Occurred!',
        message: 'Invalid name.'
    },
    {
        code: "mbtp.student.yearlyDataViewRequest.yearlyData.notExist",
        title: 'Error Occurred!',
        message: 'Yearly data not exist.'
    },
    {
        code: "mbtp.student.yearlyDataUpdateRequest.yearlyData.notExist",
        title: 'Error Occurred!',
        message: 'Yearly data not exist.'
    },
    {
        code: "mbtp.client.speechProtocolCreateRequest.effectiveDate.duplicates",
        title: 'Error Occurred!',
        message: 'Duplicate Effective Date.'
    },
    {
        code: "mbtp.client.leaDepartmentCreateRequest.name.exist",
        title: 'Error Occurred!',
        message: 'Duplicate name.'

    },
    {
        code: "mbtp.client.leaSiteCreateRequest.name.exists",
        title: 'Error Occurred!',
        message: 'Duplicate Name.'
    },
    {
        code: "mbtp.client.leaInfoUpdateRequest.otPrescPhysicianNpi.invalid",
        title: 'Error Occurred!',
        message: "Invalid OT Prescribing Physician's NPI."
    },
    {
        code: "mbtp.client.leaInfoUpdateRequest.ptPrescPhysicianNpi.invalid",
        title: 'Error Occurred!',
        message: "Invalid PT Prescribing Physician's NPI."
    },
    {
        code: "mbtp.student.createRequest.status.invalid",
        title: 'Error Occurred!',
        message: "Invalid Student Status."
    },
    {
        code: "mbtp.student.createRequest.firstName.invalid",
        title: 'Error Occurred!',
        message: "Invalid Student First Name."
    },
    {
        code: "mbtp.student.createRequest.student.duplicates",
        title: 'Error Occurred!',
        message: "Duplicate Student."
    }

];