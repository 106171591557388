import React from "react"

export const StudentsInfoIcon = _ => (
    <svg
        className="prefix__jss85 prefix__jss22"
        viewBox="0 0 24 24"
        aria-hidden="true"
    >
        <path d="M19 3h-1V1h-2v2H8V1H6v2H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z" />
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
)