import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { withRouter } from "react-router"
import { Button, TextField } from "@material-ui/core"
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { withNamespaces } from 'react-i18next'
import { toast } from 'react-toastify';

import { loginFormUpdate } from '../../actions/sessionActions'
import LatestUpdatesContainer from '../../components/LatestUpdates/LatestUpdatesContainer'
import { EMAIL_REGEX, responseCodeSuccess, activationTokenValid } from '../../constants/authConstants'
import { baseContentUrl } from '../../utils/config'
import './forgotPassword.scss'
import { resetPasswordVarificationAction, resendAccountActivationLinkAction, resendResetPasswordLinkAction, resetPasswordAction } from '../../actions/generalActions'
import { hasNumber, hasLowerCase, hasUpperCase } from '../../constants/helpers'
import { SuccessfullyCreated } from '../../constants/notifyConstants.jsx'
import { Alert } from '@material-ui/lab';

class ResetPassword extends Component {

	constructor(props, context) {
		var queryString = window.location.href;
		var urlQuery = queryString.split("/password-reset?token=");

		super(props, context)
		this.state = {
			activationToken: urlQuery[1],
			passwordPolicy: '',
			activationStatus: false,
			activeErrors: '',
			tokenStatus: null,

			isLogIn: false,
			isChrome: navigator.userAgent.indexOf("Chrome") !== -1,
			pass: "",
			cPass: "",
			overlay: true,
			showPassword: false,
			prevPath: localStorage.getItem('prevPath') ? localStorage.getItem('prevPath') : null,
			errorFields: {
				cPass: false,
				pass: false,
			},
			touched: {
				cPass: false,
				pass: false
			},
			expired: false,
			recaptchaToken: "",
			errorsInTheForm: true,
			loggedInRealStatus: false,
			sholdShowTheRecpatchaForm: false,
			emailRule: new RegExp(EMAIL_REGEX),
			errorMessage: "",
		}
	}

	componentDidMount() {
		this.props.resetPasswordVarification({ token: this.state.activationToken }).then(data => this.showActivationForm(data))
	}

	showActivationForm = data => {
		if (data && data.statusCode && data.statusCode === responseCodeSuccess) {
			if (data.content && data.content.status && data.content.status === activationTokenValid) {
				this.setState({ passwordPolicy: data.content.passwordPolicy, activationStatus: true })
			}

			this.setState({
				tokenStatus: data.content.status,
				errorMessage: data.content.message
			})

		}
	}

	handleClickShowPassword = _ => this.setState({ showPassword: !this.state.showPassword })

	handleMouseDownPassword = event => event.preventDefault()

	handleBlur = field => _ => this.setState({ touched: { ...this.state.touched, [field]: true, errorsInTheForm: true } },
		() => {
			setTimeout(() => this.setState({
				touched: {
					cPass: false,
					pass: false
				},
			}), 4000)
		})

	updateFormHandler = field => event => {
		this.setState({ [field]: event.target.value })

		setTimeout(() => {
			var errors = "";
			var errorsInForm = true;

			if (!this.state.password) {
				errors = 'Password is required';
			} else if (!this.state.confPassword) {
				errors = 'Confirm Password is required';
			} else if (this.state.password.length < 6) {
				errors = 'Password minimum requirements should be 6 characters';
			} else if (!hasNumber(this.state.password)) {
				errors = 'Password should contain at least 1 number';
			} else if (!hasLowerCase(this.state.password)) {
				errors = 'Password should contain at least 1 lowercase letter';
			} else if (!hasUpperCase(this.state.password)) {
				errors = 'Password should contain at least 1 uppercase letter';
			} else if (this.state.password !== this.state.confPassword) {
				errors = 'Password mismatch';
			} else {
				errorsInForm = false;
			}

			this.setState({ activeErrors: errors, errorsInTheForm: errorsInForm })
		}, 500)
	}

	formSubmitHandler = _ => {
		this.props.resetPassword(
			{
				accountRequestToken: this.state.activationToken,
				password: this.state.password,
				confirmedPassword: this.state.confPassword,
			}
		).then(data => this.afterSubmit(data))
	}

	resendActivationLink = _ => {
		this.props.resendResetPasswordLinkAction({ token: this.state.activationToken, resend: "true" }).then(data => this.afterResendSubmit(data))
	}

	afterSubmit = data => {
		if (data && data.statusCode && data.statusCode === responseCodeSuccess) {
			toast.success(<SuccessfullyCreated message="Password Changed. Please login" />);
			this.routeToLogin()
		}
	}

	afterResendSubmit = response => {
		let data = response.data;
		if (data && data.statusCode && data.statusCode === responseCodeSuccess) {
			toast.success(<SuccessfullyCreated message={data.content.acknowledgement} />);
			this.routeToLogin()
		}
	}

	routeToLogin = () => this.setState({ isLogIn: !this.state.isLogIn })

	render() {

		const {
			t,
			loading,
			// classes,
			password,
			confPassword,
			isLoggedIn
		} = this.props

		const {
			showPassword,
			errorsInTheForm,
			sholdShowTheRecpatchaForm,
			activationStatus,
			activeErrors,
			tokenStatus,
			prevPath,
			errorMessage
		} = this.state

		if (isLoggedIn) {
			if (prevPath) {
				localStorage.removeItem('prevPath')
				window.location.href = prevPath
				return true
			}
			return <Redirect to={"/dashboard"} />
		}

		if (this.state.isLogIn) { return <Redirect to={"/login"} /> }

		return (
			<>
				<div className="login__page-container">
					<div className="login__page-left-bgimage">
						<LatestUpdatesContainer t={t} />
					</div>
					<div className="login__page-right-bgimage">
						<div className={`${sholdShowTheRecpatchaForm ? 'login__content-wrapper-overrided' : ''} login__content-wrapper2`}>

							<img src={`${baseContentUrl}/images/MBT-Logo.login.png`} alt="logo" />
							{!this.state.isChrome && <p className='caution'>For the best performance please use the Google Chrome web browser when accessing MBT's web portal.</p>}

							<h4 className='latest__update-heading'>Reset Your Password</h4>

							<p>{this.state.passwordPolicy}</p>

							{tokenStatus === "VALID" &&
								<>
									<TextField
										fullWidth
										margin="normal"
										name="password"
										value={password}
										disabled={loading || !activationStatus}
										label="Password *"
										className='default-input'
										onBlur={this.handleBlur('pass')}
										onChange={this.updateFormHandler('password')}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														disabled={loading || !activationStatus}
														edge="end"
														onClick={this.handleClickShowPassword}
														aria-label="toggle password visibility"
														onMouseDown={this.handleMouseDownPassword}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<TextField
										fullWidth
										margin="normal"
										name="confPassword"
										value={confPassword}
										disabled={loading || !activationStatus}
										label="Confirm Password *"
										className='default-input'
										onBlur={this.handleBlur('cPass')}
										onChange={this.updateFormHandler('confPassword')}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														disabled={loading || !activationStatus}
														edge="end"
														onClick={this.handleClickShowPassword}
														aria-label="toggle password visibility"
														onMouseDown={this.handleMouseDownPassword}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</>
							}

							{(errorsInTheForm && activeErrors) && <span className="error">{activeErrors}</span>}

							{(tokenStatus === "INVALID" || tokenStatus === "EXPIRED") &&
								<Alert severity="error">
									{errorMessage}
								</Alert>
							}

							<div className="login__btn-container">
								{tokenStatus === "VALID" &&
									<Button onClick={this.formSubmitHandler} size="large" className="defaultActionBtn min__width-overrided" disabled={loading || errorsInTheForm || !activationStatus} variant="contained">{loading ? t('LABEL_PLEASE_WAIT') : 'Reset Password'}</Button>
								}
								{tokenStatus === "INVALID" &&
									<Button size="large" disabled={loading} onClick={this.routeToLogin}>Go to Login</Button>
								}
								{tokenStatus === "EXPIRED" &&
									<Button size="small" onClick={this.resendActivationLink}>
										Resend reset password link
									</Button>
								}
							</div>



						</div>
						<div className="mobile__view-only">
							<LatestUpdatesContainer t={t} />
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = ({ session }) => ({
	loading: session.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn
})

const mapDispatchToProps = dispatch => ({
	loginFormUpdate: (username, password) => dispatch(loginFormUpdate(username, password)),
	resetPasswordVarification: param => dispatch(resetPasswordVarificationAction(param)),
	resendAccountActivationLink: param => dispatch(resendAccountActivationLinkAction(param)),
	resendResetPasswordLinkAction: param => dispatch(resendResetPasswordLinkAction(param)),
	resetPassword: param => dispatch(resetPasswordAction(param)),
})

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(ResetPassword))