import React from 'react'
// import { Button } from "@material-ui/core"

import './LatestUpdates.scss'

export const LatestUpdates = ({ t }) => (
    <>
        {/* <h4 className="latest__update-heading">{t('LABEL_LATEST_UPDATES')}</h4>
        <div className="latest__update-block">
            <p className="latest__update-para">01 August 2019</p>
            <h3 className="latest__update-sub-heading">Lobortis scelerisque fermentum dui faucibus in.</h3>
            <p className="latest__update-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Et ultrices neque ornare aenean. Tortor aliquam nulla facilisi cras. At lectus urna duis convallis convallis tellus id. Volutpat est velit egestas dui id ornare. Amet est placerat in egestas erat imperdiet sed. Quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Laoreet non curabitur gravida arcu ac tortor.</p>
        </div>
        <div className="latest__update-block">
            <p className="latest__update-para">26 July 2019</p>
            <h3 className="latest__update-sub-heading">Introducing new user interface with new features</h3>
            <p className="latest__update-para">Beyond the new, cleaner look, we've added a few long-requested features like period comparison or heatmaps showing the busiest time of the day.</p>
        </div> */}

        <div className="latest__update-block" dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_MESSAGE_1 }}>
            {/* <div dangerouslySetInnerHTML={{ __html: "<p className='latest__update-para'>Test</p>" }} /> */}
            {/* <p className="latest__update-para">{process.env.REACT_APP_DATE}</p>
            <br />
            <h4 className="latest__update-heading">{process.env.REACT_APP_TITLE}</h4>
            <p className="latest__update-para">{process.env.REACT_APP_MESSAGE_1}</p>
            <br />
            <p className="latest__update-para"><b>{process.env.REACT_APP_MESSAGE_2}</b></p>
            <br />
            <p className="latest__update-para"><b>{process.env.REACT_APP_MESSAGE_3}</b></p>
            <br />
            <p className="latest__update-para">{process.env.REACT_APP_MESSAGE_4}</p>
            <br />
            <p className="latest__update-para">{process.env.REACT_APP_MESSAGE_5}</p> */}
        </div>
        {/* <Button variant="outlined" color="primary" size="small" style={{ marginTop: 10 }}>{t('LABEL_MORE_UPDATES')}</Button> */}
    </>)