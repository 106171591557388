import { StyleSheet } from 'aphrodite-jss';
import variables from '../global.scss';

export const styles = StyleSheet.create({
  dayClick: {
    "& .MuiPickersDay-daySelected": {
      backgroundColor: '#3f51b5 !important',
      color: '#fff',
      fontWeight: '500',
    },
    "& .MuiIconButton-root": {
      borderRadius: '50% !important'
    }
  },
  previewListItem: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "30px"
  },
  fileUploadSnackBar: {
    top: '20px !important',
    bottom: 'unset !important',
    '@media only screen and (min-width: 600px)': {
      left: 'auto !important',
      right: '10px !important',
    },
    '& div': {
      backgroundColor: '#4caf50 !important'
    }
  },
  dayPickerStyle: {
    height: "350px"
  },
  removeArrows: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  interalWrapperContent: {
    padding: '0px!important',
    display: 'block',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0.0), 0px 0px 0px 0px rgba(0, 0, 0, 0.0)!important',
    '& h2': {
      marginBottom: 20,
      marginLeft: 15,
      fontSize: variables.titleFontSize,
    },
    '& form': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: '0px',
    },
    '& svg': {
      fill: variables.defaultBlackColor,
    },
  },
  flex: {
    display: 'flex !important',
  },
  tabContainer: {
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    border: '15px solid #F3F3F3',
  },
  enrollmentContainer: {
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    backgroundColor: '#F3F3F3 !important',
    borderTop: '2px solid #F3F3F3',
    borderLeft: '15px solid #F3F3F3',
    borderRight: '15px solid #F3F3F3',
  },
  enrollmentCommentContainer: {
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    borderTop: '15px solid #F3F3F3',
    borderLeft: '15px solid #F3F3F3',
    borderRight: '15px solid #F3F3F3',
  },
  enrollmentCommentPadContainer: {
    padding: '1%',
    borderTop: '15px solid #F3F3F3',
    borderLeft: '15px solid #F3F3F3',
    borderRight: '15px solid #F3F3F3',
  },
  padding1: {
    padding: '1%',
  },
  popTitle: {
    paddingLeft: '19px',
    paddingBottom: '5px',
  },
  flex2: {
    display: 'flex',
    padding: '0 20px 10px'
  },
  flexBorder: {
    display: 'flex',
    padding: '0 20px 10px',
    borderTop: '10px solid rgb(243, 243, 243)'
  },
  labelx: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    fontSize: '12px',
    fontWeight: 'bold'
  },
  usersRight: {
    width: '49%',
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '20px',
  },
  loaderLottie: {
    backgroundcolor: variables.transparent,
    // margin: 0,
    overflow: 'hidden',
    position: 'fixed',
    width: '100%',
    height: '100%',
    opacity: '0.9',
    margin: '0 auto',
    textAlign: 'center',
    zIndex: 999999,
    '& svg': {
      width: '50px !important',
      height: '50px !important',
      position: 'relative',
      top: '20%',
      '& g[filter]': {
        display: 'none !important',
      },
    },
  },
  loaderLottie2: {
    backgroundcolor: variables.transparent,
    // margin: 0,
    overflow: 'hidden',
    position: 'fixed',
    width: '100%',
    height: '100%',
    opacity: '0.9',
    margin: '0 auto',
    textAlign: 'center',
    zIndex: 999999,
    '& svg': {
      width: '100% !important',
      height: '25px !important',
      position: 'relative',
      top: '15%',
      '& g[filter]': {
        display: 'none !important',
      },
    },
  },
  paperWhite: {
    color: variables.defaultBlackColor,
    margin: '0',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0.0), 0px 0px 0px 0px rgba(0, 0, 0, 0.0)!important',
    // boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    padding: '0px',
    marginTop: '25px!important',
  },
  fixedFilter: {
    position: 'relative',
    zIndex: 999,
    width: '100%',
  },
  width100: {
    width: '100%',
  },
  adminSearchFixedAutocomplete: {
    width: '100%',
    marginRight: '2% !important',
    '& button': {
      float: 'right !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  checkBx: {
    padding: '4px !important'
  },
  speachAssessment: {
    width: '100%',
    marginRight: '2% !important',
    border: '1px solid lightblue !important',
    borderRadius: '10px!important',
    paddingLeft: '20px!important',
    marginTop: '18px!important',
    paddingTop: '10px!important',
    '& button': {
      float: 'right !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  studentSelect: {
    textDecoration: 'underline',
    color: '#4278A4',
    cursor: 'pointer',
    paddingLeft: '15px',
  },
  link: {
    textDecoration: 'underline',
    color: '#4278A4',
    cursor: 'pointer',
  },
  tabMenu2: {
    color: '#fff',
    backgroundColor: 'rgb(66, 120, 164)'
  },
  tabMenu: {
    backgroundColor: variables.defaultBgColor,
  },
  padding0: {
    padding: '0 !important',
  },
  paddingZero: {
    paddingTop: '0 !important',
    display: 'block !important',
    // justifyContent: 'space-between',
  },
  tabPanel: {
    backgroundColor: '#F3F3F3',
    // backgroundColor: variables.defaultBgColor,
    marginTop: '10px!important',
    '.div': {
      backgroundColor: 'black !important',
    },
  },
  tabPanel2: {
    backgroundColor: variables.defaultBgColor,
    paddingBottom: '30px',
  },
  tabPanelHeader: {
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {},
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      maxWidth: '125px !important',
    },
  },
  internalContentgridStudent: {
    display: 'flex',
    marginRight: 25,
    width: '100%',
    justifyContent: 'space-between',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize
    },
    '& input[type="text"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      maxWidth: 200
    },
    '& div': {
      marginBottom: 0,
    },
    '& div[class*=-placeholder]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize
    },
  },
  internalContentgrid: {
    marginTop: '0',
    display: 'flex',
    justifyContent: 'left',
    marginRight: '0',
    width: '100%',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      maxWidth: 200,
    },
    '& div': {
      marginBottom: 0,
      marginTop: 0,
      width: '250px',
      marginRight: '0px;',
      '@media only screen and (max-width: 1366px) ': {
        width: '174px !important;',
      },
    },
    '& div[class*=-placeholder]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      flexDirection: 'column',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },
  internalContentgridMulti: {
    display: 'flex',
    marginRight: 25,
    justifyContent: 'space-between',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },
    '& input[type="text"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      maxWidth: '100%!important',
    },
    '& input[type="textarea"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      marginBottom: 0,

    },
    '& div[class*=-placeholder]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  internalContentgridOptiClaim: {
    display: 'flex',
    marginRight: 25,
    width: '50%',
    flexDirection: 'column',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },
    '& input[type="text"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      maxWidth: '100%!important',
    },
    '& input[type="textarea"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      marginBottom: 0,
    },
    '& div[class*=-placeholder]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  internalContentgridClient: {
    display: 'flex',
    marginRight: 25,
    width: '100%',
    justifyContent: 'space-between',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },
    '& input[type="text"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      maxWidth: '100%!important',
    },
    '& input[type="textarea"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      marginBottom: 0,
    },
    '& div[class*=-placeholder]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  defaultTextBoxesWrapperFullWith: {
    marginRight: '15px!important',
    fontSize: '20px',
    width: '100%',
    '& label': {
      fontWeight: 'bold',
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      width: '100%',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '100%',
    },
  },
  defaultTextBoxesWrapper: {
    marginRight: '15px!important',
    fontSize: '20px',
    width: '30%',
    '& label': {
      fontWeight: 'bold',
    },
    '& input[type="text"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      width: '100%',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '100%',
    },
  },
  buttonWrappertwo: {
    width: '25%',
    marginTop: '70px !important',
    alignSelf: 'flex-end',
  },
  defaultTextBoxesWrapperMargin: {
    marginTop: '30px !important',
    marginRight: '2% !important',
    width: '30%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& textarea': {
      minWidth: '84%',
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  defaultTextBoxesWrapperCollapse: {
    alignSelf: 'flex-end !important',
    marginRight: '2% !important',
    width: '30%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& textarea': {
      minWidth: '84%',
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  width48: {
    width: '48%'
  },
  accordPaddingZero: {
    minHeight: '0px !important',
    margin: '0px 0 !important'
  },
  caseloadSelectWrapper: {
    paddingRight: '15px',
    width: '25%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  fullDateSelectEndWrapper: {
    paddingRight: '15px',
    alignSelf: 'flex-end',
    width: '100%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  leaBillingSelectWrapper: {
    paddingRight: '15px',
    paddingBottom: '10px',
    alignSelf: 'flex-end',
    width: '33%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  caseloadSelectEndWrapper: {
    paddingRight: '15px',
    alignSelf: 'flex-end',
    width: '25%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  newEnrollmentAutocomplete: {
    paddingRight: '15px',
    alignSelf: 'flex-end',
    width: '100%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  tabContainer2: {
    borderTop: '15px solid rgb(243, 243, 243)',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  leaBillingSearchButton: {
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    marginRight: '15px!important',
    lineHeight: 'normal !important',
    top: 0,
    height: '34px !important',
    alignSelf: 'center',
    '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
      alignSelf: 'start',
      marginTop: '7px !important'
    },
  },
  caseloadSearchButton: {
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    marginRight: '15px!important',
    lineHeight: 'normal !important',
    top: 0,
    height: '34px !important',
    alignSelf: 'flex-end',
    '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
      alignSelf: 'start',
      marginTop: '7px !important'
    },
  },
  regenerateButton: {
    alignSelf: 'center !important',
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    marginRight: '15px!important',
    lineHeight: 'normal !important',
    top: 0,
    height: '34px !important',
    alignSelf: 'center !important',
    '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
      alignSelf: 'center !important',
      marginTop: '7px !important'
    },
  },
  serviceListTextBoxesWrapper: {
    paddingBottom: '8px',
    width: '48%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  defaultTextBoxesWrapperOptiClaim: {
    alignSelf: 'flex-end !important',
    width: '48%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& textarea': {
      minWidth: '84%',
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  input100Wrapper: {
    width: '100%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '50%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    }
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  serviceListDateWrapperLeft: {
    paddingBottom: '10px !important',
    width: '45%',
    marginRight: '10px !important',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& textarea': {
      minWidth: '84%',
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  serviceListDateWrapper: {
    paddingBottom: '10px !important',
    width: '45%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& textarea': {
      minWidth: '84%',
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  defaultTextBoxesWrapperClient: {
    alignSelf: 'flex-end !important',
    width: '30%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& textarea': {
      minWidth: '84%',
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  defaultTextArea: {
    width: '30%',
    borderWidth: '1px !important',
    borderStyle: 'solid !important',
    borderColor: 'lightblue !important',
    borderRadius: '10px !important',
    padding: '10px !important',
    '& label': {
      padding: '10px',
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& textarea': {
      minWidth: '84%',
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  textBoxesNumberWrapper: {
    marginTop: '25px !important',
    width: '30%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      minWidth: '88%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& textarea': {
      minWidth: '84%',
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  collapsedHeader: {
    marginBottom: '0 !important',
  },
  datesWrapper: {
    width: '48%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
  },
  datesWrapperFull: {
    width: '100%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
  },
  datesWrapper100: {
    width: '100%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
  },
  datesFlexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
    '& input': {
      paddingBottom: '10px',
    },
  },
  radioGroupWrapper: {
    marginTop: '20px !important',
    width: '100%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
    '& input': {
      width: 'unset !important',
    },
    "& .MuiButtonBase-root": {
      padding: '10px !important',
    },
    "& .MuiIconButton-label": {
      color: '#3f51b5',
    }
  },
  radioGroupWrapperNotes: {
    marginTop: '20px !important',
    paddingLeft: '10px !important',
    width: '100%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
    '& input': {
      width: 'unset !inportant',
    },
  },
  defaultTextBoxesWrapperExtrastudent: {
    width: '17%',
    float: 'left',
    marginRight: '15px!important',
  },
  addButton: {
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    // height: '45px!important',
    marginRight: '15px!important',
    lineHeight: 'normal !important',
    height: '34px !important',
    alignSelf: 'flex-end',
    top: 0,
    border: 'solid 1px #4278a4!important',
    color: '#4278a4!important',
    '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
      alignSelf: 'start',
      marginTop: '7px !important'
    },
  },
  addButtonComment: {
    alignSelf: 'flex-end!important',
    paddingLeft: '15px!important',
  },
  caseloadAddButton: {
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    // height: '45px!important',
    marginRight: '15px!important',
    lineHeight: 'normal !important',
    height: '34px !important',
    alignSelf: 'flex-end',
    top: 0,
    border: 'solid 1px #4278a4!important',
    color: '#4278a4!important',
    '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
      alignSelf: 'start',
      marginTop: '7px !important'
    },
  },
  datePickerClient: {
    marginTop: '13px !important',
    width: '30%',
    '& div': {
      // width: '100%'
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& input': {
      maxWidth: '85% !important'
    },
  },
  datePickerWrap: {
    marginTop: '13px !important',
    width: '30%',
    alignSelf: 'end',
    '& div': {
      // width: '100%'
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& input': {
      maxWidth: '85% !important'
    },
  },
  datepickerInternalWrapperstudent: {
    width: '17%',
    float: 'left',
    marginRight: '15px!important',
    marginTop: '-8px !important',

    color: variables.labelDefaultColor,
    '& input[type="text"]': {
      minWidth: '80%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  labelWrapper: {
    width: '30%',
    marginTop: '30px',
    lineHeight: '50px',
  },
  selectBoxWrapper: {
    width: '30%',
    marginTop: '10px !important',
  },
  selectBoxPopWrapper: {
    width: '50%',
    marginLeft: '2% !important',
    marginTop: '10px !important',
  },
  defaultTextBoxesWrapperExtra: {
    width: '20%',
  },
  dataTable: {
    marginTop: '50px !important',
  },
  fontSize: {
    fontSize: variables.defaultFontSize + '!important'
  },
  dropDownContainerClientMiddle: {
    top: 15,
    alignSelf: 'center',
    width: '30%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& li': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  dropDownContainerClient: {
    top: 15,
    width: '30%',
    '& label': {
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& li': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  dropDownContainer: {
    marginRight: '15px!important',
    top: 0,
    width: '23%',
    marginTop: 0,
    '& div': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& li': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      width: '100%',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '100%',
    },
  },
  searchButtonWrapper: {
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    // height: '45px!important',
    marginRight: '15px!important',
    lineHeight: '0!important',
    top: 0,
    height: '34px !important',
    alignSelf: 'flex-end',
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      width: '100%',
      //marginBottom: '10px !important'
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '100%',
      // marginBottom: '10px !important'
    },
  },
  cancelButtonWrapper: {
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    height: '45px!important',
    marginRight: '10px!important',
    lineHeight: '0!important',
    top: 0,

    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      width: '100%',
      //marginBottom: '10px !important'
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '100%',
      // marginBottom: '10px !important'
    },
  },
  tblFilterWrapper: {
    marginTop: '20px',
    border: '2px solid #fafafa',
    backgroundColor: '#fafafa',
    padding: '10px 30px 30px 30px',
  },

  backButtonWrapperbtn: {
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    width: '90% !important',
    marginLeft: '15px!important',
    lineHeight: '0!important',
    height: '34px !important',
    alignSelf: 'flex-end',
    top: 0,
    border: 'solid 1px #4278a4!important',
    color: '#4278a4!important',
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      width: '100%',
      //marginBottom: '10px !important'
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '100%',
      // marginBottom: '10px !important'
    },
  },
  appplyForwardbtn: {
    paddingTop: '12px!important',
    paddingBottom: '12px!important',
    width: '52% !important',
    lineHeight: '0!important',
    alignSelf: 'center',
    top: 0,
    border: 'solid 1px #4278a4!important',
    color: '#4278a4!important',
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      width: '100%',
      //marginBottom: '10px !important'
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '100%',
      // marginBottom: '10px !important'
    },
  },

  tags: {
    position: 'absolute',
    right: '50px',
    width: '11%',
    zIndex: '99999',
    backgroundColor: 'rgb(66, 120, 164)',
    color: 'white',
    fontWeight: 'bolder',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '25px',
  },

  rightMargin: {
    marginRight: '10px!important',
    cursor: 'pointer'
  },
  addButtonWrapperbtn: {
    paddingTop: '15px!important',
    paddingBottom: '15px!important',
    // height: '45px!important',
    marginRight: '15px!important',
    lineHeight: '0!important',
    height: '34px !important',
    alignSelf: 'flex-end',
    top: 0,
    border: 'solid 1px #4278a4!important',
    color: '#4278a4!important',
    '@media only screen and (min-width: 320px) and (max-width: 640px)': {
      width: '100%',
      //marginBottom: '10px !important'
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      width: '100%',
      // marginBottom: '10px !important'
    },
  },

  datepickerInternalWrapper: {
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // maxwidth: 140,
    // marginTop: '-2px',
    color: variables.labelDefaultColor,
    '& input[type="text"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  overridedDatePickerPosition: {
    marginTop: '16px !important',
  },
  fromToDateWrapperLabel: {
    position: 'relative',
    top: '24px',
    color: variables.labelDefaultColor,
  },
  pt30: {
    paddingTop: '30px',
    flexGrow: 1,
    width: '100% !important',
  },
  collapseMenu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  addTableItemButton: {
    width: '10%',
    marginTop: '30px !important',
  },
  calanderIcon: {
    width: 15,
    height: 15,
  },
  dialogContent2: {
    backgroundColor: '#fff',
    width: '600px',
  },
  dialogContent: {
    width: '600px',
  },
  defaultTextBoxesCenter: {
    width: '28%',
    marginRight: '2% !important',
    alignSelf: 'center !important',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input[type="text"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },

  defaultTextBoxesWrapperFormControl: {
    width: '28%',
    marginRight: '2% !important',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input[type="text"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  defaultTextBoxesNumberWrap: {
    width: '28%',
    marginRight: '2% !important',
    marginTop: '16px !important',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input[type="text"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="textArea"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  fullWithMargin: {
    padding: '0px 20px 10px 20px !important',
  },
  autocompletesWrapper: {
    width: '100%',
    marginRight: '2% !important',
    '& button': {
      float: 'right !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  autocompletesTypeWrapperClient: {
    width: '25%',
    marginRight: '2% !important',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  viewStudentCheckbox: {
    paddingTop: '2%'
  },
  autocompletesTypeWrapperCollapse: {
    width: '30%',
    marginRight: '2% !important',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  autocompletesTypeWrapper: {
    width: '100%',
    marginRight: '2% !important',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },

  searchButtonWrapperpop: {
    height: 35,
    top: 0,
    flex: 'inherit !important',
    lineHeight: '0!important',
  },
  addButtonWrapperbtnpop: {
    border: 'solid 1px #4278a4!important',
    color: '#4278a4!important',
  },
  totalRecords: {
    textAlign: 'right',
    fontSize: '15px',
    margin: '20px 20px 0 0',
  },
  emptyRow: {
    overflow: 'hidden',
    position: 'fixed',
    width: '98%',
    height: '100%',
    opacity: '0.9',
    margin: '0 auto',
    textAlign: 'center',
    zIndex: 999999,
    background: '#fff !important',
    '&:hover': {
      background: '#fff !important',
      cursor: 'default',
    },
  },
  center: {
    textAlign: 'center',
  },
  calenderClearActions: {
    margin: '5px',
    textAlign: 'right'
  },
  calenderActions: {
    marginBottom: '10px',
    '& button': {
      margin: '10px !important',
    },
  },
  calenderTopActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      margin: '10px !important',
    },
  },
  containerBoxsection: {
    background: '#F3F3F3',
    padding: '25px',
  },
  containerBoxsectionMulti: {
    backgroundColor: variables.defaultBgColor,
    padding: '0 25px 25px 25px',
    marginBottom: ' 15px',
    boxShadow: 'rgba(68, 78, 98, 0.08) 0px 1px 15px 1px',
  },
  containerBoxsection100: {
    width: '100%',
    backgroundColor: variables.defaultBgColor,
    padding: '10px',
    marginBottom: ' 15px',
    boxShadow: 'rgba(68, 78, 98, 0.08) 0px 1px 15px 1px',
  },
  containerBoxCaseload: {
    backgroundColor: variables.defaultBgColor,
    padding: '25px',
    marginBottom: ' 15px',
    marginTop: ' 15px',
    boxShadow: 'rgba(68, 78, 98, 0.08) 0px 1px 15px 1px',
  },
  containerBoxsectionMain: {
    backgroundColor: variables.defaultBgColor,
    padding: '25px',
    marginBottom: ' 15px',
    boxShadow: 'rgba(68, 78, 98, 0.08) 0px 1px 15px 1px',
  },
  containerBoxsectionNoPadding: {
    backgroundColor: variables.defaultBgColor,
    marginBottom: ' 15px',
    boxShadow: 'rgba(68, 78, 98, 0.08) 0px 1px 15px 1px',
  },
  containerBoxsectionAddress: {
    background: '#F3F3F3',
    padding: '25px !important',
    paddingTop: '0 !important',
    marginBottom: ' 15px',
  },
  inputGroupWrap: {
    width: '100%',
    borderRadius: '10px !important',
  },
  inputGroupWrapper: {
    marginTop: '20px !important',
    width: '100%',
    // border: '1px solid lightblue !important',
    borderRadius: '10px !important',
  },
  inputGroupWrapperAddress: {
    width: '100%',
    borderRadius: '10px !important',
  },
  flexSpace: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  collapseForm: {
    width: '100%',
    display: 'flex',
    // justifyContent: 'space-between',
  },
  defaultLabel: {
    fontSize: variables.defaultFontSize,
    fontWeight: 'bold !important',
    color: variables.labelDefaultColor,

  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: variables.defaultFontSize,
    color: variables.labelDefaultColor,
    fontWeight: 'bold !important',
  },
  inputGroupinputs: {
    padding: '10px!important',
    paddingBottom: '25px!important',
  },
  pointer: {
    cursor: 'pointer',
  },
  selectedType: {
    color: 'black',
    cursor: 'default',
    height: '40px',
    display: 'inline-flex',
    marginRight: '15px',
    marginTop: '10px',
    padding: '15px',
    fontSize: '0.8125rem',
    alignItems: 'center',
    borderRadius: '10px',
  },
  relationshipsWrapper: {
    marginTop: '20px !important',
    width: '100%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
    height: 'fit-content',
    paddingBottom: '10px !important',
  },
  defaultTextBoxesWrapperAuto: {
    width: '30%',
    marginTop: '00px!important',
    '& input[type="text"]': {
      width: '100%',
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& div': {
      width: '100%',
      marginBottom: '0',
    },
  },
  phoneNumber: {
    width: '70%',
    marginTop: '25px !important',
    '& div': {
      '& input': {
        maxWidth: 'unset !important',
        width: '400px !important',
        fontSize: '15px !important',
      },
    },
  },
  phoneNumberExt: {
    width: '25%',
    marginTop: '25px !important',
    marginLeft: '20px !important',
    '& div': {
      '& input': {
        maxWidth: 'unset !important',
        width: '400px !important',
        fontSize: '15px !important',
      },
    },
  },
  menuItm: {
    padding: '0px 12px 0 0!important',
    '& svg': {
      fill: '#3f51b5 !important'
    },
    '&:focus': {
      backgroundColor: variables.defaultBgColor,
    },
  },
  selectedMenuItm: {
    backgroundColor: 'lightgray !important',
  },
  phoneNumberWrapperClient: {
    width: '25%',
  },
  phoneNumberWrapperUser: {
    width: '30%',
  },
  phoneNumberWrapper: {
    width: '30%',
    marginTop: '20px',
    '& div': {
      width: '100%',
    },
  },
  phoneNumberWrap: {
    width: '30%',
    marginTop: '20px',
    alignSelf: 'end',
    '& div': {
      width: '100%',
    },
  },
  chipsControl: {
    width: '30%',
    marginTop: '13px !important',
  },
  internalRadioGrid: {
    marginTop: '40px!important',
    width: '100%',
    display: 'flex',
    marginRight: '25px',
    justifyContent: 'space-between',
  },
  autocompletesWrapperClient: {
    width: '100%',
    marginRight: '2%',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  relationshipType: {
    maxWidth: '500px !important',
    width: '100%',
    marginRight: '2%',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  autocompletesWrapFlexEnd: {
    width: '30%',
    alignSelf: 'flex-end',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  autoComplete50date: {
    paddingLeft: '25px !important',
    width: '100%',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  flexAlign: {
    display: 'flex !important',
    justifyContent: 'space-between',
    '& h2': {
      alignSelf: 'center !important',
    },
  },
  autoComplete5: {
    width: '50%',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  autoComplete50Margin: {
    width: '100%',
    marginTop: '20px !important',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  autoComplete50: {
    width: '100%',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  radioGroupWrapperExpand: {
    marginTop: '20px !important',
    marginRight: '2% !important',
    width: '20%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
    height: 'fit-content',
    '& legend': {
      fontWeight: '600 !important',
      paddingRight: '15px !important'
    },
  },
  radioGroupWrapperClient: {
    marginTop: '20px !important',
    width: '30%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
    height: 'fit-content',
    '& legend': {
      fontWeight: '600 !important',
      paddingRight: '15px !important'
    },
  },
  verticalTabRoot: {
    flexGrow: 1,
    backgroundColor: '#F3F3F3 !important',
    display: 'flex',
    height: 700,
  },
  verticalTabRootMin: {
    flexGrow: 1,
    backgroundColor: '#F3F3F3 !important',
    display: 'flex',
    height: 300,
  },
  verticalTabTabs: {
    marginTop: '60px',
    borderRight: `2px solid #fafafa`,
    backgroundColor: '#FFF !important',
    minWidth: '228px'
  },
  tabRootScroll: {
    flexGrow: 1,
    width: '1395px',
    backgroundColor: '#fff !important',
    padding: '0 2% 2% 2%',
    '@media only screen and (min-width: 300px) and (max-width: 1000px)': {
      width: '250px !important',
    },
    '@media only screen and (min-width: 1000px) and (max-width: 1100px)': {
      width: '350px !important',
    },
    '@media only screen and (min-width: 1100px) and (max-width: 1200px)': {
      width: '450px !important',
    },
    '@media only screen and (min-width: 1200px) and (max-width: 1300px)': {
      width: '550px !important',
    },
    '@media only screen and (min-width: 1300px) and (max-width: 1400px)': {
      width: '650px !important',
    },
    '@media only screen and (min-width: 1400px) and (max-width: 1500px)': {
      width: '750px !important',
    },
    '@media only screen and (min-width: 1500px) and (max-width: 1600px)': {
      width: '850px !important',
    },
    '@media only screen and (min-width: 1600px) and (max-width: 1700px)': {
      width: '900px !important',
    },
    '@media only screen and (min-width: 1700px) and (max-width: 1800px)': {
      width: '1100px !important',
    },
    '@media only screen and (min-width: 1800px) and (max-width: 1900px)': {
      width: '1300px !important',
    },
    '@media only screen and (min-width: 1900px) and (max-width: 2000px)': {
      width: '1300px !important',
    },
  },
  tabRootScrollMain: {
    flexGrow: 1,
    width: '1635px !important',
    backgroundColor: '#fff !important',
    padding: '0 2% 2% 2%',
    '@media only screen and (min-width: 300px) and (max-width: 1000px)': {
      width: '550px !important',
    },
    '@media only screen and (min-width: 1000px) and (max-width: 1100px)': {
      width: '650px !important',
    },
    '@media only screen and (min-width: 1100px) and (max-width: 1200px)': {
      width: '750px !important',
    },
    '@media only screen and (min-width: 1200px) and (max-width: 1300px)': {
      width: '850px !important',
    },
    '@media only screen and (min-width: 1300px) and (max-width: 1400px)': {
      width: '950px !important',
    },
    '@media only screen and (min-width: 1400px) and (max-width: 1500px)': {
      width: '1050px !important',
    },
    '@media only screen and (min-width: 1500px) and (max-width: 1600px)': {
      width: '1150px !important',
    },
    '@media only screen and (min-width: 1600px) and (max-width: 1700px)': {
      width: '1300px !important',
    },
    '@media only screen and (min-width: 1700px) and (max-width: 1800px)': {
      width: '1400px !important',
    },
    '@media only screen and (min-width: 1800px) and (max-width: 1900px)': {
      width: '1500px !important',
    },
    '@media only screen and (min-width: 1900px) and (max-width: 2000px)': {
      width: '1600px !important',
    },

  },
  tabRoot: {
    flexGrow: 1,
    backgroundColor: '#F3F3F3 !important',
    display: 'flex',
  },
  tabTabs: {
    marginTop: '3%',
    borderRight: `2px solid #fafafa`,
  },
  lblClients: {
    position: 'absolute',
    backgroundColor: '#fff',
    textAlign: 'center',
    paddingTop: '1%',
    marginBottom: '1%',
    width: '228px',
    height: '50px'
  },
  tabBody: {
    width: '100%',
    backgroundColor: '#F3F3F3 !important',
    marginLeft: '10px !important',
  },
  internalButtongrid: {
    width: '100%',
    display: 'flex',
    marginRight: '25px',
    justifyContent: 'center',
  },
  internalButtongridBottom: {
    width: '100%',
    display: 'flex',
    marginRight: '25px',
    marginBottom: '10px',
    justifyContent: 'center',
  },
  buttonWrapperClient: {
    width: '30%',
    marginTop: '30px !important',
  },
  buttonWrapper: {
    width: '25%',
    marginTop: '30px !important',
  },
  googlePlaceInput: {
    width: '30%',
    marginTop: '15px',
    '& input': {
      maxWidth: 'unset !important',
    },
    '& form': {
      '& div': {
        width: '100%',
      },
    },
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnSave: {
    width: '100%',
    color: '#fff !important',
    fontWeight: 'bold !important',
    fontSize: '15px !important',
  },
  fullWidth: {
    width: '100% !important',
    marginTop: '16px !important',
  },
  usersLeft: {
    width: '49%',
  },
  breadcrumb: {
    '& p': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '500px'
    },
  },
  breadcrumbMargin: {
    marginBottom: '10px',
    '& p': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '500px'
    },
  },
  internalNoteGrid: {
    paddingTop: '20px',
    display: 'flex',
    width: '100%',
    // justifyContent: 'normal',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& input[type="text"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      width: '25%',
    },
    '& input[type="textarea"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
    '& fieldset': {
      marginTop: '20px !important',
      marginLeft: '50px',
      width: '10% !important'
    },
    '& div': {
      marginBottom: 0,
    },
    '& div[class*=-placeholder]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  pt10: {
    paddingTop: '10px'
  },
  pt20: {
    paddingTop: '20px'
  },
  width45: {
    width: '45% !important',
    marginRight: '20px !important'
  },
  width50: {
    width: '50% !important',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },
    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  flexAlign: {
    display: 'flex !important',
    justifyContent: 'space-between',
    '& h2': {
      alignSelf: 'center !important',
    },
  },
  width100: {
    width: '100% !important',
    marginRight: 'unset!important',
  },
  width100Check: {
    width: '100% !important',
    marginRight: 'unset!important',
    marginTop: '16px'
  },
  serviceEntryDates: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  serviceListFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingBottom: '10px'
  },
  selectedDates: {
    '& small': {
      marginTop: 20,
      marginRight: 20,
    },
  },
  serviceEntryCalander: {
    paddingTop: '20px',
    '& label': {
      marginLeft: 25,
      paddingBottom: '10px'
    },
  },
  dateWrapper: {
    display: 'inline !important',
    padding: '20px !important',
    width: '30%',
    border: '1px solid lightblue !important',
    borderRadius: '10px !important',
    marginTop: '20px !important',
    marginRight: '20px !important',
    '&:hover': {
      boxShadow: 'lightgrey 0px 10px 20px 1px',
    },
  },
  dateWrapperClose: {
    zIndex: '9999',
    position: 'absolute !important',
    right: '12px !important',
    marginTop: '-25px !important'
  },
  flexWrapper: {
    display: 'flex !important',
    justifyContent: 'center',
    paddingTop: '2%'
  },
  defaultInput: {
    fontSize: '20px',
    '& label': {
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },
    '& input[type="text"]': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
    },
  },
  dateBoxIn: {
    width: '60%',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },
    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  dateBoxSe: {
    width: '30%',
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },
    '& div': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  dateBoxInput: {
    width: '50%',
    paddingRight: '20px !important',
    '& button': {
      display: 'none !important',
    },
    '& label': {
      color: variables.labelDefaultColor,
      fontSize: variables.defaultFontSize,
      fontWeight: 'bold',
    },

    '& input': {
      maxWidth: '100% !important',
      fontSize: variables.defaultFontSize,
      color: variables.labelDefaultColor,
    },
  },
  flexAlign: {
    display: 'flex !important',
    justifyContent: 'space-between',
    '& h2': {
      alignSelf: 'center !important',
    },
  },
  dialogTtl: {
    padding: '0px 24px !important'
  },
  dialogHead2: {
    padding: '2% 0!important',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      fontSize: '1.25rem !important',
      alignSelf: 'center',
    }
  },
  dialogHead: {
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      fontSize: '1.25rem !important',
      alignSelf: 'center',
    }
  },
  pb10: {
    paddingBottom: '10px'
  },
  createNote: {
    boxShadow: 'lightgrey 0px 0px 10px 1px',
    padding: '15px',
    margin: '10px 0',
    '&:hover': {
      boxShadow: 'lightgrey 0px 0px 10px 5px',
    },
  },
  radioError: {
    color: 'red !important',
    alignSelf: 'center !important',
    fontWeight: 'bold !important',
  }

});
