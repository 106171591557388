import { Component } from 'react'
import { authorities } from './permissionsEnums'
import * as decode from 'jwt-decode';
import { getTokenAuthorities, getUserType, getCUPermInfo, getSessionTimeoutValues } from '../services/authService'


export function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return month + '/' + day + '/' + year;
}

export function hasNumber(myString) { return /\d/.test(myString); }

export function hasLowerCase(str) { return (/[a-z]/.test(str)); }

export function hasUpperCase(str) { return (/[A-Z]/.test(str)); }

export function filterOptions(extArr, suggestions) {
    var extIds = []
    var returnArr = []

    if (extArr) {
        extArr.filter(function (extItem) {
            extIds.push(extItem.id)
            return true;
        })
    }

    if (suggestions) {
        suggestions.filter(function (sugItem) {
            if (!extIds.includes(sugItem.id)) {
                returnArr.push(sugItem)
            }
            return true;
        })
    }

    return returnArr
}

export function filterOptionsValue(extArr, suggestions) {
    var extIds = []
    var returnArr = []

    if (suggestions) {
        extArr.filter(function (extItem) {
            if (extItem) {
                extIds.push(extItem.value)
            }
            return true;
        })
    }

    if (suggestions) {
        suggestions.filter(function (sugItem) {
            if (!extIds.includes(sugItem.value)) {
                returnArr.push(sugItem)
            }
            return true;
        })
    }

    return returnArr
}

export function getCUPermInfoDBSAClients() {

    const tokenPermInfoArray = getCUPermInfo()

    if (tokenPermInfoArray && tokenPermInfoArray['DB-SA'] && tokenPermInfoArray['DB-SA'].clients) {
        return tokenPermInfoArray['DB-SA'].clients
    } else {
        return []
    }
}

export function getCUPermInfoDBSIClients() {

    const tokenPermInfoArray = getCUPermInfo()

    if (tokenPermInfoArray && tokenPermInfoArray['DB-SI'] && tokenPermInfoArray['DB-SI'].clients) {
        return tokenPermInfoArray['DB-SI'].clients
    } else {
        return []
    }
}

export function getLoggedInUserType() { return getUserType() }

export function getTimeoutValues() { return getSessionTimeoutValues() }

function decryptToken(token) { return decode(token) }

export function checkAllowedAuthorities(permission) {

    const tokenAuthoritiesArray = getTokenAuthorities()

    if (permission && tokenAuthoritiesArray.length) {
        return tokenAuthoritiesArray.includes(authorities[permission]) ? true : false
    } else {
        return false
    }
}

export function getTokenData() {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
        const decryptedToken = decryptToken(accessToken);
        return {
            "id": decryptedToken.user_id ? decryptedToken.user_id : null,
            "name": decryptedToken.liuName ? decryptedToken.liuName : null,
            "userName": decryptedToken.user_name ? decryptedToken.user_name : null,
            "role": decryptedToken.role ? decryptedToken.role : null,
            "clients": (decryptedToken.cuPermInfo && decryptedToken.cuPermInfo.OC && decryptedToken.cuPermInfo.OC.clients) ? decryptedToken.cuPermInfo.OC.clients : [],
        }
    } else {
        return null
    }
}

export class AllowedAuthorities extends Component {

    constructor(props) {
        super(props)
        this.state = { showchildren: true, permissionOnly: this.props.permissionOnly }
    }

    componentDidMount() {

        const tokenAuthoritiesArray = getTokenAuthorities()

        if (this.state.permissionOnly && this.state.permissionOnly.length) {

            this.state.permissionOnly.map(permission => {

                if (!tokenAuthoritiesArray.includes(authorities[permission])) {
                    this.setState({ showchildren: false })
                }
                return true;
            })
        }
    }

    render() {
        return (
            this.state.showchildren && this.props.children
        )
    }
}