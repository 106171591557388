import React from 'react'
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core"

import './LoginSessionExtendedPopup.scss'
 
export const LoginSessionExtendedPopup = ({
    t,
    seconds,
    handleSessionExtended,
    sessionExtendedDialogOpen,
    handleSignOut
}) => (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={sessionExtendedDialogOpen}
            onClose={handleSignOut}
            aria-labelledby="customized-dialog-title"
        >
            <DialogTitle id="customized-dialog-title" onClose={handleSignOut} className="dialog__title-default">{t('LABEL_SESISON_EXTEND_NOTIFICATION_HEADER')}</DialogTitle>
            <DialogContent dividers>
                <div className="internal__wrapper-content">
                    <div className="internal__content-grid">
                        <p>{t('LABEL_SESISON_EXTEND_NOTIFICATION')}
                            {' ' + seconds + ' seconds'}
                            {t('LABEL_SESISON_EXTEND_NOTIFICATION2')} </p>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={handleSignOut}>{t('LABEL_SIGN_OUT')}</Button>
                <Button type="button" onClick={handleSessionExtended} color="primary" variant="contained">{t('LABEL_STAY_SIGN_IN')}</Button>
            </DialogActions>
        </Dialog>
    )