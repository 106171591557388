import React, { Component } from 'react'
import { css } from 'aphrodite-jss'

import { styles } from './SkeltonScreens.jsx'

export default class TableSkeltonScreenMini extends Component {
    render() {

        // const { errors } = this.props

        return (
            <>


                <div className={css(styles.container)}>

                    {/* <div className={css(styles.errors)}>
                        {errors && (
                            errors.map((error, i) =>
                                <p>{error}</p>
                            )
                        )}
                    </div> */}

                    <div className={css(styles.header)}>
                        <div className={css(styles.headerLine)}></div>
                        <div className={css(styles.headerLine)}></div>
                        <div className={css(styles.headerLine)}></div>
                        <div className={css(styles.headerLine)}></div>
                        <div className={css(styles.headerLine)}></div>
                        <div className={css(styles.headerLine)}></div>
                        <div className={css(styles.headerLine)}></div>
                        <div className={css(styles.headerLine)}></div>
                    </div>

                    <div className={css(styles.post)}>
                        <div className={css(styles.line)}></div>
                        <div className={css(styles.line)}></div>
                        <div className={css(styles.line)}></div>
                        <div className={css(styles.line)}></div>
                        <div className={css(styles.line)}></div>
                        <div className={css(styles.line)}></div>
                        <div className={css(styles.line)}></div>
                        <div className={css(styles.line)}></div>
                    </div>
                </div>
            </>
        )
    }
}