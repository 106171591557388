export const authorities = {
    SEARCH_CLIENT: 'clt-s',
    CREATE_CLIENT: 'clt-c',
    UPDATE_CLIENT: 'clt-u',
    SEARCH_CLIENT_USERS: 'cu-s',
    CREATE_CLIENT_USER: 'cu-c',
    UPDATE_CLIENT_USER: 'cu-u',
    SEARCH_STUDENTS: 'std-s',
    CREATE_STUDENT: 'std-c',
    UPDATE_STUDENT: 'std-u',
    SEARCH_MBT_AGENTS: 'ma-s',
    CREATE_MBT_AGENT: 'ma-c',
    UPDATE_MBT_AGENT: 'ma-u',
    SEARCH_PRAC_CREDENTIALS: 'pcr-s',
    CREATE_PRAC_CREDENTIAL: 'pcr-c',
    UPDATE_PRAC_CREDENTIAL: 'pcr-u',
    SEARCH_REASON_FOR_SERVICES: 'rfs-s',
    CREATE_REASON_FOR_SERVICE: 'rfs-c',
    UPDATE_REASON_FOR_SERVICE: 'rfs-u',
    SEARCH_SERVICE_TYPES: 'st-s',
    CREATE_SERVICE_TYPE: 'st-c',
    UPDATE_SERVICE_TYPE: 'st-u',
    SEARCH_CUSTOMIZED_RATES: 'cr-s',
    CREATE_CUSTOMIZED_RATES: 'cr-c',
    DELETE_CUSTOMIZED_RATES: 'cr-d',
    DASHBOARD_STUDENT_CREATE: 'dbst-c',
    DASHBOARD_STUDENT_INFO_SEARCH: 'dbsti-s',
    DASHBOARD_RECENT_SERVICES_SEARCH: 'dbrs-s',
    DASHBOARD_MER_CALCULATOR: 'dbmer-s',

    SERVICE_ENTRY_SUBMIT: 's-c',
    SERVICE_LIST_SEARCH: 's-s',
    CASELOAD_SEARCH: 'clg-s',
    CREATE_CASELOAD_GROUP: 'clg-c',
    UPDATE_CASELOAD_GROUP: 'clg-u',
    DELETE_CASELOAD_GROUP: 'clg-d',
    SUBMIT_CASELOAD_GROUP: 'clg-su',
    UPDATE_SERVICE_STATUS: 's-us',
    UPDATE_SERVICE_NOTE: 's-un',
    UPDATE_RMTS: 's-ur',
    DELETE_SERVICE: 's-d',
    SEARCH_PREVIOUS_SUBMISSIONS: 'sb-s',
    CREATE_NEW_SUBMISSIONS: 'sb-c',
    UPDATE_SUBMISSIONS: 'sb-u',

    VIEW_EXPORT_SERVICE_REPORT: 'sr-v',
    VIEW_EXPORT_CLAIM_DETAILS_REPORT: 'cdr-v',
    VIEW_EXPORT_PRACTITIONER_TRACKING_REPORT: 'ptr-v',
    VIEW_EXPORT_STUDENT_REPORT: 'str-v',
    VIEW_EXPORT_PRACTITIONER_REPORT: 'pr-v',
    VIEW_EXPORT_CLIENT_INFO_REPORT: 'cir-v',
    VIEW_EXPORT_PRACTITIONER_CREDENTIAL_REPORT: 'pcr-v',

    FILE_PROCESSOR_ENROLLMENT_SEARCH_AND_VIEW: 'enr-s',
    FILE_PROCESSOR_LEA_BILLING_SEARCH_AND_VIEW: 'lb-s',
    FILE_PROCESSOR_SEARCH_TSP_LIST: 'tl-s',

    SEARCH_VIEW_PAYMENTS: 'p-s',
}
