import jwtDecode from 'jwt-decode'

export const promiseReject = value => new Promise((resolve, reject) => reject(value))

export const base64Encode = string => window.btoa(string)

export const base64Decode = string => jwtDecode(string)

// export const generateUUID = _ => {
//     let d = new Date().getTime()
//     if (Date.now)
//         d = Date.now()
//     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
//         let r = (d + Math.random() * 16) % 16 | 0
//         d = Math.floor(d / 16)
//         return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
//     })
// }
