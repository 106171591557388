import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import { withRouter } from "react-router"
import Typography from '@material-ui/core/Typography'
import { withNamespaces } from 'react-i18next'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { StyleSheet } from 'aphrodite-jss'
import { css } from 'aphrodite-jss'
import { Redirect } from 'react-router-dom'

import { baseContentUrl } from '../../utils/config'

import { logoutUser } from '../../actions/sessionActions'

import { LocalStorageService } from '../../services/localStorageService'
// import { AdministrationIcon } from '../../assets/images/icons/administration.jsx'
import { StudentsAllIcon } from '../../assets/images/icons/students-all.jsx'
import { StudentsInfoIcon } from '../../assets/images/icons/student-info.jsx'
import { NotificationIcon } from '../../assets/images/icons/notify.jsx'
import { RecentServiceIcon } from '../../assets/images/icons/recent-services.jsx'
import { ProfileIcon } from '../../assets/images/icons/profile.jsx'

import ShowNotificationDialogContainer from '../../components/ShowNotificationDialog/ShowNotificationDialogContainer'
import MERCalculatorDialogContainer from '../../components/MERCalculator/MERCalculatorContainer'
import AddNewStudentDialogContainer from '../../components/AddNewStudentDialog/AddNewStudentDialogContainer'
import StudentInformationDialogContainer from '../../components/StudentInformationDialog/StudentInformationDialogContainer'
import RecentlyEnteredServicesDialogContainer from '../../components/RecentlyEnteredServicesDialog/RecentlyEnteredServicesDialogContainer'
import PasswordChangeDialogContainer from '../../components/PasswordChangeDialog/PasswordChangeDialogContainer'
import { checkAllowedAuthorities, getTokenData, getLoggedInUserType } from '../../constants/helpers.js'
import { LiveChatWidget } from "@livechat/widget-react";

import {
    customScreenType,
    customScreenSizes
} from '../../constants/mediaConstants'

import './header.scss'
import { fetchRecentServicesAction } from '../../actions/generalActions'
import ReactGA from "react-ga4";
import { MERIcon } from '../../assets/images/icons/mer.jsx'
ReactGA.initialize(process.env.REACT_APP_ANALYTICS_MID);
ReactGA.send({ hitType: "pageview", page: window.location.hash });

const styles = StyleSheet.create({
    appbarBgImageExtended: {
        backgroundRepeat: 'no-repeat',
        width: '171px',
        height: '49px',
        backgroundImage: `url(${baseContentUrl}/images/MBT_Logo.png)`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        position: 'relative',
        left: '0'
    }
})

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tokenData: getTokenData(),
            redirectServiceList: false,
            allowAuthorityStudentCreate: checkAllowedAuthorities('DASHBOARD_STUDENT_CREATE'),
            allowAuthorityStudentInfoSearch: checkAllowedAuthorities('DASHBOARD_STUDENT_INFO_SEARCH'),
            allowAuthorityRecentServiceSearch: checkAllowedAuthorities('DASHBOARD_RECENT_SERVICES_SEARCH'),
            allowAuthorityMERCalculator: checkAllowedAuthorities('DASHBOARD_MER_CALCULATOR'),
            userType: getLoggedInUserType(),
            anchorEl: null,
            serviceDialogOpen: false,
            addStudentInfoDialog: false,
            notificationDialog: false,
            studentInfoDialogOpen: false,
            merCalculatorDialogOpen: false,
            passwordChangeDialogOpen: false
        }
    }

    componentWillMount() {
        window.addEventListener("resize", this.updateDimensionsEventHandler, true)
    }

    componentDidMount() {

    }

    updateDimensionsEventHandler = _ => this.handleTheTopHeaderMobileResponsiveness()

    handleTheTopHeaderMobileResponsiveness = _ => {
        if (window.innerWidth > customScreenSizes.medium) {
            this.setState({ screenType: customScreenType.large, isMobileView: false })
        } else if ((window.innerWidth <= customScreenSizes.medium) && (window.innerWidth > customScreenSizes.small)) {
            this.setState({ screenType: customScreenType.medium, isMobileView: false })
        } else if ((window.innerWidth <= customScreenSizes.small) && (window.innerWidth > customScreenSizes.mobile)) {
            this.setState({ screenType: customScreenType.small, isMobileView: true })
        } else if (window.innerWidth <= customScreenSizes.mobile) {
            this.setState({ screenType: customScreenType.mobile, isMobileView: true })
        }
    }
    handleShowNotificationDialog = _ => this.setState({ notificationDialog: true })

    handleHideNotificationDialog = _ => this.setState({ notificationDialog: false })

    handleShowAddNewStudentDialog = _ => this.setState({ addStudentInfoDialog: true })

    handleHideAddNewStudentDialog = _ => this.setState({ addStudentInfoDialog: false })

    handleShowStudentInfoDialog = _ => this.setState({ studentInfoDialogOpen: true })

    handleHideStudentInfoDialog = _ => this.setState({ studentInfoDialogOpen: false })

    handleShowServiceDialog = _ => { this.props.fetchRecentServices(); this.setState({ serviceDialogOpen: true }) }

    handleHideServiceDialog = _ => this.setState({ serviceDialogOpen: false })

    handleMerCalculatorDialog = _ => this.setState({merCalculatorDialogOpen: true})

    goToServiceList = _ => { this.setState({ serviceDialogOpen: false, redirectServiceList: true }) }

    redirectServiceListFalse = _ => { this.setState({ redirectServiceList: false }) }

    showProfileMenu = e => this.setState({ anchorEl: e.target })

    handleProfileMenuClose = _ => this.setState({ anchorEl: null })

    showPasswordUpdateDialog = _ => this.setState({ passwordChangeDialogOpen: true, anchorEl: null })

    hidePasswordUpdateDialog = _ => this.setState({ passwordChangeDialogOpen: false })

    logoutUser = _ => {
        this.props.logoutUser()
        LocalStorageService.clearLocalStorage()
        window.location.href = '/'
    }

    onAddStudentDialogChange = _ => this.setState({ addStudentInfoDialog: false })

    render() {

        const { anchorEl, allowAuthorityStudentCreate, allowAuthorityStudentInfoSearch, allowAuthorityRecentServiceSearch, redirectServiceList, allowAuthorityMERCalculator } = this.state
        const { t, drawerOpen } = this.props

        if (redirectServiceList) {
            this.redirectServiceListFalse()
            return <Redirect to={`/opticlaim/service-list/` + Math.floor(Math.random() * Math.floor(100))} />
        }

        return (
            <>
                {!drawerOpen && <Typography variant="h6" noWrap className={css(styles.appbarBgImageExtended)}></Typography>}
                <div className="mobile__view-only">
                    <img src={`${baseContentUrl}/images/MBT_Logo.png`} alt="" />
                </div>
                <div className="header__toolbar-content">
                    <div className="header__toolbar-left-content">

                        {/* =========== QUIKCK LINKS GOES HERE ============= */}
                        {/* <div className="header__nav-block">
                            <NavLink to="/">
                                <AdministrationIcon />
                                <span>{'Administration'}</span>
                            </NavLink>
                        </div> */}
                        {/* =========== QUIKCK LINKS GOES HERE ============= */}

                    </div>
                    <div className="header__toolbar-right-content">

                        {/* {allowAuthorityStudentCreate === true &&
                            <div className="header__nav-block" onClick={this.handleShowNotificationDialog}>
                                <NavLink to="#">
                                    <NotificationIcon />
                                    <span>{t('LABEL_UPDATE_NOTIFICATION')}</span>
                                </NavLink>
                            </div>
                        } */}

                        {allowAuthorityMERCalculator === true &&
                            <div className="header__nav-block" onClick={this.handleMerCalculatorDialog}>
                                <NavLink to="#">
                                    <MERIcon />
                                    <span>{t('LABEL_CALCULATE_MER')}</span>
                                </NavLink>
                            </div>
                        }

                        {allowAuthorityStudentCreate === true &&
                            <div className="header__nav-block" onClick={this.handleShowAddNewStudentDialog}>
                                <NavLink to="#">
                                    <StudentsAllIcon />
                                    <span>{t('LABEL_ADD_STUDENT')}</span>
                                </NavLink>
                            </div>
                        }

                        {allowAuthorityStudentInfoSearch === true &&
                            <div className="header__nav-block" onClick={this.handleShowStudentInfoDialog}>
                                <NavLink to="#">
                                    <StudentsInfoIcon />
                                    <span>{t('LABEL_STUDENT_INFO_SHORT')}</span>
                                </NavLink>
                            </div>
                        }

                        {allowAuthorityRecentServiceSearch === true &&
                            <div className="header__nav-block" onClick={this.handleShowServiceDialog}>
                                <NavLink to="#">
                                    <RecentServiceIcon />
                                    <span>{t('LABEL_RECENT_SERVICE')}</span>
                                </NavLink>
                            </div>
                        }

                        <div className="header__nav-block extra__link-section">
                            <ProfileIcon showMenu={this.showProfileMenu} />
                            <span onClick={this.showProfileMenu}>{t('LABEL_MY_PROFILE')}</span>
                            <Menu
                                keepMounted
                                id="profileMenu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleProfileMenuClose}
                            >
                                <MenuItem onClick={this.showPasswordUpdateDialog}>{t('LABEL_CHANGE_PASSWORD')}</MenuItem>
                                <MenuItem onClick={this.logoutUser}>
                                    <span>{t('LABEL_LOGOUT')}</span>
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>

                {allowAuthorityMERCalculator === true && <MERCalculatorDialogContainer 
                    state={this.state} 
                    handleDialogClose={() => this.setState({merCalculatorDialogOpen: false})}
                />}

                <ShowNotificationDialogContainer
                    t={t}
                    state={this.state}
                    handleAddClose={this.handleHideNotificationDialog}
                />

                <AddNewStudentDialogContainer
                    t={t}
                    state={this.state}
                    handleAddClose={this.handleHideAddNewStudentDialog}
                    onAddStudentDialogChange={this.onAddStudentDialogChange}
                />

                <StudentInformationDialogContainer
                    t={t}
                    state={this.state}
                    handleInfoClose={this.handleHideStudentInfoDialog}
                    redirectClose={this.handleHideStudentInfoDialog}
                />

                <RecentlyEnteredServicesDialogContainer
                    t={t}
                    state={this.state}
                    // recentServices={this.props.recentServices}
                    // recentServicesLoading={this.props.recentServicesLoading}
                    goToServiceList={this.goToServiceList}
                    handleServicesClose={this.handleHideServiceDialog}
                />

                <PasswordChangeDialogContainer
                    t={t}
                    state={this.state}
                    handlePasswordChange={this.hidePasswordUpdateDialog}
                />
                {this.state.tokenData.role !== "MA" &&
                    <LiveChatWidget
                        license={"3514881"}
                        customerName={this.state.tokenData.name}
                        customerEmail={this.state.tokenData.userName}
                    />
                }
            </>
        )
    }

}

const mapStateToProps = ({ navigation, general }) => ({
    drawerOpen: navigation.open,
    recentServices: general.recentServices,
    recentServicesLoading: general.recentServicesLoading,
})

const mapDispatchToProps = dispatch => ({
    logoutUser: _ => dispatch(logoutUser()),
    fetchRecentServices: _ => dispatch(fetchRecentServicesAction()),
})

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(Header))
