import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { deleteStudentGoalsAction, fetchStudentInfoAction } from '../../../actions/generalActions'

class DeleteNotifyMessageCellRenderer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // id: this.props.params.id, studentId: this.props.params.studnetId, 
            open: false
        }

        this.deleteStudent = this.deleteStudent.bind(this)
    }

    handleConfirm = () => { this.setState({ open: false }); this.deleteStudent(); }

    handleClose = () => this.setState({ open: false })

    handleOpen = () => this.setState({ open: true })

    deleteStudent() {
        // this.props.deleteStudentGoals({ id: this.props.params.id, studentId: this.state.studentId }).then(data => this.setSubmitState(data)) 
    }

    setSubmitState(response) {
        if (response && response.statusCode && response.statusCode === 200) {
            // toast.success(<SuccessfullyDeleted />);
            this.props.listStudentGoals({ studentId: this.state.studentId })
        }
    }

    render() {
        const { t } = this.props

        return (
            <>
                <Tooltip title="Delete">
                    <DeleteOutlineIcon onClick={this.handleOpen} />
                </Tooltip>
                <Dialog disableEnforceFocus={true} open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle> {t('LABEL_ARE_YOU_SURE')} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {t('LABEL_ABOUT_TO_DELETE')} </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.handleClose} color="primary"> {t('LABEL_CANCEL')} </Button>
                        <Button onClick={this.handleConfirm} color="primary"> {t('LABEL_CONFIRM')} </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = ({ general }) => {
    return {
        loading: general.loading,
    }
}

const mapDispatchToProps = dispatch => ({
    listStudentGoals: id => dispatch(fetchStudentInfoAction(id)),
    deleteStudentGoals: params => dispatch(deleteStudentGoalsAction(params)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(DeleteNotifyMessageCellRenderer)