import React, { Component } from 'react'
import { connect } from 'react-redux'

import RecentlyEnteredServicesDialog from './RecentlyEnteredServicesDialog.js'

class RecentlyEnteredServicesDialogContainer extends Component {

    state = {
        rows: []
    }

    onSubmit = _ => { }

    handleSubmit = _ => { }

    render() {
        const { t, state, handleServicesClose, goToServiceList } = this.props
        return (
            <RecentlyEnteredServicesDialog
                t={t}
                state={this.state}
                tokenData={this.props.state.tokenData}
                handleServicesClose={handleServicesClose}
                goToServiceList={goToServiceList}
                serviceDialogOpen={state.serviceDialogOpen}
            />
        )
    }
}

const mapStateToProps = ({ general }) => ({
    loading: general.loading
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyEnteredServicesDialogContainer)
