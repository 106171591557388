import React from 'react';
import BarChartIcon from '@material-ui/icons/BarChart';

export const PermCalender = _ => (
  <svg viewBox="0 0 24 24" aria-hidden="true" fill="white">
    <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />

    <circle cx="12" cy="14" r="3" />
    <path d="M16 20c0-2-2-3-4-3s-4 1-4 3h8z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
);
