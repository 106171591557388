import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import ListSubheader from "@material-ui/core/ListSubheader"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
// import _ from 'lodash'

import { getItems } from '../Navigation/navigationItems'
// import { permissionsKeyTypes } from '../../constants/permissions'
import './Navigation.scss'

class Navigation extends Component {

    constructor(props) {
        super(props)
        const urlSegments = this.props.location.pathname.split('/')
        this.state = {
            path:  urlSegments[1] + '/' + urlSegments[2]
        }
    }

    handleClick = (name, url) => {
        this.setState({ [name]: !this.state[name] })
        if (url) this.props.history.push(`/${url}`)
        this.setState({ path: this.props.history.location.pathname })        
    }

    closeTheExpandable = _ => this.props.expandedSubMenuClose()

    render() {

        const items = getItems()
        const { 
            moduleName, 
            currentLink,
            // permissions
        } = this.props

        return (
            <div className="navigation__menu-wrapper">
                {items.list.map(list => (
                    // (permissions.indexOf(list.permission) > -1) &&
                    <List
                        key={list.id}
                        subheader={<ListSubheader>{list.title}</ListSubheader>}
                    >
                        {list.items.map(item => (
                            <div key={item.id}>
                                {item.subitems != null ? (
                                    <div key={item.id}>
                                        <ListItem button key={item.id} onClick={() => this.handleClick(item.name, item.url)}>
                                            <ListItemIcon>{item.icon}</ListItemIcon>                                                             
                                            <ListItemText primary={item.name} />
                                            {(this.state[item.name] || (moduleName === item.name)) ? (<ExpandLess onClick={this.closeTheExpandable} />) : (<ExpandMore />)}
                                        </ListItem>
                                        <Collapse         
                                            unmountOnExit            
                                            timeout="auto"                               
                                            component="li"                                                    
                                            key={list.items.id}
                                            in={(this.state[item.name] || (moduleName === item.name))}                                                                                                        
                                        >
                                            <List disablePadding>
                                                {item.subitems.map(sitem => (
                                                    // (permissions.indexOf(sitem.permission) > -1) &&
                                                    <ListItem 
                                                        button 
                                                        key={sitem.id}
                                                        onClick={() => this.handleClick(sitem.name, sitem.url)}     
                                                        className={`sub__menbu-listing ${(currentLink && (sitem.url.indexOf(currentLink) !== -1)) ? 'active__left-link' : ''}`}                                                                                                                                                
                                                    >
                                                        <ListItemIcon>{sitem.icon}</ListItemIcon>   
                                                        <ListItemText key={sitem.id} primary={sitem.name} />
                                                    </ListItem>)
                                                )}
                                            </List>
                                        </Collapse>{" "}
                                    </div>
                                ) : (
                                    // (permissions.indexOf(item.permission) > -1) &&
                                    <ListItem
                                        button
                                        key={item.id}
                                        onClick={() => this.handleClick(item.name, item.url)}     
                                        className={`main__left-link 
                                        ${(this.state.path && (item.subURL.includes(this.state.path))) ? 'active__main-left-link' : ''}
                                        ${(this.state.path && (item.subURL.includes(this.state.path.substring(1)))) ? 'active__main-left-link' : ''}
                                        ${(currentLink && (item.url.indexOf(currentLink) !== -1)) ? 'active__main-left-link' : ''}`}                                                                                                                                                                                                       
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>                                                
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                )}
                            </div>)
                        )}
                        <Divider key={list.id} absolute />
                    </List>)
                )}
            </div>
        )
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = ({ session }) => ({
    permissions: session.permissions
})

export default withRouter(connect(mapStateToProps, null)(Navigation))