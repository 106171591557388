import {
    fetchClientSuggestions, createStudent, fetchClientStudentSuggestions, fetchDashboardRefData, fetchStudentInfo, addStudentGoal, getPasswordResetEmail, activationTokenVarification,
    deleteStudentGoals, fetchRecentServices, resendAccountActivationLink, activateUser, changePassword, resetPasswordVarification, resetPassword, resendResetPasswordLink,
    studentDocumentService, studentDocumentFileUploadService, studentDocumentFileDownloadService, studentDocumentFileDeleteService,
    merClientsSuggetions,
    merYearSuggetions,
    merData,
    studentFileRefDataService
} from '../services/containerService'
import { authByRefreshToken, isTokenValid } from "../services/authService"
import { LocalStorageService } from '../services/localStorageService'


export const GENERAL_ACTION_REQUEST = "GENERAL_ACTION_REQUEST"
export const GENERAL_ACTION_REQUEST_SUCCESS = "GENERAL_ACTION_REQUEST_SUCCESS"
export const GENERAL_ACTION_REQUEST_FAILURE = "GENERAL_ACTION_REQUEST_FAILURE"
export const FETCH_CLIENTS_SUGGESTIONS_REQUEST = "FETCH_CLIENTS_SUGGESTIONS_REQUEST"
export const FETCH_CLIENTS_SUGGESTIONS_SUCCESS_REQUEST = "FETCH_CLIENTS_SUGGESTIONS_SUCCESS_REQUEST"
export const LOAD_FAILURE_REQUEST = "LOAD_FAILURE_REQUEST"
export const CREATE_STUDENT_REQUEST = "CREATE_STUDENT_REQUEST"
export const CREATE_STUDENT_SUCCESS_REQUEST = "CREATE_STUDENT_SUCCESS_REQUEST"
export const FETCH_CLIENT_STUDENTS_SUGGESTION_REQUEST = "FETCH_CLIENT_STUDENTS_SUGGESTION_REQUEST"
export const FETCH_CLIENT_STUDENTS_SUGGESTION_SUCCESS_REQUEST = "FETCH_CLIENT_STUDENTS_SUGGESTION_SUCCESS_REQUEST"
export const FETCH_DASHBOARD_REF_REQUEST = "FETCH_DASHBOARD_REF_REQUEST"
export const FETCH_DASHBOARD_REF_SUCCESS_REQUEST = "FETCH_DASHBOARD_REF_SUCCESS_REQUEST"
export const FETCH_STUDENT_INFO_REQUEST = "FETCH_STUDENT_INFO_REQUEST"
export const FETCH_STUDENT_INFO_SUCCESS_REQUEST = "FETCH_STUDENT_INFO_SUCCESS_REQUEST"
export const ADD_STUDENT_GOAL_REQUEST = "ADD_STUDENT_GOAL_REQUEST"
export const ADD_STUDENT_GOAL_SUCCESS_REQUEST = "ADD_STUDENT_GOAL_SUCCESS_REQUEST"
export const DELETE_STUDENT_GOALS_REQUEST = "DELETE_STUDENT_GOALS_REQUEST"
export const DELETE_STUDENT_GOALS_SUCCESS_REQUEST = "DELETE_STUDENT_GOALS_SUCCESS_REQUEST"
export const FETCH_RECENT_SERVICES_REQUEST = "FETCH_RECENT_SERVICES_REQUEST"
export const FETCH_RECENT_SERVICES_REQUEST_SUCCESS = "FETCH_RECENT_SERVICES_REQUEST_SUCCESS"
export const GET_PASSWORD_RESET_REQUEST = "GET_PASSWORD_RESET_REQUEST"
export const GET_PASSWORD_RESET_SUCCESS_REQUEST = "GET_PASSWORD_RESET_SUCCESS_REQUEST"
export const ACTIVATION_TOKEN_VARIFY_REQUEST = "ACTIVATION_TOKEN_VARIFY_REQUEST"
export const ACTIVATION_TOKEN_VARIFY_SUCCESS_REQUEST = "ACTIVATION_TOKEN_VARIFY_SUCCESS_REQUEST"
export const RESEND_ACTIVATION_LINK_REQUEST = "RESEND_ACTIVATION_LINK_REQUEST"
export const RESEND_ACTIVATION_LINK_SUCCESS_REQUEST = "RESEND_ACTIVATION_LINK_SUCCESS_REQUEST"
export const RESEND_RESET_PASSWORD_LINK = "RESEND_RESET_PASSWORD_LINK"
export const RESEND_RESET_PASSWORD_LINK_SUCCESS_REQUEST = "RESEND_RESET_PASSWORD_LINK_SUCCESS_REQUEST"
export const ACTIVATE_USER = "ACTIVATE_USER"
export const ACTIVATE_USER_REQUEST_SUCCESS = "ACTIVATE_USER_REQUEST_SUCCESS"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD_SUCCESS"
export const RESET_PASS_VARIFICATION = "RESET_PASS_VARIFICATION"
export const RESET_PASS_VARIFICATION_SUCCESS = "RESET_PASS_VARIFICATION_SUCCESS"
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS"

export const testActionRequest = _ => ({
    type: GENERAL_ACTION_REQUEST
})

export const testActionRequestSuccess = _ => ({
    type: GENERAL_ACTION_REQUEST_SUCCESS
})

export const testActionRequestFailure = _ => ({
    type: GENERAL_ACTION_REQUEST_FAILURE
})

const loadClientsSuggestionRequest = params => ({
    type: FETCH_CLIENTS_SUGGESTIONS_REQUEST,
    params
})

const loadClientsSuccessRequest = payload => ({
    type: FETCH_CLIENTS_SUGGESTIONS_SUCCESS_REQUEST,
    payload
})

const loadDashboardRefDataRequest = params => ({
    type: FETCH_DASHBOARD_REF_REQUEST,
    params
})

const loadDashboardRefDataSuccessRequest = payload => ({
    type: FETCH_DASHBOARD_REF_SUCCESS_REQUEST,
    payload
})

const fetchClientStudentSuggestionsRequest = params => ({
    type: FETCH_CLIENT_STUDENTS_SUGGESTION_REQUEST,
    params
})

const fetchClientStudentSuggestionsSuccessRequest = payload => ({
    type: FETCH_CLIENT_STUDENTS_SUGGESTION_SUCCESS_REQUEST,
    payload
})

const createStudentRequest = params => ({
    type: CREATE_STUDENT_REQUEST,
    params
})

const createStudentSuccessRequest = payload => ({
    type: CREATE_STUDENT_SUCCESS_REQUEST,
    payload
})

const loadFailureRequest = payload => ({
    type: LOAD_FAILURE_REQUEST,
    payload
})

const fetchStudentInfoRequest = params => ({
    type: FETCH_STUDENT_INFO_REQUEST,
    params
})

const fetchStudentInfoSuccessRequest = payload => ({
    type: FETCH_STUDENT_INFO_SUCCESS_REQUEST,
    payload
})

const getPasswordResetEmailRequest = params => ({
    type: GET_PASSWORD_RESET_REQUEST,
    params
})

const getPasswordResetEmailSuccessRequest = payload => ({
    type: GET_PASSWORD_RESET_SUCCESS_REQUEST,
    payload
})

const activationTokenVarificationRequest = params => ({
    type: ACTIVATION_TOKEN_VARIFY_REQUEST,
    params
})

const activationTokenVarificationSuccessRequest = payload => ({
    type: ACTIVATION_TOKEN_VARIFY_SUCCESS_REQUEST,
    payload
})


const resendAccountActivationLinkRequest = payload => ({
    type: RESEND_ACTIVATION_LINK_REQUEST,
    payload
})

const resendAccountActivationLinkSuccessRequest = payload => ({
    type: RESEND_ACTIVATION_LINK_SUCCESS_REQUEST,
    payload
})

const resendResetPasswordLinkRequest = payload => ({
    type: RESEND_RESET_PASSWORD_LINK,
    payload
})

const resendResetPasswordLinkSuccessRequest = payload => ({
    type: RESEND_RESET_PASSWORD_LINK_SUCCESS_REQUEST,
    payload
})

const addStudentGoalRequest = params => ({
    type: ADD_STUDENT_GOAL_REQUEST,
    params
})

const addStudentGoalSuccessRequest = payload => ({
    type: ADD_STUDENT_GOAL_SUCCESS_REQUEST,
    payload
})

const deleteStudentGoalsRequest = params => ({
    type: DELETE_STUDENT_GOALS_REQUEST,
    params
})

const deleteStudentGoalsSuccessRequest = payload => ({
    type: DELETE_STUDENT_GOALS_SUCCESS_REQUEST,
    payload
})

const fetchRecentServicesRequest = params => ({
    type: FETCH_RECENT_SERVICES_REQUEST,
    params
})

const fetchRecentServicesSuccessRequest = payload => ({
    type: FETCH_RECENT_SERVICES_REQUEST_SUCCESS,
    payload
})

const activateUserRequest = payload => ({
    type: ACTIVATE_USER,
    payload
})

const activateUserSuccessRequest = payload => ({
    type: ACTIVATE_USER_REQUEST_SUCCESS,
    payload
})

const changePasswordRequest = payload => ({
    type: CHANGE_PASSWORD,
    payload
})

const changePasswordSuccessRequest = payload => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload
})

const resetPasswordVarificationRequest = payload => ({
    type: RESET_PASS_VARIFICATION,
    payload
})

const resetPasswordVarificationSuccessRequest = payload => ({
    type: RESET_PASS_VARIFICATION_SUCCESS,
    payload
})

const resetPasswordRequest = payload => ({
    type: RESET_PASSWORD_REQUEST,
    payload
})

const resetPasswordSuccessRequest = payload => ({
    type: RESET_PASSWORD_REQUEST_SUCCESS,
    payload
})


function authHndler(params, dispatch, response, recursiveFunction, successFunction) {

    if (response && response.validationFailures && response.validationFailures.length > 0) {
        response.validationFailures.map(er => {
            if (er.code === "mbtp.auth.accessToken.expired") {

                setTimeout(() => {
                    if (!isTokenValid()) {
                        authByRefreshToken().then(resp => {
                            if (!resp) {
                                LocalStorageService.clearTokens()
                            }
                            if (resp && resp.data && resp.data.access_token) {
                                LocalStorageService.updateTokens(resp.data)
                                dispatch(recursiveFunction(params));
                                return true;

                            } else {
                                LocalStorageService.clearTokens()
                            }
                        }).catch((error) => {
                            LocalStorageService.clearTokens()
                        })
                    } else {
                        dispatch(recursiveFunction(params));
                        dispatch(successFunction(response))
                        return response;
                    }
                }, 1000)

            } else {
                dispatch(successFunction(response))
                return response;
            }
            return true;
        })
        return response;

    } else {
        dispatch(successFunction(response))
        return response;
    }
}

export const loadDashboardRefDataAction = (keyword, action = null) => dispatch => {
    dispatch(loadDashboardRefDataRequest(keyword))
    return fetchDashboardRefData().then(data => {
        dispatch(loadDashboardRefDataSuccessRequest(data));
        return data;
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}


export const loadClientSuggestions = (keyword, action = null) => dispatch => {
    dispatch(loadClientsSuggestionRequest(keyword))
    return fetchClientSuggestions(keyword).then(data => {
        dispatch(loadClientsSuccessRequest(data));
        return data;
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const fetchClientStudentSuggestionsAction = params => dispatch => {
    dispatch(fetchClientStudentSuggestionsRequest(params))
    return fetchClientStudentSuggestions(params).then(data => {
        dispatch(fetchClientStudentSuggestionsSuccessRequest(data))
        return data;
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}


export const fetchStudentInfoAction = params => dispatch => {
    dispatch(fetchStudentInfoRequest(params))
    return fetchStudentInfo(params).then(data => {
        dispatch(fetchStudentInfoSuccessRequest(data))
        return data;
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const deleteStudentGoalsAction = params => dispatch => {
    dispatch(deleteStudentGoalsRequest(params))
    return deleteStudentGoals(params).then(data => {
        dispatch(deleteStudentGoalsSuccessRequest(data))
        return data;
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const createStudentsAction = params => dispatch => {
    dispatch(createStudentRequest(params))
    return createStudent(params).then(data => {
        dispatch(createStudentSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const addStudentGoalAction = params => dispatch => {
    dispatch(addStudentGoalRequest(params))
    return addStudentGoal(params).then(data => {
        dispatch(addStudentGoalSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const getPasswordResetEmailAction = params => dispatch => {
    dispatch(getPasswordResetEmailRequest(params))
    return getPasswordResetEmail(params).then(data => {
        dispatch(getPasswordResetEmailSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}


export const activationTokenVarificationAction = params => dispatch => {
    dispatch(activationTokenVarificationRequest(params))
    return activationTokenVarification(params).then(data => {
        dispatch(activationTokenVarificationSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const resendAccountActivationLinkAction = params => dispatch => {
    dispatch(resendAccountActivationLinkRequest(params))
    return resendAccountActivationLink(params).then(data => {
        dispatch(resendAccountActivationLinkSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const resendResetPasswordLinkAction = params => dispatch => {
    dispatch(resendResetPasswordLinkRequest(params))
    return resendResetPasswordLink(params).then(data => {
        dispatch(resendResetPasswordLinkSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const activateUserAction = params => dispatch => {
    dispatch(activateUserRequest(params))
    return activateUser(params).then(data => {
        dispatch(activateUserSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const changePasswordAction = params => dispatch => {
    dispatch(changePasswordRequest(params))
    return changePassword(params).then(data => {
        dispatch(changePasswordSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}


export const resetPasswordVarificationAction = params => dispatch => {
    dispatch(resetPasswordVarificationRequest(params))
    return resetPasswordVarification(params).then(data => {
        dispatch(resetPasswordVarificationSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const resetPasswordAction = params => dispatch => {
    dispatch(resetPasswordRequest(params))
    return resetPassword(params).then(data => {
        dispatch(resetPasswordSuccessRequest(data))
        return data
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}





export const fetchRecentServicesAction = param => dispatch => {
    dispatch(fetchRecentServicesRequest())
    return fetchRecentServices().then(data => {
        return authHndler(param, dispatch, data, fetchRecentServicesAction, fetchRecentServicesSuccessRequest);
    }).catch(error => {
        dispatch(loadFailureRequest())
        return error
    })
}

export const STUDENT_DOCUMENT = "STUDENT_DOCUMENT"
export const STUDENT_DOCUMENT_SUCCESS = "STUDENT_DOCUMENT_SUCCESS"
export const STUDENT_DOCUMENT_FAILURE = "STUDENT_DOCUMENT_FAILURE"

const studentDocument = _ => ({
    type: STUDENT_DOCUMENT
})

const studentDocumentSuccess = payload => ({
    type: STUDENT_DOCUMENT_SUCCESS,
    payload
})

const studentDocumentFailure = _ => ({
    type: STUDENT_DOCUMENT_FAILURE
})

export const studentDocumentAction = params => dispatch => {
    dispatch(studentDocument())
    return studentDocumentService(params).then(data => {
        return authHndler(params, dispatch, data, studentDocumentAction, studentDocumentSuccess);
    }).catch(error => {
        dispatch(studentDocumentFailure())
        return error
    })
}

export const STUDENT_DOCUMENT_FILE_UPLOAD = "STUDENT_DOCUMENT_FILE_UPLOAD"
export const STUDENT_DOCUMENT_FILE_UPLOAD_SUCCESS = "STUDENT_DOCUMENT_FILE_UPLOAD_SUCCESS"
export const STUDENT_DOCUMENT_FILE_UPLOAD_FAILURE = "STUDENT_DOCUMENT_FILE_UPLOAD_FAILURE"

const studentDocumentFileUpload = _ => ({
    type: STUDENT_DOCUMENT_FILE_UPLOAD
})

const studentDocumentFileUploadSuccess = payload => ({
    type: STUDENT_DOCUMENT_FILE_UPLOAD_SUCCESS,
    payload
})

const studentDocumentFileUploadFailure = _ => ({
    type: STUDENT_DOCUMENT_FILE_UPLOAD_FAILURE
})

export const studentDocumentFileUploadAction = params => dispatch => {
    dispatch(studentDocumentFileUpload())
    return studentDocumentFileUploadService(params).then(data => {
        return authHndler(params, dispatch, data, studentDocumentFileUploadAction, studentDocumentFileUploadSuccess);
    }).catch(error => {
        dispatch(studentDocumentFileUploadFailure())
        return error
    })
}

export const STUDENT_DOCUMENT_FILE_DOWNLOAD = "STUDENT_DOCUMENT_FILE_DOWNLOAD"
export const STUDENT_DOCUMENT_FILE_DOWNLOAD_SUCCESS = "STUDENT_DOCUMENT_FILE_DOWNLOAD_SUCCESS"
export const STUDENT_DOCUMENT_FILE_DOWNLOAD_FAILURE = "STUDENT_DOCUMENT_FILE_DOWNLOAD_FAILURE"

const studentDocumentFileDownload = _ => ({
    type: STUDENT_DOCUMENT_FILE_DOWNLOAD
})

const studentDocumentFileDownloadSuccess = payload => ({
    type: STUDENT_DOCUMENT_FILE_DOWNLOAD_SUCCESS,
    payload
})

const studentDocumentFileDownloadFailure = _ => ({
    type: STUDENT_DOCUMENT_FILE_DOWNLOAD_FAILURE
})

export const studentDocumentFileDownloadAction = params => dispatch => {
    dispatch(studentDocumentFileDownload())
    return studentDocumentFileDownloadService(params).then(data => {
        return authHndler(params, dispatch, data, studentDocumentFileDownloadAction, studentDocumentFileDownloadSuccess);
    }).catch(error => {
        dispatch(studentDocumentFileDownloadFailure())
        return error
    })
}

export const STUDENT_DOCUMENT_FILE_DELETE = "STUDENT_DOCUMENT_FILE_DELETE"
export const STUDENT_DOCUMENT_FILE_DELETE_SUCCESS = "STUDENT_DOCUMENT_FILE_DELETE_SUCCESS"
export const STUDENT_DOCUMENT_FILE_DELETE_FAILURE = "STUDENT_DOCUMENT_FILE_DELETE_FAILURE"

const studentDocumentFileDelete = _ => ({
    type: STUDENT_DOCUMENT_FILE_DELETE
})

const studentDocumentFileDeleteSuccess = payload => ({
    type: STUDENT_DOCUMENT_FILE_DELETE_SUCCESS,
    payload
})

const studentDocumentFileDeleteFailure = _ => ({
    type: STUDENT_DOCUMENT_FILE_DELETE_FAILURE
})

export const studentDocumentFileDeleteAction = params => dispatch => {
    dispatch(studentDocumentFileDelete())
    return studentDocumentFileDeleteService(params).then(data => {
        return authHndler(params, dispatch, data, studentDocumentFileDeleteAction, studentDocumentFileDeleteSuccess);
    }).catch(error => {
        dispatch(studentDocumentFileDeleteFailure())
        return error
    })
}


export const MER_CLIENT_SUGGESIONS_REQUEST = "MER_CLIENT_SUGGESIONS_REQUEST"
export const MER_CLIENT_SUGGESIONS_LIST_SUCCESS = "MER_CLIENT_SUGGESIONS_LIST_SUCCESS"
export const MER_CLIENT_SUGGESIONS_LIST_FAILURE = "MER_CLIENT_SUGGESIONS_LIST_FAILURE"

const merClientSuggesions = _ => ({
    type: MER_CLIENT_SUGGESIONS_REQUEST
})

const merClientSuggesionsSuccess = payload => ({
    type: MER_CLIENT_SUGGESIONS_LIST_SUCCESS,
    payload
})

const merClientSuggesionsFailure = _ => ({
    type: MER_CLIENT_SUGGESIONS_LIST_FAILURE
})

export const loadMERClientSuggesions = params => dispatch => {
    dispatch(merClientSuggesions())
    return merClientsSuggetions(params).then(data => {
        dispatch(merClientSuggesionsSuccess(data));
        return data;
    }).catch(error => {
        dispatch(merClientSuggesionsFailure())
        return error
    })
}


export const MER_YEAR_SUGGESIONS_REQUEST = "MER_YEAR_SUGGESIONS_REQUEST"
export const MER_YEAR_SUGGESIONS_REQUEST_SUCCESS = "MER_YEAR_SUGGESIONS_REQUEST_SUCCESS"
export const MER_YEAR_SUGGESIONS_REQUEST_FAILURE = "MER_YEAR_SUGGESIONS_REQUEST_FAILURE"

const merYearSuggesionsRequest = _ => ({
    type: MER_YEAR_SUGGESIONS_REQUEST
})

const merYearSuggesionsRequestSuccess = payload => ({
    type: MER_YEAR_SUGGESIONS_REQUEST_SUCCESS,
    payload
})

const merYearSuggesionsRequestFailure = _ => ({
    type: MER_YEAR_SUGGESIONS_REQUEST_FAILURE
})

export const loadMERYearSuggesions = params => dispatch => {
    dispatch(merYearSuggesionsRequest())
    return merYearSuggetions(params).then(data => {
        dispatch(merYearSuggesionsRequestSuccess(data));
        return data;
    }).catch(error => {
        dispatch(merYearSuggesionsRequestFailure())
        return error
    })
}


export const MER_DATA_REQUEST = "MER_DATA_REQUEST"
export const MER_DATA_REQUEST_SUCCESS = "MER_DATA_REQUEST_SUCCESS"
export const MER_DATA_REQUEST_FAILURE = "MER_DATA_REQUEST_FAILURE"

const merDataRequest = _ => ({
    type: MER_DATA_REQUEST
})

const merDataRequestSuccess = payload => ({
    type: MER_DATA_REQUEST_SUCCESS,
    payload
})

const merDataRequestFailure = _ => ({
    type: MER_DATA_REQUEST_FAILURE
})

export const loadMERData = params => dispatch => {
    dispatch(merDataRequest())
    return merData(params).then(data => {
        return authHndler(params, dispatch, data, loadMERData, merDataRequestSuccess);
    }).catch(error => {
        dispatch(merDataRequestFailure())
        return error
    })
}

export const STUDENT_FILE_REF_DATA = "STUDENT_FILE_REF_DATA"
export const STUDENT_FILE_REF_DATA_SUCCESS = "STUDENT_FILE_REF_DATA_SUCCESS"
export const STUDENT_FILE_REF_DATA_FAILURE = "STUDENT_FILE_REF_DATA_FAILURE"

const studentFileRefData = _ => ({
    type: STUDENT_FILE_REF_DATA
})

const studentFileRefDataSuccess = payload => ({
    type: STUDENT_FILE_REF_DATA_SUCCESS,
    payload
})

const studentFileRefDataFailure = _ => ({
    type: STUDENT_FILE_REF_DATA_FAILURE
})

export const studentFileRefDataAction = params => dispatch => {
    dispatch(studentFileRefData())
    return studentFileRefDataService(params).then(data => {
        return authHndler(params, dispatch, data, studentFileRefDataAction, studentFileRefDataSuccess);
    }).catch(error => {
        dispatch(studentFileRefDataFailure())
        return error
    })
}