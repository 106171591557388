export const LocalStorageService = {

    updateLocalStorage(data) {
        localStorage.setItem('userName', data.username)
        localStorage.setItem('userId', data.userId)
        localStorage.setItem('userType', data.userType)
        localStorage.setItem('expire', data.expire)
        localStorage.setItem('clientId', data.clientId)
        localStorage.setItem('accessToken', data.accessToken)
        localStorage.setItem('refreshToken', data.refreshToken)
        localStorage.setItem('permissions', data.permissions)
        localStorage.setItem('scope', data.scope)
        localStorage.setItem('allowedModules', data.allowedModules)
        localStorage.setItem('changePwd', data.changePwd)
    },

    updateTokens(data) {
        localStorage.setItem('accessToken', data.access_token)
        localStorage.setItem('refreshToken', data.refresh_token)
    },

    clearTokens() {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userName')
        localStorage.removeItem('userId')
        localStorage.removeItem('userType')
        localStorage.removeItem('expire')
        localStorage.removeItem('clientId')
        localStorage.removeItem('permissions')
        localStorage.removeItem('scope')
        localStorage.removeItem('changePwd')
        localStorage.removeItem('tempPasswordRedirect')
        localStorage.removeItem('allowedModules')
    },

    clearLocalStorage() {
        localStorage.removeItem('userName')
        localStorage.removeItem('userId')
        localStorage.removeItem('userType')
        localStorage.removeItem('expire')
        localStorage.removeItem('clientId')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('permissions')
        localStorage.removeItem('scope')
        localStorage.removeItem('changePwd')
        localStorage.removeItem('tempPasswordRedirect')
        localStorage.removeItem('allowedModules')
    },

    getTheLocalStorageKey(key) {
        return localStorage.getItem(key)
    },

    setTheLocalStorageKey(key, value) {
        localStorage.setItem(key, value)
    }
}