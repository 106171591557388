export const DRAWER_OPEN = "DRAWER_OPEN"
export const DRAWER_CLOSE = "DRAWER_CLOSE"
export const UPDATE_MODULE_LINK = "UPDATE_MODULE_LINK"

export const openDrawer = _ => ({
    type: DRAWER_OPEN
})

export const closeDrawer = _ => ({
    type: DRAWER_CLOSE
})

export const updateModuleName = moduleName => ({
    type: UPDATE_MODULE_LINK,
    moduleName
})
