import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    InputAdornment,
    Button
} from "@material-ui/core"
import { Form, } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MBTDataTable from '../../reusable/MBTDataTable/MBTDataTable'
import './ShowNotificationDialog.scss'

export const ShowNotificationDialog = ({
    t,
    state,
    onSubmit,
    handleAddClose,
    handleOnChange,
    openAddStudentInfoDialog,
    handleAutoCompleateInputChange,
    handleDateChange,
    handleAutoCompleateChange
}) => (
    <Dialog disableEnforceFocus={true} open={openAddStudentInfoDialog} onClose={handleAddClose} fullWidth maxWidth='lg'>

        <Form onSubmit={onSubmit} render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate >


                <DialogTitle id="form-dialog-title" className='dialog-head'>
                    <div className='heading-tab'>
                        <span>Update Notifications</span>
                        <IconButton edge="end" color="inherit" onClick={handleAddClose} aria-label="close">
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent >

                    <h2>Current Messages</h2>

                    <div className='overflow-div1'>

                        {(state.notifyCurrentMessages && state.notifyCurrentMessages.length > 0) && (

                            <MBTDataTable
                                data={state.notifyCurrentMessages}
                                columns={state.notifyCurrentMessagesColumns}
                                totalRecords={state.notifyCurrentMessages.length}
                                options={state.mbtDTOptions}
                                pagination={state.pagination}
                            />
                        )}

                        {(!state.notifyCurrentMessages || state.notifyCurrentMessages.length === 0) && (<p className='no-records'>No Records Found</p>)}
                    </div>

                    <h2>New Messages</h2>
                    <div className='notify-new-messages'>
                        <TextField
                            required
                            type="text"
                            name="firstName"
                            value={state.firstName}
                            onChange={handleOnChange}
                            label='Title'
                            className='notify-default-text'
                            InputProps={{
                                inputProps: { autocomplete: "off", maxLength: 25, },
                                endAdornment: (<InputAdornment position="end" className='flex-end'> {state.firstName && <small>{state.firstName.length + '/'}25 </small>} </InputAdornment>),
                            }}
                        />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required
                                clearable
                                maxDate={new Date()}
                                format="MM/dd/yyyy"
                                id="date-picker-inline"
                                label='Publish Date'
                                className='notify-default-text'
                                value={state.dateOfBirth}
                                onChange={(date) => handleDateChange('dateOfBirth', date)}
                            />
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                required
                                clearable
                                maxDate={new Date()}
                                format="MM/dd/yyyy"
                                id="date-picker-inline"
                                label='Expiration Date'
                                className='notify-default-text'
                                value={state.dateOfBirth}
                                onChange={(date) => handleDateChange('dateOfBirth', date)}
                            />
                        </MuiPickersUtilsProvider>

                    </div>

                    <div style={{ display: 'grid', marginTop: '10px' }}>
                        <label>Message {state.userNote && <small>{state.userNote.length + '/'}1000 </small>} </label>
                        <textarea rows="5" cols="65" autocomplete="off" maxLength="1000" name="userNote" value={state.userNote} onChange={handleOnChange}></textarea>
                    </div>

                </DialogContent>

                <DialogActions>
                    <Button type="submit" variant="outline" color="primary" className='add-button'
                        disabled={!state.firstName.length || !state.lastName.length || !state.dateOfBirth || !state.gender || !state.client || state.addButtonLoading}> {state.addButtonLoading ? t('LABEL_WAIT') : 'Publish'}</Button>
                </DialogActions>

            </form>
        )}
        />
    </Dialog>
)