import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    IconButton,
    InputAdornment,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
} from "@material-ui/core"
import { ThemeRadio } from '../../constants/themeComponents'
import { Form } from 'react-final-form'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import './AddNewStudentDialog.scss'
import { hasNumber } from '../../constants/helpers.js'
import { css } from 'aphrodite-jss'
import { styles } from '../../assets/ContainerStyles.jsx'
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
    seed: 'App1',
});

export const AddNewStudentDialog = ({
    t,
    state,
    onSubmit,
    handleAddClose,
    handleOnChange,
    openAddStudentInfoDialog,
    handleAutoCompleateInputChange,
    handleDateChange,
    handleAutoCompleateChange
}) => (

    <Dialog disableEnforceFocus={true} open={openAddStudentInfoDialog} onClose={handleAddClose} aria-labelledby="form-dialog-title" >
        <Form onSubmit={onSubmit} render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate >
                <DialogTitle id="form-dialog-title" className='dialog-head'>
                    <div className='heading-tab'>
                        <span>{t('LABEL_ADD_NEW_STUDENT')}</span>
                        <IconButton edge="end" color="inherit" onClick={handleAddClose} aria-label="close">
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="dialog-content" style={{ overflowY: "unset" }}>
                    {state.userType && state.userType !== 'U' &&
                        < Autocomplete
                            value={state.client}
                            loadingText={t('LABEL_LOADING')}
                            className='autocompletes-type-wrapper'
                            loading={state.clientSuggestionsLoading}
                            renderOption={(option, { selected }) => (<> {option.name} </>)}
                            onInputChange={(event, value) => handleAutoCompleateInputChange(value)}
                            onChange={(event, value) => handleAutoCompleateChange('client', value)}
                            getOptionLabel={option => (option && option.name) ? option.name : ''}
                            options={state.clientSuggestionsLoading ? [] : state.clientsSuggestions}
                            noOptionsText={(state.clientKeyword.length > 0 && state.clientsSuggestions.length < 1) ? t('LABEL_NO_OPTIONS') : ''}
                            renderInput={params => <TextField autoFocus required {...params} label={t('LABEL_CLIENT')} fullWidth />}
                            inputProps={{ autocomplete: "off" }}

                        />
                    }
                    {state.userType && state.userType === 'U' && state.clientsSuggestions && state.clientsSuggestions.length !== 1 &&
                        <Autocomplete
                            value={state.client}
                            className='autocompletes-type-wrapper'
                            renderOption={(option, { selected }) => (<> {option.name} </>)}
                            onChange={(event, value) => handleAutoCompleateChange('client', value)}
                            getOptionLabel={option => (option && option.name) ? option.name : ''}
                            options={state.clientSuggestionsLoading ? [] : state.clientsSuggestions}
                            noOptionsText={(state.clientKeyword.length > 0 && state.clientsSuggestions.length < 1) ? t('LABEL_NO_OPTIONS') : ''}
                            renderInput={params => <TextField autoFocus required {...params} label={t('LABEL_CLIENT')} margin="normal" fullWidth />}
                            inputProps={{ autocomplete: "off" }}
                        />
                    }
                    {state.userType && state.userType === 'U' && state.clientsSuggestions && state.clientsSuggestions.length === 1 && state.client &&
                        <>
                            <label className='labelx'>{t('LABEL_CLIENT_REQ')}</label>
                            <p>{state.client.name}</p>
                        </>
                    }
                    <TextField
                        required
                        fullWidth
                        type="text"
                        name="firstName"
                        error={hasNumber(state.firstName)}
                        helperText={hasNumber(state.firstName) && 'First name should not include numeric values'}
                        value={state.firstName}
                        onChange={handleOnChange}
                        label={t('LABEL_FIRST_NAME')}
                        className='default-text-boxes-wrapper'
                        InputProps={{
                            inputProps: { autocomplete: "off", maxLength: 100, },
                            endAdornment: (<InputAdornment position="end" className='flex-end'> {state.firstName && <small>{state.firstName.length + '/'}100 </small>} </InputAdornment>),
                        }}
                    />
                    <TextField
                        fullWidth
                        type="text"
                        name="middleName"
                        error={hasNumber(state.middleName)}
                        helperText={hasNumber(state.middleName) && 'Middle name should not include numeric values'}
                        value={state.middleName}
                        onChange={handleOnChange}
                        label={t('LABEL_MIDDLE_NAME')}
                        className='default-text-boxes-wrapper'
                        InputProps={{
                            inputProps: { autocomplete: "off", maxLength: 100, },
                            endAdornment: (<InputAdornment position="end" className='flex-end'> {state.middleName && <small>{state.middleName.length + '/'}100 </small>} </InputAdornment>),
                        }}
                    />
                    <TextField
                        required
                        fullWidth
                        type="text"
                        name="lastName"
                        error={hasNumber(state.lastName)}
                        helperText={hasNumber(state.lastName) && 'Last name should not include numeric values'}
                        value={state.lastName}
                        onChange={handleOnChange}
                        label={t('LABEL_LAST_NAME')}
                        className='default-text-boxes-wrapper'
                        InputProps={{
                            inputProps: { autocomplete: "off", maxLength: 100, },
                            endAdornment: (<InputAdornment position="end" className='flex-end'> {state.lastName && <small>{state.lastName.length + '/'}100 </small>} </InputAdornment>),
                        }}
                    />
                    {/* <TextField
                            required
                            fullWidth
                            clearable
                            label={t('LABEL_STUDENT_DOB')}
                            format="M/dd/yyyy"
                            max={new Date()}
                            type="date"
                            name="dateOfBirth"
                            className='default-text-boxes-wrapper'
                            onChange={handleOnChange}
                            value={state.dateOfBirth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        /> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            required
                            fullWidth
                            clearable
                            maxDate={new Date()}
                            format="MM/dd/yyyy"
                            id="date-picker-inline"
                            label={t('LABEL_STUDENT_DOB')}
                            className='default-text-boxes-wrapper'
                            value={state.dateOfBirth}
                            onChange={(date) => handleDateChange('dateOfBirth', date)}
                            DialogProps={{ className: css(styles.dayClick) }}
                        />
                        {/* <DatePicker
                                required
                                maxDate={new Date()}
                                fullWidth
                                clearable
                                format="M/dd/yyyy"
                                label={t('LABEL_STUDENT_DOB')}
                                className='default-text-boxes-wrapper'
                                value={state.dateOfBirth && state.dateOfBirth}
                                onChange={(date) => handleDateChange('dateOfBirth', date)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <svg className='calander-icon' viewBox="0 0 50.11 50.09"><title>Calendar_new</title>
                                                    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M41.88,30.67h-7.6V23h7.6Z" /><path d="M8.23,30.66V23.05h7.63v7.61Z" /><path d="M29.34,30.66H21.75V23.05h7.59Z" /><path d="M8.23,42.23V34.6h7.61v7.63Z" /><path d="M21.73,34.59h7.62v7.64H21.73Z" /><path d="M34.25,34.59h7.62v7.64H34.25Z" /><rect x="7.75" width="3.81" height="11.56" rx="1.91" /><rect x="38.48" width="3.81" height="11.56" rx="1.91" /><path d="M45.49,7.69h-1.2c0,.82.06,1.58,0,2.32a3.79,3.79,0,0,1-5.21,3.2,3.7,3.7,0,0,1-2.43-3.42c0-.67,0-1.35,0-2.06H13.48c0,.71,0,1.39,0,2.06A3.7,3.7,0,0,1,11,13.21,3.8,3.8,0,0,1,5.82,10c-.06-.74,0-1.5,0-2.32H4.62C1.7,7.7,0,9.4,0,12.31V45.42c0,.21,0,.43,0,.64a4.18,4.18,0,0,0,4.16,4H45.93a4.18,4.18,0,0,0,4.17-4c0-.21,0-.43,0-.64V12.31C50.11,9.4,48.41,7.7,45.49,7.69Zm.77,37.59c0,1,0,1-1,1H4.85c-1,0-1,0-1-1q0-12.62,0-25.23c0-.66.17-.8.81-.8q10.2,0,20.41,0t20.42,0c.63,0,.81.14.81.8Q46.25,32.67,46.26,45.28Z" />
                                                    </g></g>
                                                </svg>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            /> */}
                    </MuiPickersUtilsProvider>
                    {/* <Autocomplete
                        options={state.genderTypes}
                        className='autocompletes-type-wrapper'
                        getOptionLabel={option => (option && option.label) ? option.label : ''}
                        renderOption={option => (<> {option.label} </>)}
                        onChange={(event, value) => handleAutoCompleateChange('gender', value)}
                        renderInput={params => <TextField required {...params} label={t('LABEL_GENDER')} fullWidth />}
                        value={state.gender}
                    /> */}
                    <StylesProvider generateClassName={generateClassName}>
                        <FormControl component="fieldset" className={css(styles.radioGroupWrapper)}>
                            <FormLabel component="legend">Gender *</FormLabel>
                            <RadioGroup required value={state.gender} onChange={(event, value) => handleAutoCompleateChange('gender', value)} row>
                                <FormControlLabel
                                    value="M"
                                    control={<ThemeRadio color="primary" />}
                                    label="Male"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="F"
                                    control={<ThemeRadio color="primary" />}
                                    label="Female"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="U"
                                    control={<ThemeRadio color="primary" />}
                                    label="Unspecified"
                                    labelPlacement="start"
                                />
                            </RadioGroup>
                        </FormControl>
                    </StylesProvider >
                    {/* <TextField
                        fullWidth
                        type="text"
                        name="studentId"
                        value={state.studentId}
                        onChange={handleOnChange}
                        label={t('LABEL_STUDENT_ID')}
                        className='default-text-boxes-wrapper'
                        InputProps={{
                            inputProps: { autocomplete: "off", maxLength: 15, },
                            endAdornment: (<InputAdornment position="end" className='flex-end'> {state.studentId && <small>{state.studentId.length + '/'}15 </small>} </InputAdornment>),
                        }}
                    /> */}
                    <NumberFormat
                        fullWidth
                        className='default-text-boxes-wrapper'
                        customInput={TextField}
                        value={state.studentId}
                        name="studentId"
                        label={t('LABEL_STUDENT_ID')}
                        onChange={handleOnChange}
                        InputProps={{
                            inputProps: { autocomplete: "off", maxLength: 15, },
                            endAdornment: (<InputAdornment position="end" className='flex-end'> {state.studentId && <small>{state.studentId.length + '/'}15 </small>} </InputAdornment>),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="outline" color="primary" className='add-button'
                        disabled={!state.firstName.length || hasNumber(state.firstName) || !state.lastName.length || hasNumber(state.lastName) || hasNumber(state.middleName) || !state.dateOfBirth || !state.gender || !state.client || state.addButtonLoading}> {state.addButtonLoading ? t('LABEL_WAIT') : t('LABEL_ADD')} </Button>
                </DialogActions>
            </form>
        )}
        />
    </Dialog>
)