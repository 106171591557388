import { Button, Radio } from '@material-ui/core'
import { withStyles } from '@material-ui/core';

export const ThemeButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: '#4278A4',
        '&:hover': {
            backgroundColor: '#325A7C',
        },
    },
}))(Button);

export const ThemeRadio = withStyles(theme => ({
    root: {
        '&$checked': {
            color: '#4278A4'
        }
    },
    checked: {}
}))(Radio);