import { StyleSheet } from 'aphrodite-jss'

// import variables from '../../global.scss'

export const styles = StyleSheet.create({   
  
  errors:{
    position: 'absolute',
    background: '#f3f3f3',
    textAlign: 'center',
    padding: '2%',
    borderRadius: '10px',
  },
    container: {
       display: 'flex',
       flexDirection: 'column',
       justifyContent: 'center',
       alignItems: 'center',
      //  marginTop: '2%'
     },
    avatar : {
        float: 'left',
        width: '52px',
        height: '52px',
        backgroundColor: '#f3f3f3',
        borderRadius: '25%',
        margin: '8px',
        backgroundImage: 'linear-gradient(90deg, #f3f3f3 0px, #e8e8e8 40px, #f3f3f3 80px)',
        backgroundSize: '600px',
        animation: 'shine-avatar 1.6s infinite linear',
        line : {
          marginTop: '5px',
          width: '100px',
        }
      },
      line : {
        float: 'left',
        width: '200px',
        height: '47px',
        marginTop: '5px',
        borderRadius: '7px',
        backgroundImage: 'linear-gradient(90deg, #f3f3f3 0px, #e8e8e8 40px, #f3f3f3 80px)',
        backgroundSize: '600px',
        animation: 'shine-lines 1.6s infinite linear',
        marginRight: '5px',
        '@media only screen and (min-width: 300px) and (max-width: 400px)': {
          width: '40px !important',
        },
        '@media only screen and (min-width: 400px) and (max-width: 500px)': {
          width: '50px !important',
        },
        '@media only screen and (min-width: 500px) and (max-width: 600px)': {
          width: '60px !important',
        },
        '@media only screen and (min-width: 600px) and (max-width: 700px)': {
          width: '70px !important',
        },
        '@media only screen and (min-width: 700px) and (max-width: 800px)': {
          width: '65px !important',
        },
        '@media only screen and (min-width: 800px) and (max-width: 900px)': {
          width: '70px !important',
        },
        '@media only screen and (min-width: 900px) and (max-width: 1000px)': {
          width: '80px !important',
        },
        '@media only screen and (min-width: 1000px) and (max-width: 1100px)': {
          width: '90px !important',
        },
        '@media only screen and (min-width: 1100px) and (max-width: 1200px)': {
          width: '100px !important',
        },
        '@media only screen and (min-width: 1200px) and (max-width: 1300px)': {
          width: '110px !important',
        },
        '@media only screen and (min-width: 1300px) and (max-width: 1400px)': {
          width: '110px !important',
        },
        '@media only screen and (min-width: 1400px) and (max-width: 1500px)': {
          width: '120px !important',
        },
        '@media only screen and (min-width: 1500px) and (max-width: 1600px)': {
          width: '140px !important',
        },
        '@media only screen and (min-width: 1600px) and (max-width: 1700px)': {
          width: '150px !important',
        },
        '@media only screen and (min-width: 1700px) and (max-width: 1800px)': {
          width: '160px !important',
        },
        '@media only screen and (min-width: 1800px) and (max-width: 1900px)': {
          width: '175px !important',
        },
        '@media only screen and (min-width: 1900px) and (max-width: 2000px)': {
          width: '190px !important',
        },

      },
      headerLine : {     
        float: 'left',
        width: '200px',
        height: '47px',
        marginTop: '30px',
        borderRadius: '7px',
        backgroundImage: 'linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)',
        backgroundSize: '600px',
        animation: 'shine-lines 1.6s infinite linear',
        marginRight: '5px',
        '@media only screen and (min-width: 300px) and (max-width: 400px)': {
          width: '40px !important',
        },
        '@media only screen and (min-width: 400px) and (max-width: 500px)': {
          width: '50px !important',
        },
        '@media only screen and (min-width: 500px) and (max-width: 600px)': {
          width: '60px !important',
        },
        '@media only screen and (min-width: 600px) and (max-width: 700px)': {
          width: '70px !important',
        },
        '@media only screen and (min-width: 700px) and (max-width: 800px)': {
          width: '65px !important',
        },
        '@media only screen and (min-width: 800px) and (max-width: 900px)': {
          width: '70px !important',
        },
        '@media only screen and (min-width: 900px) and (max-width: 1000px)': {
          width: '80px !important',
        },
        '@media only screen and (min-width: 1000px) and (max-width: 1100px)': {
          width: '90px !important',
        },
        '@media only screen and (min-width: 1100px) and (max-width: 1200px)': {
          width: '100px !important',
        },
        '@media only screen and (min-width: 1200px) and (max-width: 1300px)': {
          width: '110px !important',
        },
        '@media only screen and (min-width: 1300px) and (max-width: 1400px)': {
          width: '110px !important',
        },
        '@media only screen and (min-width: 1400px) and (max-width: 1500px)': {
          width: '120px !important',
        },
        '@media only screen and (min-width: 1500px) and (max-width: 1600px)': {
          width: '140px !important',
        },
        '@media only screen and (min-width: 1600px) and (max-width: 1700px)': {
          width: '150px !important',
        },
        '@media only screen and (min-width: 1700px) and (max-width: 1800px)': {
          width: '160px !important',
        },
        '@media only screen and (min-width: 1800px) and (max-width: 1900px)': {
          width: '175px !important',
        },
        '@media only screen and (min-width: 1900px) and (max-width: 2000px)': {
          width: '190px !important',
        },
    },
     
     '@keyframes shine-lines': {
       '0%': {
         backgroundPosition: '-100px',
       },
       '40%, 100%': {
         backgroundPosition: '140px',
       }
     },
   
     '@keyframes shine-avatar': {
       '0%': {
         backgroundPosition: '-32px',
       },
       '40%, 100%': {
         backgroundPosition: '208px',
       }
     }
})