import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { withRouter } from "react-router"
import { Button, TextField } from "@material-ui/core"
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { withNamespaces } from 'react-i18next'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'
import BackendClient from "../../services/backendClient"
import { login, loginFormUpdate, sendLoginClearForm, createNewPasswordAction, logoutUser } from '../../actions/sessionActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"
import LatestUpdatesContainer from '../../components/LatestUpdates/LatestUpdatesContainer'
import { EMAIL_REGEX, responseCodeSuccess, responseCodeUnauthorized, responseCodeBadRequest } from '../../constants/authConstants'
import { baseContentUrl, googleSiteKey } from '../../utils/config'
import './login.scss'
import { LiveChatWidget } from "@livechat/widget-react";
import { LocalStorageService } from '../../services/localStorageService'
import { hasNumber, hasLowerCase, hasUpperCase } from '../../constants/helpers'

class Login extends Component {

	constructor(props, context) {
		super(props, context)
		this.state = {
			isReset: false,
			isChrome: navigator.userAgent.indexOf("Chrome") !== -1,
			pass: "",
			email: "",
			overlay: true,
			showPassword: false,
			showTempPassword: false,
			showRtTempPassword: false,
			prevPath: localStorage.getItem('prevPath') ? localStorage.getItem('prevPath') : null,
			errorFields: {
				email: false,
				pass: false,
			},
			touched: {
				email: false,
				pass: false
			},
			expired: false,
			recaptchaToken: "",
			errorsInTheForm: false,
			loggedInRealStatus: false,
			sholdShowTheRecpatchaForm: false,
			emailRule: new RegExp(EMAIL_REGEX),
			tempPassword: "",
			rtTempPassword: "",
			errorsInTheForm2: true,
			activeErrors: '',
			loading2: false
		}
		this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this)
		this.verifyCallback = this.verifyCallback.bind(this)
	}

	componentDidMount() {
		localStorage.removeItem('mouseLastMovedTime')
		loadReCaptcha()
		if (this.captchaDemo) {
			this.captchaDemo.reset()
			this.captchaDemo.execute()
		}
	}

	onLoadRecaptcha() {
		if (this.captchaDemo) {
			this.captchaDemo.reset()
			this.captchaDemo.execute()
		}
	}

	verifyCallback(recaptchaToken) {
		this.setState({ recaptchaToken })
	}

	handleClickShowPassword = _ => this.setState({ showPassword: !this.state.showPassword })

	handleMouseDownPassword = event => event.preventDefault()

	handleBlur = field => _ => this.setState({ touched: { ...this.state.touched, [field]: true, errorsInTheForm: false } },
		() => {
			setTimeout(() => this.setState({
				touched: {
					email: false,
					pass: false
				},
			}), 4000)
		})

	updateFormHandler = field => event => {
		switch (field) {
			case "username":
				this.setState({ email: event.target.value, errorsInTheForm: (!this.state.emailRule.test(event.target.value)) ? true : false })
				break
			case "password":
				this.setState({ pass: event.target.value })
				break
			default:
				return true
		}
		this.props.loginFormUpdate(field, event.target.value)
	}

	sendInfo = _ => {
		let params = {};
		let linkHash = null;
		let scriptHash = null;
		let links = document.getElementsByTagName("link");
		Array.prototype.slice.call(links).map((link) => {
			if (link && link.href) {
				let linkHref = link.href.split('.');
				if (linkHref[2] === "net/static/css/main" || linkHref[2] === "com/static/css/main") linkHash = linkHref[3];
			}
		});

		let scripts = document.getElementsByTagName("script");
		Array.prototype.slice.call(scripts).map((script) => {
			if (script && script.src) {
				let scriptSrc = script.src.split('.');
				if (scriptSrc[2] === "net/static/js/main" || scriptSrc[2] === "com/static/js/main") scriptHash = scriptSrc[3];
			}
		});
		if (scriptHash) {
			params.module = "CN";
			params.mainCssHash = linkHash
			params.mainJsHash = scriptHash
			BackendClient.post(`/as-api/portal-info/frontend-asset-versions`, params);
		}
	}

	formSubmitHandler = (event) => {
		event.preventDefault();
		localStorage.removeItem('newPassText')
		const { t } = this.props
		const { recaptchaToken, sholdShowTheRecpatchaForm } = this.state
		if ((this.state.pass) && (this.state.email)) {
			this.props.login(sholdShowTheRecpatchaForm && recaptchaToken).then(data => {
				if (data.status === responseCodeSuccess) {
					// this.props.showNotification(t('LABEL_LOGIN_SUCCESS'), 'success')					
					localStorage.removeItem('frontEndInfoDashboard');
					localStorage.removeItem('frontEndInfoAdmin');
					localStorage.removeItem('frontEndInfoFileprocessor');
					localStorage.removeItem('frontEndInfoOpticlaim');
					localStorage.removeItem('frontEndInfoOptilearn');
					localStorage.removeItem('frontEndInfoOptireports');
					this.sendInfo();
					if (data.data.changePwd) {
						this.props.history.push("/temporary-password")
						localStorage.setItem('tempPasswordRedirect', true)
					}
				} else {
					if (data.data && data.data.validationFailures.length) {

						if (data.data.validationFailures[0].code === 'mbtp.auth.user.noActiveClients') {
							this.props.showNotification(t('LABEL_NO_ACTIVE_CLIENTS'), 'info')
						}

						if ((data.data.validationFailures[0].code === 'mbtp.auth.credentials.invalidAndCaptchaRequired') ||
							(data.data.validationFailures[0].code === 'mbtp.auth.captcha.empty') ||
							(data.data.validationFailures[0].code === 'mbtp.auth.captcha.failed')) {
							this.props.showNotification(t('LABEL_LOGIN_CAPTHA_REQUIRED'), 'info')
							this.setState({ sholdShowTheRecpatchaForm: true })
							if (this.captchaDemo) this.captchaDemo.reset();
						}

						if (data.status === responseCodeBadRequest) {
							this.props.showNotification(t('LABEL_LOGIN_FAILED'), 'error')
							if (this.captchaDemo) this.captchaDemo.reset();
						}

						this.setState({ pass: "" });
					}
				}
				setTimeout(_ => this.props.hideNotification(), 2500)
			})
		} else {
			if ((!this.state.email) || (!this.state.emailRule.test(this.state.email)))
				this.setState(prevState => ({ touched: { ...prevState.touched, email: true } }))
			// this.setState({ touched: { ...this.state.touched, ['email']: true } })

			if (!this.state.pass)
				this.setState(prevState => ({ touched: { ...prevState.touched, pass: true } }))
			// this.setState({ touched: { ...this.state.touched, ['pass']: true } })
		}
	}

	routeToReset = () => this.setState({ isReset: !this.state.isReset })

	handleInput = (e) => {
		this.setState({ [e.target.name]: e.target.value })

		setTimeout(() => {
			var errors = "";
			var errorsInForm = true;

			if (!this.state.tempPassword) {
				errors = 'New password is required';
			} else if (!this.state.rtTempPassword) {
				errors = 'Confirm Password is required';
			} else if (this.state.tempPassword.length < 6) {
				errors = 'Password minimum requirements should be 6 characters';
			} else if (!hasNumber(this.state.tempPassword)) {
				errors = 'Password should contain at least 1 number';
			} else if (!hasLowerCase(this.state.tempPassword)) {
				errors = 'Password should contain at least 1 lowercase letter';
			} else if (!hasUpperCase(this.state.tempPassword)) {
				errors = 'Password should contain at least 1 uppercase letter';
			} else if (this.state.tempPassword !== this.state.rtTempPassword) {
				errors = 'Password mismatch';
			} else {
				errorsInForm = false;
			}

			this.setState({ activeErrors: errors, errorsInTheForm2: errorsInForm })
		}, 500)
	}

	createNewPassword = (event) => {
		event.preventDefault();
		this.setState({ loading2: true });
		let params = {};
		params.password = this.state.tempPassword;
		params.confirmedPassword = this.state.rtTempPassword;
		params.userId = this.props.userId
		this.props.createNewPasswordAction(params).then(response => {
			if (response.statusCode === 200) {
				localStorage.setItem("newPassText", true)
				this.logout();
			} else if (response.statusCode === 400 && response.validationFailures &&
				response.validationFailures[0] && response.validationFailures[0].code === "mbtp.auth.accessToken.expired") {
				this.logout();
			}
			this.setState({ loading2: false });
		});
	}

	logout = _ => {
		this.props.logoutUser()
		LocalStorageService.clearLocalStorage()
		window.location.href = '/'
	}

	render() {

		const {
			t,
			loading,
			// classes,
			username,
			password,
			isLoggedIn,
			changePwd,
		} = this.props

		const {
			touched,
			errorFields,
			showPassword,
			showTempPassword,
			showRtTempPassword,
			errorsInTheForm,
			sholdShowTheRecpatchaForm,
			prevPath,
			tempPassword,
			rtTempPassword,
			errorsInTheForm2,
			activeErrors,
			loading2,
			pass
		} = this.state

		if (isLoggedIn && !changePwd) {
			if (prevPath) {
				localStorage.removeItem('prevPath')
				window.location.href = prevPath
				return true
			}

			return <Redirect to={"/dashboard"} />

		} else if (changePwd && this.props.location.pathname !== "/temporary-password" && localStorage.getItem("tempPasswordRedirect")) {
			this.logout();
		}

		if (this.state.isReset) { return <Redirect to={"/forgot-password"} /> }

		return (
			<>
				<div className="login__page-container">
					<div className="login__page-left-bgimage">
						<LatestUpdatesContainer t={t} />
					</div>
					<div className="login__page-right-bgimage">
						<div className={`${sholdShowTheRecpatchaForm ? 'login__content-wrapper-overrided' : ''} login__content-wrapper`}>

							{!changePwd && <form onSubmit={this.formSubmitHandler} noValidate>
								<img src={`${baseContentUrl}/images/MBT-Logo.login.png`} alt="logo" />
								{!this.state.isChrome && <p className='caution'>For the best performance please use the Google Chrome web browser when accessing MBT's web portal.</p>}
								{localStorage.getItem("newPassText") && <p>Login with you new password!</p>}
								<TextField
									fullWidth
									autoFocus
									type="email"
									name="email"
									margin="normal"
									disabled={loading}
									className='default-input'
									onBlur={this.handleBlur('username')}
									inputProps={{ autoComplete: "on" }}
									label={t('LABEL_EMAIL_ADDRESS')}
									onChange={this.updateFormHandler('username')}
								/>
								{((touched.username && !username) || errorFields.username || errorsInTheForm) && <span className="error">{t('LABEL_EMAIL_ADDRESS_WARNING')}</span>}
								<TextField
									fullWidth
									margin="normal"
									name="password"
									disabled={loading}
									label={t('LABEL_PASSWORD')}
									className='default-input'
									onBlur={this.handleBlur('password')}
									onChange={this.updateFormHandler('password')}
									type={showPassword ? 'text' : 'password'}
									value={pass}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													edge="end"
													onClick={this.handleClickShowPassword}
													aria-label="toggle password visibility"
													onMouseDown={this.handleMouseDownPassword}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								{((touched.password && !password) || errorFields.password) && <span className="error">{t('LABEL_PASSWORD_WARNING')}</span>}
								{sholdShowTheRecpatchaForm &&
									<ReCaptcha
										ref={(el) => { this.captchaDemo = el }}
										size="normal"
										render="explicit"
										sitekey={googleSiteKey}
										onloadCallback={this.onLoadRecaptcha}
										verifyCallback={this.verifyCallback}
									/>}
								<div className="login__btn-container">
									<Button type="submit" size="large" className="defaultActionBtn min__width-overrided" disabled={loading || errorsInTheForm || !pass || !password || !username} variant="contained" onClick={this.formSubmitHandler}>{loading ? t('LABEL_PLEASE_WAIT') : t('LABEL_LOGIN')}</Button>
									<Button size="large" disabled={loading} onClick={this.routeToReset}>{t('BUTTON_FORGET_PASSWORD')}</Button>
								</div>
							</form>}

							{changePwd && <form onSubmit={this.createNewPassword} noValidate>
								<img src={`${baseContentUrl}/images/MBT-Logo.login.png`} alt="logo" />
								<p>Please create new password</p>
								<TextField
									fullWidth
									margin="normal"
									name="tempPassword"
									disabled={loading}
									label="New Password"
									className='default-input'
									onChange={this.handleInput}
									type={showTempPassword ? 'text' : 'password'}
									value={tempPassword}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													edge="end"
													onClick={() => this.setState({ showTempPassword: !showTempPassword })}
													aria-label="toggle password visibility"
													onMouseDown={this.handleMouseDownPassword}
												>
													{showTempPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<TextField
									fullWidth
									margin="normal"
									name="rtTempPassword"
									disabled={loading}
									label="Confirm Password"
									className='default-input'
									onChange={this.handleInput}
									type={showRtTempPassword ? 'text' : 'password'}
									value={rtTempPassword}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													edge="end"
													onClick={() => this.setState({ showRtTempPassword: !showRtTempPassword })}
													aria-label="toggle password visibility"
													onMouseDown={this.handleMouseDownPassword}
												>
													{showRtTempPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								{(errorsInTheForm2 && activeErrors) && <span className="error">{activeErrors}</span>}
								<div className="login__btn-container">
									<Button type="submit" size="large" className="defaultActionBtn min__width-overrided"
										disabled={loading2 || !tempPassword || !rtTempPassword || (tempPassword !== rtTempPassword) || (errorsInTheForm2 && activeErrors)}
										variant="contained">
										{loading2 ? t('LABEL_PLEASE_WAIT') : "Save Password"}
									</Button>
									{/* <Button size="large" className="defaultActionBtn min__width-overrided" onClick={this.logout} variant="contained">
										Login
									</Button> */}
								</div>
							</form>}
						</div>
						<div className="mobile__view-only">
							<LatestUpdatesContainer t={t} />
						</div>
					</div>
				</div>
				<LiveChatWidget
					license={"3514881"}
					customerName={"Visitor"}
					customerEmail={" "}
				/>
			</>
		)
	}
}

const mapStateToProps = ({ session }) => ({
	loading: session.loading,
	username: session.username,
	password: session.password,
	isLoggedIn: session.isLoggedIn,
	changePwd: session.changePwd,
	userId: session.userId
})

const mapDispatchToProps = dispatch => ({
	hideNotification: _ => dispatch(hideNotification()),
	login: captchaValue => dispatch(login(captchaValue)),
	createNewPasswordAction: params => dispatch(createNewPasswordAction(params)),
	sendLoginClearForm: _ => dispatch(sendLoginClearForm()),
	loginFormUpdate: (username, password) => dispatch(loginFormUpdate(username, password)),
	showNotification: (message, messageType) => dispatch(showNotification(message, messageType)),
	logoutUser: _ => dispatch(logoutUser())
})

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(Login))
