export const mediaTypes = {
    image: 'image',
    video: 'video',
    audio: 'audio'
}

export const sortTypes = {
    newest: 'created desc',
    oldest: 'created asc',
    name_ascending:'name asc',
    name_descending: 'name desc',
    highest_view: 'hits desc',
    lowest_view: 'hits asc'   
}

export const mediaViews = {
    list: 'list',
    grid: 'grid'
}

export const messageTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning',
}

export const tableSortTypes = {
    ASC: 'asc',
    DESC: 'desc'
}

export const customScreenSizes = {
    mobile: '480',
    small: '1280',
    medium: '1440'
}

export const customScreenType = {
    mobile: 'mobile',
    small: 'small',
    medium: 'medium',
    large: 'large'
}

export const paginationDataLimitPerScreens = {
    default_screens: 50,
    large_screens: 6,
    medium_screens: 5,
    small_screens: 4,
    mobile_screens: 6
}

export const mediaPerPageOptions = [50, 100, 200]

export const mediaPerPageOptionsForSmallScreens = [6, 10, 15]
