import React, { Component } from 'react'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import WarningIcon from '@material-ui/icons/Warning';

import { errors } from '../constants/errorCodes'
// import { authTokenValidation } from "./authService"
import { toast } from 'react-toastify';

class errorResponse extends Component {

    handle = (error = null) => {
        if (error && error.response && error.response.data) {

            const errorResponse = error.response.data

            if (!errorResponse.validationFailures) {

                const ServerError = () => (
                    <div style={{ color: '#000', display: 'flex', marginLeft: '10px' }}>
                        <ErrorOutlineOutlinedIcon />
                        <div>
                            <div style={{ fontWeight: 800, margin: '2px 0 0 10px' }}> Error </div>
                            <div style={{ margin: '2px 0 0 10px' }}> Internal Server Error </div>
                        </div>
                    </div>
                )

                toast.error(<ServerError />)
            } else {
                errorResponse.validationFailures.map(error => {
                    if (error.code === "mbtp.auth.accessToken.expired") {
                        // authTokenValidation();
                    } else {
                        this.alertErrorMessageToUser(error.code)
                    }
                    return true;
                })
            }
        }
    }


    alertErrorMessageToUser(errorCode) {

        const CodeError = () => (
            <div style={{ color: '#000', display: 'flex', marginLeft: '10px' }}>
                <WarningIcon />
                <div>
                    <div style={{ fontWeight: 800, margin: '2px 0 0 10px' }}> Something went wrong. </div>
                    <div style={{ margin: '2px 0 0 10px' }}> <small style={{ overflowWrap: 'anywhere' }}>{errorCode}</small> </div>
                </div>
            </div>
        )

        toast.warning(CodeError, { autoClose: 5000 })
    }

    // This function can be used to show error message related to backend error code
    // using the front end property JSON File
    alertPropertyErrorMessageToUser(errorCode) {
        var toasted = false;
        for (let index = 0; index < errors.length; index++) {
            const error = errors[index];
            if (error.code === errorCode) {

                const Error = () => (
                    <div style={{ color: '#000', display: 'flex', marginLeft: '10px' }}>
                        <WarningIcon />
                        <div>
                            <div style={{ fontWeight: 800, margin: '2px 0 0 10px' }}> {error.title} </div>
                            <div style={{ margin: '2px 0 0 10px' }}> {error.message} </div>
                        </div>
                    </div>
                )

                toast.warning(<Error />, { autoClose: 5000 });
                toasted = true;
            }
        }

        if (!toasted) {

            const CodeError = () => (
                <div style={{ color: '#000', display: 'flex', marginLeft: '10px' }}>
                    <WarningIcon />
                    <div>
                        <div style={{ fontWeight: 800, margin: '2px 0 0 10px' }}> Something went wrong. </div>
                        <div style={{ margin: '2px 0 0 10px' }}> <small style={{ overflowWrap: 'anywhere' }}>{errorCode}</small> </div>
                    </div>
                </div>
            )
            console.log(errorCode);
            toast.warning(CodeError, { autoClose: 5000 })

        }
    }

}

export default new errorResponse()

