import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { I18nextProvider } from "react-i18next"

import translationEN from './assets/locale/en/translation.json'

const resources = {
  en: {
    translation: translationEN
  }
}

i18n
  .use(detector)
  .use(I18nextProvider)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false 
    }
})

export default i18n