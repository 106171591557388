import * as actions from "../actions/sessionActions"

const _INIT_ACTION_TYPE = "@@INIT"

const initialState = {
    users: [],
    scope: "",
    expire: 0,
    userId: "",
    username: "",
    userType: "",
    password: "",
    clientId: "",
    loading: false,
    newPassword: "",
    permissions: [],
    accessToken: "",
    refreshToken: "",
    isLoggedIn: false,
    changePwd: false,
    confirmPassword: "",
    userSessionTimePassed: 0,
    currentLinkPermissions: [],
    checkUserSessionStarted: false
}

export const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case _INIT_ACTION_TYPE:
        case actions.SESSION_INITIALISE:
            return {
                ...state,
                isLoggedIn: localStorage.getItem('accessToken') ? true : false,
                username: action.username || localStorage.getItem('userName'),
                userId: action.userId || localStorage.getItem('userId'),
                userType: action.userType || localStorage.getItem('userType'),
                expire: action.expire || localStorage.getItem('expire'),
                clientId: action.clientId || localStorage.getItem('clientId'),
                accessToken: action.accessToken || localStorage.getItem('accessToken'),
                refreshToken: action.refreshToken || localStorage.getItem('refreshToken'),
                permissions: action.permissions || localStorage.getItem('permissions'),
                scope: action.scope || localStorage.getItem('scope'),
                changePwd: localStorage.getItem('changePwd') === "true" ? true : false
            }
        case actions.UPDATE_CHECK_USER_SESSION:
            return {
                ...state,
                checkUserSessionStarted: true
            }
        case actions.UPDATE_USER_SESSION_TIME_PASSED:
            return {
                ...state,
                userSessionTimePassed: action.userSessionTimePassed
            }
        case actions.LOGIN_FORM_UPDATE:
            return {
                ...state,
                [action.field]: action.value
            }
        case actions.SEND_LOGIN_FORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_LOGIN_FORM_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_LOGIN_FORM_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_LOGIN_CLEAR_FORM:
            return {
                ...state,
                username: "",
                password: ""
            }
        case actions.SEND_PASSWORD_RESET_FORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_PASSWORD_RESET_FORM_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_PASSWORD_RESET_FORM_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.LOGIN_PROCESS_SUCCESS:
            return {
                ...state
            }
        case actions.SESSION_END:
            return initialState
        default:
            return state
    }
}
