import React from 'react'

import { DashboardIcon } from '../../assets/images/icons/dashboard'
import { OpticlaimIcon } from '../../assets/images/icons/opticlaim'
import { ServiceEntryIcon } from '../../assets/images/icons/service-entry'
import { CaseLoadIcon } from '../../assets/images/icons/caseload'
import { ApprovalListIcon } from '../../assets/images/icons/approve-list'
import { ServieListIcon } from '../../assets/images/icons/service-list'
import { BatchedServieListIcon } from '../../assets/images/icons/batched-service-list'
import { InvoiceIcon } from '../../assets/images/icons/invocie'
import { AdministrationIcon } from '../../assets/images/icons/administration'
import { StudentsAllIcon } from '../../assets/images/icons/students-all'
// import { ClientsIcon } from '../../assets/images/icons/clients'
// import { UsersIcon } from '../../assets/images/icons/users'
// import { StudentsIcon } from '../../assets/images/icons/students'
// import { EmployeesIcon } from '../../assets/images/icons/employees'
// import { CodesIcon } from '../../assets/images/icons/codes'
import { OptireportsIcon } from '../../assets/images/icons/optireports'
// import { FileprocessorIcon } from '../../assets/images/icons/fileprocessor'
import { OptilearnIcon } from '../../assets/images/icons/optilearn'
import { authorities } from '../../constants/permissionsEnums'
import { PermCalender } from '../../assets/images/icons/permCalender'


// import { permissionsKeyTypes } from '../../constants/permissions'

export const getItems = _ => {

    const allowedModules = localStorage.getItem('allowedModules')
    const permissions = localStorage.getItem('permissions')


    var list = [
        {
            id: 1,
            items: [
                {
                    id: 1,
                    name: "Dashboard",
                    url: "dashboard",
                    subURL: [],
                    icon: <DashboardIcon />,
                }
            ]
        },
    ];

    if (allowedModules && allowedModules.length && allowedModules.includes("AD")) {
        list.push(
            {
                id: 2,
                items: [
                    {
                        id: 1,
                        name: "Administration",
                        url: "administration/general",
                        subURL: ["administration/general", "administration/clients", "administration/users", "administration/students", "administration/mbt_agents"],
                        icon: <AdministrationIcon />
                    }
                ]
            }
        )
    }

    if (allowedModules && allowedModules.length && allowedModules.includes("OC")) {
        var ocList = []



        if (permissions && permissions.length && authorities && permissions.includes(authorities.SERVICE_ENTRY_SUBMIT)) {
            ocList.push(
                {
                    id: 1,
                    name: "Service Entry",
                    url: "opticlaim/service-entry",
                    subURL: [],
                    icon: <ServiceEntryIcon />
                }
            )
        }

        if (permissions && permissions.length && authorities && permissions.includes(authorities.CASELOAD_SEARCH)) {
            ocList.push(
                {
                    id: 2,
                    name: "Caseload",
                    url: "opticlaim/caseload/" + Math.floor(Math.random() * Math.floor(100)),
                    subURL: [],
                    icon: <CaseLoadIcon />
                }
            )
        }

        if (permissions && permissions.length && authorities && permissions.includes(authorities.SERVICE_LIST_SEARCH)) {
            ocList.push(
                {
                    id: 3,
                    name: "Service List",
                    url: "opticlaim/service-list",
                    subURL: [],
                    icon: <ServieListIcon />
                },
            )
        }

        if (permissions && permissions.length && authorities && permissions.includes(authorities.SEARCH_PREVIOUS_SUBMISSIONS)) {
            ocList.push(

                {
                    id: 4,
                    name: "Submissions",
                    url: "opticlaim/submissions",
                    subURL: [],
                    icon: <ApprovalListIcon />
                },
            )
        }

        if (permissions && permissions.length && authorities && permissions.includes(authorities.SEARCH_VIEW_PAYMENTS)) {
            ocList.push(
                {
                    id: 6,
                    name: "Payments",
                    url: "opticlaim/payments",
                    subURL: [],
                    icon: <InvoiceIcon />
                }
            )
        }

        list.push(
            {
                id: 3,
                items: [
                    {
                        id: 1,
                        name: "Opticlaim",
                        icon: <OpticlaimIcon />,
                        subitems: ocList
                    }
                ]
            }
        )
    }

    if (allowedModules && allowedModules.length && allowedModules.includes("OR")) {
        // var orList = []

        // orList.push(
        //     {
        //         id: 1,
        //         name: "Service Report",
        //         url: "optireports/service-report",
        //         subURL: [],
        //         icon: <ServiceEntryIcon />
        //     }
        // )

        // orList.push(
        //     {
        //         id: 1,
        //         name: "Claims Detail Report",
        //         url: "optireports/claim-detail-report",
        //         subURL: [],
        //         icon: <ServiceEntryIcon />
        //     }
        // )

        list.push(
            {
                id: 4,
                items: [
                    {
                        id: 1,
                        name: "OptiReports",
                        url: "optireports",
                        subURL: [],
                        icon: <OptireportsIcon />
                    }
                ]
            }
        )
    }

    if (allowedModules && allowedModules.length && allowedModules.includes("FP")) {

        let fpList = [];

        if (permissions && permissions.length && authorities && permissions.includes(authorities.FILE_PROCESSOR_ENROLLMENT_SEARCH_AND_VIEW)) {
            fpList.push(
                {
                    id: 1,
                    name: "Enrollment",
                    url: "fileprocessor/enrollment",
                    subURL: [],
                    icon: <StudentsAllIcon />
                }
            )
        }

        if (permissions && permissions.length && authorities && permissions.includes(authorities.FILE_PROCESSOR_LEA_BILLING_SEARCH_AND_VIEW)) {
            fpList.push(
                {
                    id: 2,
                    name: "LEA Billing",
                    url: "fileprocessor/lea-billing",
                    subURL: [],
                    icon: <CaseLoadIcon />
                },
            )
        }
        if (permissions && permissions.length && authorities && permissions.includes(authorities.FILE_PROCESSOR_SEARCH_TSP_LIST)) {
            fpList.push(
                {
                    id: 2,
                    name: "TSP Lists",
                    url: "fileprocessor/tsp-list",
                    subURL: [],
                    icon: <PermCalender />
                },
            )
        }
        if (permissions && permissions.length && authorities &&
            (permissions.includes(authorities.FILE_PROCESSOR_ENROLLMENT_SEARCH_AND_VIEW) ||
                permissions.includes(authorities.FILE_PROCESSOR_LEA_BILLING_SEARCH_AND_VIEW) || 
                permissions.includes(authorities.FILE_PROCESSOR_SEARCH_TSP_LIST))) {

            list.push(
                {
                    id: 5,
                    items: [
                        {
                            id: 1,
                            name: "FileProcessor",
                            icon: <BatchedServieListIcon />,
                            url: "fileprocessor/recent-original-files",
                            subURL: [],
                            subitems: fpList

                            // {
                            //     id: 1,
                            //     name: "General",
                            //     url: "fileprocessor/general",
                            //     subURL: [],
                            //     icon: <ServiceEntryIcon />
                            // },
                            // {
                            //     id: 2,
                            //     name: "LEA Billing",
                            //     url: "fileprocessor/lea-billing",
                            //     subURL: [],
                            //     icon: <CaseLoadIcon />
                            // },

                            // {
                            //     id: 4,
                            //     name: "IEP",
                            //     url: "fileprocessor/iep",
                            //     subURL: [],
                            //     icon: <ServieListIcon />
                            // },
                            // {
                            //     id: 5,
                            //     name: "Transportation",
                            //     url: "fileprocessor/transportation",
                            //     subURL: [],
                            //     icon: <BatchedServieListIcon />
                            // }

                        }
                    ]
                }
            )
        }
    }

    if (allowedModules && allowedModules.length && allowedModules.includes("OL")) {
        list.push(
            {
                id: 6,
                items: [
                    {
                        id: 1,
                        name: "OptiLearn",
                        url: "optilearn",
                        subURL: [],
                        icon: <OptilearnIcon />
                    }
                ]
            }
        )
    }

    const navigationList = { list: list }

    return navigationList
}