import React, { Fragment } from 'react'
import { connect } from "react-redux";
import { Component } from "react";
import { loadMERClientSuggesions, loadMERData, loadMERYearSuggesions } from '../../actions/generalActions';
import { merClientsSuggetions, merData, merFileDownload } from '../../services/containerService';
import { MERCalculatorDialog } from './MERCalculatorDialog';
import DownloadMERDocumentCellRenderer from '../StudentInformationDialog/CellRenderers/DownloadMERDocumentCellRenderer';

class MERCalculatorDialogContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientsSuggestions: [],
            clientSuggestionsLoading: false,
            client: null,
            clientKeyword: '',

            merYearandMonthData: [],
            merYearSuggestions: [],
            yearSugestionsLoading: false,
            selectedYear: null,

            merMonthSuggestions: [],
            selectedMonth: null,

            merData: [],
            merDataLoading: false,
            noMERData: false,

            MERTableColumns: [
                {
                    name: "yearMonth",
                    label: "Month",
                    type: "MER"
                },
                {
                    name: "medicalEligibleStudentCount",
                    label: "Total MC Students",
                    type: "MER"
                },
                {
                    name: "totalEnrollment",
                    label: "Total Enrollment",
                     type: "MER"
                },
                {
                    name: "merWithPercentage",
                    label: "MER %",
                     type: "MER"
                },
                {
                    name: "action",
                    label: "Action",
                    width: 100,
                    cellRenderer: DownloadMERDocumentCellRenderer,
                    type: "CellRenderer"
                }
            ]
        }

    }

    componentDidMount() {
        this.props.fetchMERClients().then(_ => this.setClients());
        this.props.fetchMERYears().then(_ => this.setYears());
    }

    setClients() {
        if (this.props.merClientSuggestions && this.props.merClientSuggestions.content) {
            this.setState({ clientsSuggestions: this.props.merClientSuggestions.content, clientSuggestionsLoading: this.props.merClientSuggestionsLoading })
        }
    }

    setYears() {
        if (this.props.merYearSuggestions && this.props.merYearSuggestions.content) {
            this.setState({ merYearSuggestions: this.props.merYearSuggestions.content.merYears, yearSugestionsLoading: this.props.merYearSuggestionsLoading, merYearandMonthData: this.props.merYearSuggestions.content })
            this.setDefaultYear();
        }
    }

    setDefaultYear (){
        if(this.props.merYearSuggestions.content.merYears.some(x => x.default)){
            const selectedYear = this.props.merYearSuggestions.content.merYears.find(x => x.default);
            const monthsForSelectedyear = this.props.merYearSuggestions.content.merYearMap[selectedYear.value];
            this.setDefaultMonth(monthsForSelectedyear);
            this.setState({ selectedYear, merMonthSuggestions: monthsForSelectedyear });
        }else {
            this.setState({ selectedYear: null });
        }
    }

    setDefaultMonth = (months) => {
        const defaultMonth = months.find(x => x.default);
        if(defaultMonth != null){
            this.setState({ selectedMonth: defaultMonth });
        }else {
            this.setState({ selectedMonth: null });
        }
    }

    handleAutoCompleateChange = (name, value) => {
        const dataToSet = { [name]: value }
        this.setState(dataToSet)
    }

    handleYearDropdownChange = (value) => {
        this.setState({ selectedYear: value, merMonthSuggestions: this.state.merYearandMonthData.merYearMap[value.value] })
    }

    handleOnSearch = () => {
        const requestBody = {
            clientId: this.state.client.id,
            year: this.state.selectedYear.value,
            month: this.state.selectedMonth.value
        }
        this.setState({merDataLoading: true });
        this.props.fetchMERData(requestBody).then((data) => {this.setState({merData: data.content ? [{...data.content}]: [], merDataLoading: false, noMERData: [{...data.content}].length < 1 }); })
    }

    handleDownloadClick = (record) => {
        const requestBody = {
            clientId: this.state.client.id,
            year: this.state.selectedYear.value,
            month: this.state.selectedMonth.value
        }
        const updatedRecord = {...record, downloadInProgress: true};
        this.setState({ merData: [...this.state.merData.filter(x => x != record), updatedRecord] }); 
        merFileDownload(requestBody, record.excelFileName).then(() => {
            this.setState({ merData: [...this.state.merData.filter(x => x != updatedRecord), {...record, downloadInProgress: false}] }); 
        });
        
    }

    handleAutoCompleateInputChange = (value) => {
    }

    onDialogClose = () => {
        this.setState({merData: [], client: null});
        this.props.handleDialogClose();
        this.setDefaultYear();
    }

    render() {
        const { t, state } = this.props

        return (
            <Fragment>
                <MERCalculatorDialog
                    props={this.props}
                    state={this.state}
                    handleInfoClose={this.onDialogClose}
                    handleAutoCompleateChange={this.handleAutoCompleateChange}
                    handleAutoCompleateInputChange={this.handleAutoCompleateInputChange}
                    isModalOpen={state.merCalculatorDialogOpen}
                    handleYearDropdownChange={this.handleYearDropdownChange}
                    handleOnSearch={this.handleOnSearch}
                    handleDownloadClick={this.handleDownloadClick}
                />
            </Fragment>
        )
    }


}

const mapStateToProps = ({ general }) => ({
    merClientSuggestions: general.merClientSuggestions,
    merYearSuggestions: general.merYearSuggestions,
    merClientSuggestionsLoading: general.merClientSuggestionsLoading,
    merYearSuggestionsLoading: general.merYearSuggestionsLoading

})

const mapDispatchToProps = dispatch => ({
    fetchMERClients: keyword => dispatch(loadMERClientSuggesions(keyword)),
    fetchMERYears: keyword => dispatch(loadMERYearSuggesions(keyword)),
    fetchMERData: keyword => dispatch(loadMERData(keyword))
})

export default connect(mapStateToProps, mapDispatchToProps)(MERCalculatorDialogContainer)