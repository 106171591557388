import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { withRouter } from "react-router"
import { Button, TextField } from "@material-ui/core"
import { withNamespaces } from 'react-i18next'
import { toast } from 'react-toastify';

import { loginFormUpdate } from '../../actions/sessionActions'
import { getPasswordResetEmailAction } from '../../actions/generalActions'
import LatestUpdatesContainer from '../../components/LatestUpdates/LatestUpdatesContainer'
import { EMAIL_REGEX, responseCodeSuccess } from '../../constants/authConstants'
import { baseContentUrl, } from '../../utils/config'
import { SuccessfullyCreated } from '../../constants/notifyConstants.jsx'

import './forgotPassword.scss'

class ForgotPassword extends Component {

	constructor(props, context) {
		super(props, context)
		this.state = {
			isLogIn: false,
			isChrome: navigator.userAgent.indexOf("Chrome") !== -1,
			email: "",
			overlay: true,
			showPassword: false,
			errorFields: { email: false },
			touched: { email: false },
			expired: false,
			errorsInTheForm: true,
			loggedInRealStatus: false,
			emailRule: new RegExp(EMAIL_REGEX),
			prevPath: localStorage.getItem('prevPath') ? localStorage.getItem('prevPath') : null,
		}
	}

	handleBlur = field => _ => this.setState({ touched: { ...this.state.touched, [field]: true, errorsInTheForm: false } },
		() => {
			setTimeout(() => this.setState({
				touched: {
					email: false
				},
			}), 4000)
		})

	updateFormHandler = field => event => {
		switch (field) {
			case "username":
				this.setState({ email: event.target.value, errorsInTheForm: (!this.state.emailRule.test(event.target.value)) ? true : false })
				break

			default:
				return true
		}
		this.props.loginFormUpdate(field, event.target.value)
	}

	formSubmitHandler = _ => this.props.getPasswordResetEmail({ email: this.state.email }).then(data => this.setSuccess(data))


	setSuccess(data) {
		if (data && data.data && data.data.statusCode && data.data.statusCode === responseCodeSuccess) {
			toast.success(<SuccessfullyCreated message={data.data.content.acknowledgement} />);
			this.routeToLogin();
		}
	}

	routeToLogin = () => this.setState({ isLogIn: !this.state.isLogIn })

	render() {

		const {
			t,
			loading,
			username,
			isLoggedIn
		} = this.props

		const {
			touched,
			errorFields,
			errorsInTheForm,
			prevPath
		} = this.state

		if (isLoggedIn) {
			if (prevPath) {
				localStorage.removeItem('prevPath')
				window.location.href = prevPath
				return true
			}
			return <Redirect to={"/dashboard"} />
		}

		if (this.state.isLogIn) { return <Redirect to={"/login"} /> }

		return (
			<>
				<div className="login__page-container">
					<div className="login__page-left-bgimage">
						<LatestUpdatesContainer t={t} />
					</div>
					<div className="login__page-right-bgimage">
						<div className={`login__content-wrapper`}>

							<img src={`${baseContentUrl}/images/MBT-Logo.login.png`} alt="logo" />
							{!this.state.isChrome && <p className='caution'>For the best performance please use the Google Chrome web browser when accessing MBT's web portal.</p>}

							<h4 className='pb5 latest__update-heading'>Forgot Your Password?</h4>
							<TextField
								fullWidth
								autoFocus
								type="email"
								name="email"
								margin="normal"
								disabled={loading}
								className='default-input'
								onBlur={this.handleBlur('username')}
								inputProps={{ autoComplete: "on" }}
								label={t('LABEL_EMAIL_ADDRESS')}
								onChange={this.updateFormHandler('username')}
							/>
							{((touched.username && !username) || errorFields.username || errorsInTheForm) && <span className="error">{t('LABEL_EMAIL_ADDRESS_WARNING')}</span>}

							<div className="login__btn-container">
								<Button size="large" className="defaultActionBtn min__width-overrided" disabled={loading || errorsInTheForm || !username} variant="contained" onClick={this.formSubmitHandler}>{loading ? t('LABEL_PLEASE_WAIT') : 'Reset'}</Button>
								<Button size="large" disabled={loading} onClick={this.routeToLogin}>Go to Login</Button>
							</div>



						</div>
						<div className="mobile__view-only">
							<LatestUpdatesContainer t={t} />
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = ({ session }) => ({
	loading: session.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn
})

const mapDispatchToProps = dispatch => ({
	loginFormUpdate: (username, password) => dispatch(loginFormUpdate(username, password)),
	getPasswordResetEmail: param => dispatch(getPasswordResetEmailAction(param)),
})

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(ForgotPassword))
