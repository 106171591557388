import React, { Component } from "react"
import Button from '@material-ui/core/Button'
import { Paper } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'

import { baseUrl } from '../../utils/config'

import './403.scss'

class Forbidden extends Component {

    state = {
        backToHome: false
    }

    redirectToLocation = _ => window.history.go(-1)

    backToHome = _ => window.location.href = baseUrl

    render() {
        const { t } = this.props
        return (
            <Paper style={{ margin: '1%', padding: 25, width: '98%%' }}>
                <div className="wrapper">
                    <h1>403</h1>
                    <h4>Access Denied/Forbidden</h4>
                    <p>{t('ERROR_TEXT')}</p>
                    <div className="buttons">
                        <Button variant="contained" color="primary" className="defaultActionBtn" onClick={this.redirectToLocation}>{t('BUTTON_BACK')}</Button>
                        <Button variant="contained" color="primary" className="defaultActionBtn" onClick={this.backToHome}>{t('BUTTON_HOME')}</Button>
                    </div>
                </div>
            </Paper>
        )
    }
}

export default withNamespaces()(Forbidden)