import React from 'react'
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment,
    IconButton
} from "@material-ui/core"
import TextField from '@material-ui/core/TextField'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import './PasswordChangeDialog.scss'

export const PasswordChangeDialog = ({
    t,
    state,
    onSubmit,
    handleSubmit,
    handlePasswordChange,
    passwordChangeDialogOpen,
    handleOnChange,
    handleClickShowPassword,
    handleMouseDownPassword
}) => (
    <Dialog disableEnforceFocus={true}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handlePasswordChange}
        open={passwordChangeDialogOpen}
        aria-labelledby="customized-dialog-title"
    >

        <DialogTitle id="form-dialog-title" className='dialog-head'>
            <div className='heading-tab'>
                <span>{t('LABEL_CHANGE_PASSWORD')}</span>
                <IconButton edge="end" color="inherit" onClick={handlePasswordChange} aria-label="close">
                    <HighlightOffIcon />
                </IconButton>
            </div>
        </DialogTitle>


        <DialogContent className="dialog-content">

            <TextField
                fullWidth
                name="oldPassword"
                onChange={handleOnChange}
                label={t('LABEL_OLD_PASSWORD')}
                className='default-text-boxes-wrapper'
                type={state.showPassword ? 'text' : 'password'}
                InputProps={{
                    inputProps: { autocomplete: "off", },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                onClick={handleClickShowPassword}
                                aria-label="toggle password visibility"
                                onMouseDown={handleMouseDownPassword}>
                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}

            />

            <TextField
                fullWidth
                name="newPassword"
                onChange={handleOnChange}
                label={t('LABEL_NEW_PASSWORD')}
                className='default-text-boxes-wrapper'
                type={state.showPassword ? 'text' : 'password'}
                InputProps={{
                    inputProps: { autocomplete: "off", },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                onClick={handleClickShowPassword}
                                aria-label="toggle password visibility"
                                onMouseDown={handleMouseDownPassword}>
                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}

            />

            <TextField
                fullWidth
                name="confirmPassword"
                onChange={handleOnChange}
                label={t('LABEL_CONFIRM_PASSWORD')}
                className='default-text-boxes-wrapper'
                type={state.showPassword ? 'text' : 'password'}
                InputProps={{
                    inputProps: { autocomplete: "off", },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                onClick={handleClickShowPassword}
                                aria-label="toggle password visibility"
                                onMouseDown={handleMouseDownPassword}>
                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}

            />

            {(state.errorsInTheForm && state.activeErrors) && <span className="error">{state.activeErrors}</span>}


        </DialogContent>

        <DialogActions>
            <Button onClick={onSubmit} variant="outline" color="primary" className='add-button' disabled={state.errorsInTheForm}>{t('BUTTON_SUBMIT')}</Button>
        </DialogActions>

    </Dialog>
)