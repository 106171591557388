import { StyleSheet } from 'aphrodite-jss'
import { baseContentUrl } from '../../utils/config'


export const styles = StyleSheet.create({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: '999999',
        // transition: theme.transitions.create(['width', 'margin'], {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
    },
    appBarShift: {
        marginLeft: 220,
        width: 220,
        // transition: theme.transitions.create(['width', 'margin'], {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    menuButton: {
        marginRight: 0,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        width: 220,
        '& div': {
            borderRight: 'none',
            overflowY: 'hidden !important'
            // width: '220px'
        },
    },
    drawerOpen: {
        width: 220,
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
        borderRight: 'none'
    },
    drawerClose: {
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        overflowX: 'hidden',
        // width: theme.spacing(7) + 1,
        // [theme.breakpoints.up('sm')]: {
        //     width: theme.spacing(9) + 1,
        // },
    },
    toolbar: {
        width: '220 px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: `#4278a4`,
        // padding: theme.spacing(0, 1),
        // ...theme.mixins.toolbar,
        minHeight: '78px !important',
        '& h6': {
            width: '190px',
            margin: '14px'
        },
    },
    bgStyle: {
        backgroundRepeat: 'repeat-x',
        boxShadow: 'none'
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
    },
    appbarBgImage: {
        backgroundRepeat: 'no-repeat',
        width: '320px',
        height: '49px',
        backgroundImage: `url(${baseContentUrl}/images/MBT_Logo.png)`,
        backgroundSize: 'contain',
        backgroundPosition: 'center'
    },
    appBarHeaderBgImage: {
        backgroundImage: `url(${baseContentUrl}/images/header-bg.jpeg)`
    }
})