export const authUsernameStatic = process.env.REACT_APP_AUTH_CLIENT 

export const authPasswordStatic = process.env.REACT_APP_AUTH_SECRET 

export const grantType = "password"

export const responseCodeSuccess = 200

export const activationTokenValid = "VALID"

export const activationTokenExpired = "EXPIRED"

export const responseCodeSuccessCreated = 201

export const responseCodeBadRequest = 400

export const responseCodeUnauthorized = 401

export const responseCodePermissionDenied = 403

export const responseCodeServerError = 500

export const EMAIL_REGEX = /^[a-zA-Z0-9-'_.]+@(?:[a-zA-Z0-9-]+\.)+[A-Za-z]+$/