import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { format } from "date-fns"
import { toast } from 'react-toastify';

import { AddNewStudentDialog } from './AddNewStudentsDialog'
import { getCUPermInfoDBSAClients } from '../../constants/helpers.js'
import { getFormattedDate } from '../../constants/helpers.js'
import { loadClientSuggestions, createStudentsAction, loadDashboardRefDataAction } from '../../actions/generalActions'
import { responseCodeSuccess, responseCodeSuccessCreated } from '../../constants/authConstants'
import { SuccessfullyCreated } from '../../constants/notifyConstants'

class AddNewStudentDialogContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirth: null,
            gender: null,
            studentId: "",
            districtStudentId: "",
            status: null,

            clientKeyword: '',
            clientsSuggestions: [],
            clientSuggestionsLoading: false,
            userType: this.props.state.userType,
            addButtonLoading: false,
            genderTypes: [],
            studentStatuses: []
        }

        this._debounceHandlerAdd = _.debounce(
            (value) => {
                this.setState({ clientKeyword: value })
                this.props.fetchAddClients({ keyword: value, fieldSet: 'gen' }).then(_ => this.setClients());
            }, 1000)
    }

    componentDidMount() {
        this.props.loadDashboardRefData().then(data => this.setDashboardRefData(data));

        if (this.props.state.userType === 'U') {

            let cUPermInfoDBSAClients = getCUPermInfoDBSAClients()

            if (cUPermInfoDBSAClients.length === 1) {
                this.setState({ client: cUPermInfoDBSAClients[0] })
            }

            this.setState({ clientsSuggestions: getCUPermInfoDBSAClients() })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.clientsSuggestions && prevProps.clientsSuggestions && this.props.clientsSuggestions.content) {
            if (this.props.clientsSuggestions.content !== prevProps.clientsSuggestions.content) {
                this.setState({ clientsSuggestions: this.props.clientsSuggestions.content })
            }
        }

        if (this.props.clientSuggestionsLoading !== prevProps.clientSuggestionsLoading) {
            this.setState({ clientSuggestionsLoading: this.props.clientSuggestionsLoading })
        }
    }

    // onSubmit = _ => {
    //     this.setState({ addButtonLoading: true });
    // }

    onSubmit = _ => { this.setState({ addButtonLoading: true }); this.props.createStudents(this.getAddParams()).then(data => this.onAfterSubmit(data)) }

    getAddParams = _ => {
        var date = new Date(this.state.dateOfBirth);
        var formattedDate = format(date, "MM/dd/yyyy");

        return {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            studentId: this.state.studentId,
            districtStudentId: this.state.studentId,
            middleName: this.state.middleName,
            dateOfBirth: formattedDate,
            gender: { value: this.state.gender },
            // status: this.state.status,
            client: this.state.client
        }
    }

    onAfterSubmit(data) {
        this.setState({ addButtonLoading: false })

        if (data && data.status && data.status === responseCodeSuccessCreated) {
            toast.success(<SuccessfullyCreated message={this.props.t('TOAST_STUDENT_CREATED')} />);
            this.clearInputFields()
            if (typeof this.props.onAddStudentDialogChange === 'function') {
                this.props.onAddStudentDialogChange(false)
            }
            this.componentDidMount()
        }
    }

    clearInputFields() {
        this.setState({
            client: null,
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirth: null,
            gender: null,
            studentId: "",
            // status: null,
        })
    }

    setDashboardRefData(data) {
        if (data && data.statusCode && data.statusCode === responseCodeSuccess && data.content && data.content.studentStatuses) {
            this.setState({ genderTypes: data.content.genderTypes, studentStatuses: data.content.studentStatuses })
            data.content.studentStatuses.map((status) => {
                if (status.value === 'U') {
                    this.setState({ status: status })
                }
                return true;
            })
        }
    }

    setClients() {
        if (this.props.clients && this.props.clients.content) {
            this.setState({ clients: this.props.clients.content })
        }
        if (this.props.clientsSuggestions && this.props.clientsSuggestions.content) {
            this.setState({ clientsSuggestions: this.props.clientsSuggestions.content })
        }
    }


    handleAutoCompleateInputChange = (value) => { if (value) this._debounceHandlerAdd(value) }

    handleDateChange = (field, selectedDate) => {
        this.setState({ [field]: selectedDate ? getFormattedDate(selectedDate) : null })
    }

    handleDOB = date => this.setState({ dob: date })

    handleOnChange = event => this.setState({ [event.target.name]: event.target.value })

    handleAutoCompleateChange = (name, value) => this.setState({ [name]: value })


    render() {

        const { t, state, handleAddClose } = this.props

        return (
            <AddNewStudentDialog
                t={t}
                state={this.state}
                onSubmit={this.onSubmit}
                handleDOB={this.handleDOB}
                handleAddClose={handleAddClose}
                handleOnChange={this.handleOnChange}
                openAddStudentInfoDialog={state.addStudentInfoDialog}

                handleAutoCompleateInputChange={this.handleAutoCompleateInputChange}
                handleDateChange={this.handleDateChange}
                handleAutoCompleateChange={this.handleAutoCompleateChange}
            />
        )
    }
}

const mapStateToProps = ({ general }) => ({
    loading: general.loading,
    clientSuggestionsLoading: general.clientsLoading,
    clientsSuggestions: general.clientSuggestions,
    dashboardRefData: general.dashboardRefData,
})

const mapDispatchToProps = dispatch => ({
    fetchAddClients: keyword => dispatch(loadClientSuggestions(keyword)),
    createStudents: params => dispatch(createStudentsAction(params)),
    loadDashboardRefData: params => dispatch(loadDashboardRefDataAction(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewStudentDialogContainer)
