import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import GetAppIcon from '@material-ui/icons/GetApp';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { studentDocumentFileDownloadAction } from '../../../actions/generalActions'
import { toast } from 'react-toastify';
import { SuccessfullyCreated } from '../../../constants/notifyConstants';
import { responseCodeSuccess } from '../../../constants/authConstants';
import { merFileDownload } from '../../../services/containerService';

class DownloadMERDocumentCellRenderer extends Component {

    state = {
        downloadBtnStatus: false
    }

    handleDownloadable = _ => {
        this.setState({ downloadBtnStatus: true });
        let toastId = toast.success(<SuccessfullyCreated message="Requesting file from server..." />);
        let params = {
            clientId: this.props.filterInformation.client,
            year: this.props.filterInformation.year,
            month: this.props.filterInformation.month
        }
        merFileDownload(params, this.props.params.excelFileName).then((response) => {
            if (response === responseCodeSuccess) {
                toast.success(<SuccessfullyCreated message="MER Document Downloaded" />)
            }
            toast.dismiss(toastId);
            this.setState({ downloadBtnStatus: false });
        });
    }

    render() {
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                {this.props.params.excelExportable &&
                    (!this.state.downloadBtnStatus ?
                        <Tooltip title="Download">
                            <IconButton style={{padding: 'unset'}} onClick={this.handleDownloadable} >
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Download in progress">
                            <IconButton style={{padding: 'unset'}}>
                                <CircularProgress size={24} color="success" />
                            </IconButton>
                        </Tooltip>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = ({ general }) => {
    return {}
}

const mapDispatchToProps = dispatch => ({
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(DownloadMERDocumentCellRenderer)