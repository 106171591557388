import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';

import { PasswordChangeDialog } from './PasswordChangeDialog'
import { changePasswordAction } from '../../actions/generalActions'
import { SuccessfullyCreated } from '../../constants/notifyConstants.jsx'
import { responseCodeSuccess } from '../../constants/authConstants';
import { hasNumber, hasLowerCase, hasUpperCase } from '../../constants/helpers'

class PasswordChangeDialogDialogContainer extends Component {

    state = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        showPassword: false,
        errorsInTheForm: true,
        activeErrors: '',

    }

    onSubmit = _ => {
        this.props.changePassword({
            "oldPassword": this.state.oldPassword,
            "newPassword": this.state.newPassword,
            "confirmedNewPassword": this.state.confirmPassword,
        }).then(data => this.afterSubmit(data))
    }

    afterSubmit = data => {
        if (data && data.statusCode && data.statusCode === responseCodeSuccess) {
            toast.success(<SuccessfullyCreated message="Password changed successfully." />);
            this.props.handlePasswordChange()
        }
    }

    handleSubmit = _ => { }

    handleOnChange = event => {
        this.setState({ [event.target.name]: event.target.value })

        setTimeout(() => {
            var errors = "";
            var errorsInForm = true;

            if (!this.state.newPassword) {
                errors = 'New password is required';
            } else if (!this.state.confirmPassword) {
                errors = 'Confirm Password is required';
            } else if (this.state.newPassword.length < 6) {
                errors = 'Password minimum requirements should be 6 characters';
            } else if (!hasNumber(this.state.newPassword)) {
                errors = 'Password should contain at least 1 number';
            } else if (!hasLowerCase(this.state.newPassword)) {
                errors = 'Password should contain at least 1 lowercase letter';
            } else if (!hasUpperCase(this.state.newPassword)) {
                errors = 'Password should contain at least 1 uppercase letter';
            } else if (this.state.newPassword !== this.state.confirmPassword) {
                errors = 'Password mismatch';
            } else {
                errorsInForm = false;
            }

            this.setState({ activeErrors: errors, errorsInTheForm: errorsInForm })
        }, 500)
    }

    handleClickShowPassword = _ => this.setState({ showPassword: !this.state.showPassword })

    handleMouseDownPassword = event => event.preventDefault()

    render() {

        const { t, state, handlePasswordChange } = this.props
        return (
            <PasswordChangeDialog
                t={t}
                state={this.state}
                onSubmit={this.onSubmit}
                handleSubmit={this.handleSubmit}
                handlePasswordChange={handlePasswordChange}
                handleOnChange={this.handleOnChange}
                passwordChangeDialogOpen={state.passwordChangeDialogOpen}
                handleClickShowPassword={this.handleClickShowPassword}
                handleMouseDownPassword={this.handleMouseDownPassword}
            />
        )
    }
}

const mapStateToProps = ({ general }) => ({
    loading: general.loading
})

const mapDispatchToProps = dispatch => ({
    changePassword: param => dispatch(changePasswordAction(param)),

})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChangeDialogDialogContainer)
