import React, { Component } from 'react'
import { Switch, Route, HashRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'

import Layout from './layout/layout'
import MicroFrontend from '../MicroFrontend'
// import NotFound from '../screens/404/404'
import Forbidden from '../screens/403/403'
import Login from '../screens/login/login'
import ForgotPassword from '../screens/account/forgotPassword'
import ResetPassword from '../screens/account/resetPassword'
import AccountActivation from '../screens/account/accountActivation'

import PrivateRoute from '../PrivateRoute'
import AuthGuard from '../AuthGuard'
import { 
    checkLoginStatus, 
} from '../actions/sessionActions'

import Snackbar from '../reusable/Snackbar/Snackbar'

import '../global.scss'
import '../responsive.scss'

const {
    REACT_APP_OPTICLAIM_HOST: opticlaimHost,
    REACT_APP_DASHBOARD_HOST: dashboardHost,
    REACT_APP_ADMIN_HOST: administrationHost,
    REACT_APP_OPTIREPORTS_HOST: optireportsHost,
    REACT_APP_FILEPROCESSOR_HOST: fileprocessorHost,
    REACT_APP_OPTILEARN_HOST: optilearnHost    
} = process.env

const contentHost = process.env.REACT_APP_CONTENT_HOST
const Dashboard = ({ history }) => <MicroFrontend history={history} host={dashboardHost} name="Dashboard" />
const Administration = ({ history }) => <MicroFrontend history={history} host={administrationHost} name="Administration" />
const Opticlaim = ({ history }) => <MicroFrontend history={history} host={opticlaimHost} name="Opticlaim" />
const Optireports = ({ history }) => <MicroFrontend history={history} host={optireportsHost} name="Optireports" />
const Optilearn = ({ history }) => <MicroFrontend history={history} host={optilearnHost} name="Optilearn" />    
const Fileprocessor = ({ history }) => <MicroFrontend history={history} host={fileprocessorHost} name="Fileprocessor" />    

class App extends Component {
    
    componentWillMount() {
        this.props.checkLoginStatus()
    }

    render() {  
        
        return ( 
            <>
                <HashRouter>
                    <>              
                        <Route component={Snackbar} />
                        <Route exact path="/" component={Login} />                             
                        <Route exact path="/temporary-password" component={Login} />                             
                        <Route exact path="/login" component={Login} />   
                        <Route exact path="/forgot-password" component={ForgotPassword} />   
                        <Route exact path="/account/password-reset" component={ResetPassword} />   
                        <Route exact path="/account/activation" component={AccountActivation} />   

                        <Layout contentHost={contentHost}>      
                            <Switch>                                    
                                <Route path="/authguard" component={AuthGuard} />      
                                <Route path="/forbidden" component={Forbidden} />                                          
                                <PrivateRoute path="/opticlaim" component={Opticlaim} />
                                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                <PrivateRoute path="/administration" component={Administration} />
                                <PrivateRoute path="/optireports" component={Optireports} />
                                <PrivateRoute path="/fileprocessor" component={Fileprocessor} />      
                                <PrivateRoute path="/optilearn" component={Optilearn} />                                                                
                                {/* {(window.location.hash !== '' && window.location.hash !== '#/login' && window.location.hash !== '#/') && <Route component={NotFound} />}                                 */}
                            </Switch>                                          
                        </Layout>                                                        
                    </>                    
                </HashRouter>
            </>
        )
    }

}

const mapStateToProps = ({ navigation, session }) => ({
    isLoggedIn: session.isLoggedIn,
})

const mapDispatchToProps = dispatch => ({    
    checkLoginStatus: _ => dispatch(checkLoginStatus()),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(App)
