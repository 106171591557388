import { logout, basicAuthenticate, passwordCreateService } from '../services/authService'
import { LocalStorageService } from '../services/localStorageService'

import { responseCodeSuccess } from '../constants/authConstants'
import { base64Decode } from '../utils/misc'

export const SESSION_END = "SESSION_END"
export const SESSION_INITIALISE = "SESSION_INITIALISE"
export const LOGIN_FORM_UPDATE = "REGISTER_FORM_UPDATE"
export const SEND_LOGIN_FORM_REQUEST = "LOGIN_FORM_UPDATE"
export const LOGIN_PROCESS_SUCCESS = "LOGIN_PROCESS_SUCCESS"
export const SEND_LOGIN_CLEAR_FORM = "SEND_LOGIN_CLEAR_FORM"
export const SEND_LOGIN_FORM_SUCCESS = "SEND_LOGIN_FORM_SUCCESS"
export const SEND_LOGIN_FORM_FAILURE = "SEND_LOGIN_FORM_FAILURE"
export const UPDATE_CHECK_USER_SESSION = "UPDATE_CHECK_USER_SESSION"
export const UPDATE_USER_SESSION_TIME_PASSED = "UPDATE_USER_SESSION_TIME_PASSED"
export const SEND_PASSWORD_RESET_FORM_REQUEST = "SEND_PASSWORD_RESET_FORM_REQUEST"
export const SEND_PASSWORD_RESET_FORM_SUCCESS = "SEND_PASSWORD_RESET_FORM_SUCCESS"
export const SEND_PASSWORD_RESET_FORM_FAILURE = "SEND_PASSWORD_RESET_FORM_FAILURE"

const sendLoginFormRequest = _ => ({
    type: SEND_LOGIN_FORM_REQUEST
})

const sendLoginFormSuccess = _ => ({
    type: SEND_LOGIN_FORM_SUCCESS
})

const sendLoginFormFailure = _ => ({
    type: SEND_LOGIN_FORM_FAILURE
})

// const sendPasswordResetFormRequest = _ => ({
//     type: SEND_PASSWORD_RESET_FORM_REQUEST
// })

// const sendPasswordResetFormSuccess = _ => ({
//     type: SEND_PASSWORD_RESET_FORM_SUCCESS
// })

// const sendPasswordResetFormFailure = _ => ({
//     type: SEND_PASSWORD_RESET_FORM_FAILURE
// })

const decodeTheResponseThenDesturctTheValues = (accessToken, refreshToken, expireIn, allowedModules) => {
    const decodedResponse = base64Decode(accessToken)
    return {
        username: decodedResponse.user_name,
        userId: decodedResponse.user_id,
        userType: decodedResponse.user_type,
        expire: Math.round(expireIn),
        clientId: decodedResponse.client_id,
        accessToken: accessToken,
        refreshToken: refreshToken,
        permissions: decodedResponse.authorities,
        scope: decodedResponse.scope,
        allowedModules: allowedModules,
        changePwd: decodedResponse.changePwd,
    }
}

export const sendLoginClearForm = _ => ({
    type: SEND_LOGIN_CLEAR_FORM
})

export const sessionInitilize = ({ username = '', userId = '', userType = '', expire = '', clientId = '', accessToken = '', refreshToken = '', permissions = [], scope = '' }) => ({
    type: SESSION_INITIALISE,
    username,
    userId,
    userType,
    expire,
    clientId,
    accessToken,
    refreshToken,
    permissions,
    scope
})

export const updateHowMuchTimePassedWithUserActiveSession = userSessionTimePassed => ({
    type: UPDATE_USER_SESSION_TIME_PASSED,
    userSessionTimePassed
})

export const checkUserSessionActionStartedAlready = _ => ({
    type: UPDATE_CHECK_USER_SESSION
})

export const sessionEnd = _ => ({
    type: SESSION_END
})

export const loginFormUpdate = (field, value) => ({
    type: LOGIN_FORM_UPDATE,
    field,
    value
})

export const login = (captchaValue = "") => (dispatch, getState) => {
    const { username, password } = getState().session
    dispatch(sendLoginFormRequest())
    return basicAuthenticate(username, password, captchaValue).then(response => {
        if (response.status === responseCodeSuccess) {
            const sessionObject = decodeTheResponseThenDesturctTheValues(response.data.access_token, response.data.refresh_token, response.data.expires_in, response.data.allowedModules)
            LocalStorageService.updateLocalStorage(sessionObject)
            dispatch(sessionInitilize(sessionObject))
            dispatch(sendLoginFormSuccess())
        } else {
            dispatch(sendLoginFormFailure())
        }
        return response
    })
        .catch(error => {
            dispatch(sendLoginFormFailure())
            return error
        })
}

export const createNewPasswordAction = (params) => async _ => {
    return await passwordCreateService(params).then(response => {
        return response
    }).catch(error => {
        return error
    });
}

export const logoutUser = _ => _ => logout().then().catch(response => response)

export const checkLoginStatus = _ => dispatch => {
    const accessToken = LocalStorageService.getTheLocalStorageKey('accessToken')
    if (accessToken) {
        const sessionObject = decodeTheResponseThenDesturctTheValues(accessToken)
        dispatch(sessionInitilize(sessionObject))
        return new Promise((resolve, reject) => resolve(accessToken))
    } else {
        return null
    }
}

export const passwordReset = _ => (dispatch, getState) => {

}

export const passwordChange = _ => (dispatch, getState) => {

}
