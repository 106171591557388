import React, { Component } from 'react'
import {  Redirect } from "react-router-dom";

import { authenticateByRefreshToken } from './services/authService'
import { LocalStorageService } from './services/localStorageService'

export default class AuthGuard extends Component {

    constructor(props) {
        super(props)
        this.state = { routeState: null }
    }

    componentDidMount() {
        const refreshToken = localStorage.getItem('refreshToken')
        const authenticated = authenticateByRefreshToken(refreshToken);

        authenticated.then(resp => {

            if (!resp) {
                LocalStorageService.clearTokens()
                window.location.href = '/'
            }
            if (resp && resp.data && resp.data.access_token) {
                LocalStorageService.updateTokens(resp.data)
                this.setState({ routeState: 1 })
            } else {
                this.setState({ routeState: 0 })
            }
        }).catch((error) => {

            this.setState({ routeState: 2 })
        })
    }

    render() {
        const { location } = this.props
        const {
            routeState
        } = this.state



        if (routeState !== null) {
            if (routeState === 1 && location.state) {
                return <Redirect to={location.state} />
            } else {
                return <Redirect to={"/"} />
            }
        }

        return (<></>)
    }

}