import React from 'react'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import '../assets/toastify_scss/main.scss'

export const SuccessfullyCreated = (params) => (
    <div style={{ display: 'flex', marginLeft: '10px' }}>
        <CheckCircleOutlinedIcon />
        <div style={{ fontSize: '20px !important', fontWeight: 800, margin: '2px 0 0 10px' }}> {(params && params.message) ? params.message : 'Successfully Created.'} </div>
    </div>
)

export const SuccessfullyDeleted = () => (
    <div style={{ display: 'flex', marginLeft: '10px' }}>
        <CheckCircleOutlinedIcon />
        <div style={{ fontSize: '20px !important', fontWeight: 800, margin: '2px 0 0 10px' }}> Successfully Deleted. </div>
    </div>
)

export const ErrorOccurred = () => (
    <div style={{ display: 'flex', marginLeft: '10px' }}>
        <ErrorOutlineOutlinedIcon />
        <div style={{ fontSize: '20px !important', fontWeight: 800, margin: '2px 0 0 10px' }}> Error Occurred. </div>
    </div>
)

export const PredefinedNonBillableDate = () => (
    <div style={{ display: 'flex', marginLeft: '10px' }}>
        <ErrorOutlineOutlinedIcon />
        <div style={{ fontSize: '20px !important', fontWeight: 800, margin: '2px 0 0 10px' }}> Can not select predefined Non-Billable date. </div>
    </div>
)

export const Error = (params) => (
    <div style={{ color: '#000', display: 'flex', marginLeft: '10px' }}>
        <ErrorOutlineOutlinedIcon />
        <div style={{ fontSize: '20px !important', fontWeight: 800, margin: '2px 0 0 10px' }}> {(params && params.message) ? params.message : 'Error Occurred.'} </div>
    </div>
)

export const CodeError = (params) => (
    <div style={{ color: '#000', display: 'flex', marginLeft: '10px' }}>
        <WarningIcon />
        <div>
            <div style={{ fontWeight: 800, margin: '2px 0 0 10px' }}> Something went wrong. </div>
            <div style={{ margin: '2px 0 0 10px' }}> <small style={{ overflowWrap: 'anywhere' }}>{(params && params.message) ? params.message : 'Error Occurred.'} </small> </div>
        </div>
    </div>
)