import React, { Component } from 'react'

import { LatestUpdates } from './LatestUpdates'

class LatestUpdatesContainer extends Component {

    render() {
        const { t } = this.props
        return (
            <LatestUpdates t={t} />
        )
    }
}

export default LatestUpdatesContainer