import React from 'react'
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    IconButton,
    Tab,
    LinearProgress,
    Box
} from "@material-ui/core"
import { Typography, Grid } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { css } from 'aphrodite-jss'
import { Form } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete';
import MBTDataTable from '../../reusable/MBTDataTable/MBTDataTable'
import CaseloadInfoDataTable from '../../reusable/MBTDataTable/CaseloadInfoDataTable'
import { styles } from '../../assets/ContainerStyles.jsx'
import { DropzoneArea } from 'material-ui-dropzone';
import './StudentInformationDialog.scss'
import { ThemeButton } from '../../constants/themeComponents.js';

export const StudentInformationDialog = ({
    t,
    state,
    props,
    onSubmit,
    // handleSubmit,
    handleInfoClose,
    studentInfoDialogOpen,
    handleAutoCompleateInputChange,
    handleAutoCompleateChange,
    handleStudentInputChange,
    handleTabChange,
    handleOnChange,
    onAddStudentNote,
    redirectClose,
    uploadStudentDocument,
    studentDocumentUploadOpen,
    studentDocumentUploadClose,
    addStudentDocument,
    removeStudentDocument,
    handleFileRejected,
    removeFileError
}) => (



    <Dialog disableEnforceFocus={true} open={studentInfoDialogOpen} onClose={handleInfoClose} aria-labelledby="form-dialog-title" fullWidth maxWidth='lg' >

        <Form onSubmit={onSubmit} render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>

                <DialogTitle id="form-dialog-title" className='dialog-head'>
                    <div className='heading-tab'>
                        <span>{t('LABEL_STUDENT_INFO')}</span>
                        <IconButton edge="end" color="inherit" onClick={handleInfoClose} aria-label="close">
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className='flex'>

                    {state.userType && state.userType !== 'U' &&
                        <Autocomplete
                            value={state.client}
                            loadingText={t('LABEL_LOADING')}
                            className='autocompletes-type-wrapper'
                            loading={state.clientSuggestionsLoading}
                            renderOption={(option, { selected }) => (<> {option.name} </>)}
                            onInputChange={(event, value) => handleAutoCompleateInputChange(value)}
                            onChange={(event, value) => handleAutoCompleateChange('client', value)}
                            getOptionLabel={option => (option && option.name) ? option.name : ''}
                            options={state.clientSuggestionsLoading ? [] : state.clientsSuggestions}
                            noOptionsText={(state.clientKeyword.length > 0 && state.clientsSuggestions.length < 1) ? t('LABEL_NO_OPTIONS') : ''}
                            renderInput={params => <TextField autoFocus required {...params} label={t('LABEL_CLIENT')} margin="normal" fullWidth />}
                        />
                    }

                    {state.userType && state.userType === 'U' && state.clientsSuggestions && state.clientsSuggestions.length !== 1 &&
                        <Autocomplete
                            value={state.client}
                            className='autocompletes-type-wrapper'
                            renderOption={(option, { selected }) => (<> {option.name} </>)}
                            onChange={(event, value) => handleAutoCompleateChange('client', value)}
                            getOptionLabel={option => (option && option.name) ? option.name : ''}
                            options={state.clientSuggestionsLoading ? [] : state.clientsSuggestions}
                            noOptionsText={(state.clientKeyword.length > 0 && state.clientsSuggestions.length < 1) ? t('LABEL_NO_OPTIONS') : ''}
                            renderInput={params => <TextField autoFocus required {...params} label={t('LABEL_CLIENT')} margin="normal" fullWidth />}
                        />
                    }

                    {state.userType && state.userType === 'U' && state.clientsSuggestions && state.clientsSuggestions.length === 1 && state.client &&
                        <div style={{ width: '100%' }}>
                            <label className='labelx'>{t('LABEL_CLIENT_REQ')}</label>
                            <p>{state.client.name}</p>
                        </div>
                    }

                    <Autocomplete
                        disabled={!state.client}
                        value={state.student}
                        loadingText={t('LABEL_LOADING')}
                        className='autocompletes-type-wrapper'
                        loading={state.studentSuggestionsLoading}
                        filterOptions={(options, state) => options}
                        renderOption={(option, { selected }) => (<> {option.name} </>)}
                        onInputChange={(event, value) => handleStudentInputChange(value)}
                        onChange={(event, value) => handleAutoCompleateChange('student', value)}
                        getOptionLabel={option => (option && option.name) ? option.name : ''}
                        options={state.studentSuggestionsLoading ? [] : state.studentsSuggestions}
                        noOptionsText={(state.studentKeyword.length > 0 && state.studentsSuggestions.length < 1) ? t('LABEL_NO_OPTIONS') : ''}
                        renderInput={params => <TextField required {...params} label={t('LABEL_STUDENT')} margin="normal" fullWidth />}
                    />

                    <DialogActions>
                        <Button type='submit' disabled={!state.student || !state.client || props.infoSearchLoading} color="primary" variant="outline" className='add-button'>{props.infoSearchLoading ? t('LABEL_WAIT') : t('BUTTON_SEARCH')}</Button>
                    </DialogActions>

                </DialogContent>
            </form>
        )}
        />




        {state.isSubmit && state.student && (
            <div className='tab-container'>
                <Tabs
                    value={state.value}
                    className='tab-menu'
                    indicatorColor="primary"
                    onChange={handleTabChange}
                >
                    <Tab className='tab-panel-header' label="General Information" />
                    <Tab className='tab-panel-header' label="Caseloads" />
                    <Tab className='tab-panel-header' label="Student Goals/Notes" />
                    <Tab className='tab-panel-header' label="Student Documentation" />
                </Tabs>

                <Typography
                    // value={state.value}
                    // index={0}
                    className='tab-panel'
                    component="div"
                    role="tabpanel"
                    hidden={state.value !== 0}
                // id={`scrollable-force-tabpanel-${0}`}
                // aria-labelledby={`scrollable-force-tab-${0}`}
                >

                    <div className='pannel-div'>
                        <Grid container>
                            <Grid item xs={12} md={2}>
                                <div>
                                    <label className='labelx'>First Name</label>
                                    <p>{(state.studentInfoGeneralFields && state.studentInfoGeneralFields.firstName) ? state.studentInfoGeneralFields.firstName : ''}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div>
                                    <label className='labelx'>Last Name</label>
                                    <p>{(state.studentInfoGeneralFields && state.studentInfoGeneralFields.lastName) ? state.studentInfoGeneralFields.lastName : ''}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div>
                                    <label className='labelx'>Middle Name</label>
                                    <p>{(state.studentInfoGeneralFields && state.studentInfoGeneralFields.middleName) ? state.studentInfoGeneralFields.middleName : ''}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div>
                                    <label className='labelx'>Date of Birth</label>
                                    <p>{(state.studentInfoGeneralFields && state.studentInfoGeneralFields.dateOfBirth) ? state.studentInfoGeneralFields.dateOfBirth : ''}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div>
                                    <label className='labelx'>Student ID</label>
                                    <p>{(state.studentInfoGeneralFields && state.studentInfoGeneralFields.districtStudentId) ? state.studentInfoGeneralFields.districtStudentId : ''}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <div>
                                    <label className='labelx'>IEP Status</label>
                                    <p>{(state.studentInfoGeneralFields && state.studentInfoGeneralFields.iep) ? state.studentInfoGeneralFields.iep : ''}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <div>
                                    <label className='labelx'>Student Gender</label>
                                    <p>{(state.studentInfoGeneralFields && state.studentInfoGeneralFields.gender && state.studentInfoGeneralFields.gender.label) ? state.studentInfoGeneralFields.gender.label : ''}</p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                </Typography>

                <Typography
                    // value={state.value}
                    // index={1}
                    className='tab-panel'
                    component="div"
                    role="tabpanel"
                    hidden={state.value !== 1}
                // id={`scrollable-force-tabpanel-${1}`}
                // aria-labelledby={`scrollable-force-tab-${1}`}
                >
                    <div className='overflow-div1'>

                        {(state.studentInfoCaseLoadFields && state.studentInfoCaseLoadFields.length > 0) && (
                            <CaseloadInfoDataTable
                                data={state.studentInfoCaseLoadFields}
                                columns={state.studentInfoCaseLoadColumns}
                                totalRecords={state.studentInfoCaseLoadFields.length}
                                options={state.mbtDTOptions}
                                pagination={state.pagination}
                                onRowClickURL="/opticlaim/caseload/"
                                redirectClose={redirectClose}
                            />
                        )}

                        {(!state.studentInfoCaseLoadFields || state.studentInfoCaseLoadFields.length === 0) && (
                            <p className='no-records'>No Records Found</p>
                        )}
                    </div>
                </Typography>


                <Typography
                    // value={state.value}
                    // index={1}
                    className='tab-panel'
                    component="div"
                    role="tabpanel"
                    hidden={state.value !== 2}
                // id={`scrollable-force-tabpanel-${2}`}
                // aria-labelledby={`scrollable-force-tab-${2}`}
                >
                    <div className='pannel-div1'>
                        <div style={{ display: 'grid', marginTop: '10px' }}>
                            <label className='label-note'>New Note/Goal  {state.clientNote && <small>{state.clientNote.length + '/'}2000 </small>} </label>
                            <textarea rows="3" cols="90" autocomplete="off" maxLength="2000" name="clientNote" value={state.clientNote} onChange={handleOnChange} ></textarea>
                        </div>
                        <Button disabled={!state.clientNote || props.addGoalLoading} onClick={onAddStudentNote} color="primary" variant="outline" className='add-note-button'>{props.addGoalLoading ? t('LABEL_WAIT') : t('LABEL_ADD_NOTE')}</Button>
                    </div>

                    <div className='overflow-div1'>

                        {(state.studentInfoGoalsData && state.studentInfoGoalsData.length > 0) && (

                            <MBTDataTable
                                data={state.studentInfoGoalsData}
                                columns={state.studentInfoGoalsColumns}
                                // totalRecords={state.totalRecords}
                                totalRecords={state.studentInfoGoalsData.length}
                                options={state.mbtDTOptions}
                                pagination={state.pagination}
                            />
                        )}

                        {(!state.studentInfoGoalsData || state.studentInfoGoalsData.length === 0) && (
                            <p className='no-records'>No Records Found</p>
                        )}
                    </div>
                </Typography>

                <Typography
                    className='tab-panel'
                    component="div"
                    role="tabpanel"
                    hidden={state.value !== 3}
                >
                    <Grid container>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <div className='pannel-div1'>
                                <Button color="primary" variant="outline" className='add-note-button' onClick={studentDocumentUploadOpen}>
                                    Upload Document
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='overflow-div1'>
                                {(state.studentDocuments.length > 0) && (
                                    <MBTDataTable
                                        name="studentDocumentation"
                                        displayName="Student Documentation"
                                        data={state.studentDocuments}
                                        totalRecords={state.studentDocuments.length}
                                        columns={state.studentDocumentColumns}
                                        options={state.mbtDTOptions}
                                        pagination={state.pagination}
                                    />
                                )}
                                {(state.studentDocuments.length === 0) && (
                                    <p className='no-records'>There have been no documentation files provided for this student.</p>
                                )}
                            </div>
                            <Dialog disableEnforceFocus={true} open={state.studentDocumentUpload} onClose={studentDocumentUploadClose} fullWidth maxWidth='lg'>
                                <DialogTitle className={css(styles.dialogTtl)} id="form-dialog-title">
                                    <div className={css(styles.dialogHead)}>
                                        <span>Upload Student Document</span>
                                        <IconButton edge="end" color="inherit" onClick={studentDocumentUploadClose} aria-label="close">
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    {!state.uploadInProgress && ((!state.fileSizeError && !state.fileTypeError)) &&
                                        <>
                                            <p>{state.studentFileUploadInstruction && state.studentFileUploadInstruction}</p>
                                            <br />
                                        </>
                                    }
                                    {(state.studentFileMaxSizeInMB > 0) &&
                                        ((state.fileSizeError || state.fileTypeError)
                                            ?
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="20vh" width="100%" p={2}>
                                                <Box mb={2} textAlign="center">
                                                    <Typography variant="body1" style={{ fontSize: '0.75rem' }} color="error">
                                                        {state.fileSizeError && state.studentFileSizeValidationMessage}
                                                        <br />
                                                        {state.fileTypeError && state.studentFileTypeValidationMessage}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <ThemeButton type="submit" variant="contained" color="primary" onClick={removeFileError}>
                                                        Ok
                                                    </ThemeButton>
                                                </Box>
                                            </Box>
                                            :
                                            (!state.uploadInProgress)
                                                ?
                                                <DropzoneArea
                                                    dropzoneText="Drag and drop the file that you would like to upload."
                                                    onChange={addStudentDocument}
                                                    onDelete={removeStudentDocument}
                                                    filesLimit={1}
                                                    useChipsForPreview
                                                    previewGridProps={{ item: { class: css(styles.previewListItem) } }}
                                                    onDropRejected={handleFileRejected}
                                                    showAlerts={false}
                                                    maxFileSize={state.studentFileMaxSizeInMB}
                                                    acceptedFiles={state.supportedStudentFileTypes}
                                                />
                                                :
                                                <LinearProgress style={{ marginTop: '10px' }} />
                                        )
                                    }
                                </DialogContent>
                                <DialogActions>
                                    {!state.uploadInProgress && ((!state.fileSizeError && !state.fileTypeError)) &&
                                        <>
                                            <Button autoFocus onClick={studentDocumentUploadClose} color="primary"> Cancel</Button>
                                            <Button onClick={uploadStudentDocument} disabled={state.studentDocumentFileUploadBtnStatus} color="primary"> Confirm </Button>
                                        </>
                                    }
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                </Typography>

            </div >
        )}

    </Dialog >
)