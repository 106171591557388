import React from 'react'
import ReactDOM from 'react-dom'
import 'react-app-polyfill/ie11'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { rootReducer } from './reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'

// import logger from 'redux-logger'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import './i18n'
import App from './layouts/App'

import { unregister } from './registerServiceWorker'


const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__

// const middlewares = [thunkMiddleware, logger]
const middlewares = [thunkMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares)),
    // applyMiddleware(thunkMiddleware)
)

i18next.init({
    interpolation: { escapeValue: false }
})

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </Provider>, document.getElementById('root')
)

unregister()
