import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

class MicroFrontend extends Component {

    componentDidMount() {
        const { name, host, document } = this.props
        const scriptId = `micro-frontend-script-${name}`
        if (document.getElementById(scriptId)) {
            this.renderMicroFrontend()
            return
        }
        fetch(`${host}/asset-manifest.json`)
            .then(res => res.json())
            .then(manifest => {
                const script = document.createElement('script')
                script.id = scriptId
                script.crossOrigin = ''

                if (host.includes("localhost")) {
                    if (name === 'Dashboard' || name === 'Fileprocessor') {
                        script.src = `${host}${manifest['files']['main.js']}`
                    } else {
                        script.src = `${host}${manifest['main.js']}`
                    }
                } else {
                    if (name === 'Dashboard' || name === 'Fileprocessor') {
                        script.src = `${manifest['files']['main.js']}`
                    } else {
                        script.src = `${manifest['main.js']}`
                    }
                    script.src = script.src.replace(`https:/${process.env.REACT_APP_CONTAINER_URL}/`, '')
                }

                script.onload = this.renderMicroFrontend
                document.head.appendChild(script)
            })
    }

    componentWillUnmount() {
        const { name, window } = this.props
        if (window[`unmount${name}`]) {
            window[`unmount${name}`](`${name}-container`)
        }
    }

    renderMicroFrontend = _ => {
        const { name, window, history } = this.props
        window[`render${name}`](`${name}-container`, history)
    }

    render() {
        return (
            <main id={`${this.props.name}-container`} style={{ padding: '15px' }}>
                <CircularProgress style={{
                    margin: '0 auto',
                    marginTop: '10%'
                }} disableShrink />
                {this.props.children}
            </main>
        )
    }
}

MicroFrontend.defaultProps = {
    window,
    document
}

export default MicroFrontend
