import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton
} from "@material-ui/core"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import './RecentlyEnteredServicesDialog.scss'
// import { fetchRecentServicesAction } from '../../actions/generalActions';
import MBTDataTable from '../../reusable/MBTDataTable/MBTDataTable';
import TableSkeltonScreen from '../../reusable/SkeltonScreens/TableSkeltonScreen';

class RecentlyEnteredServicesDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recentServicesColumns: [
                {
                    name: "client",
                    label: "Client",
                    omitSort: true
                },
                {
                    name: "student",
                    label: "Student",
                    omitSort: true
                },
                {
                    name: "practitioner",
                    label: "Practitioner",
                    omitSort: true
                },
                {
                    name: "serviceDate",
                    label: "Date of Service ",
                    width: 120,
                    omitSort: true
                },
                {
                    name: "serviceType",
                    label: "service Type",
                    omitSort: true
                },
                {
                    name: "startTime",
                    label: "Start Time",
                    width: 100,
                    omitSort: true
                },

                {
                    name: "minutes",
                    label: "Mins / Miles",
                    width: 100,
                    omitSort: true
                },
                {
                    name: "reasonForService",
                    label: "Reason For Service",
                    omitSort: true
                },
                {
                    name: "performed",
                    label: "Status",
                },
                {
                    name: "createdDate",
                    label: "created",
                    width: 160,
                    omitSort: true
                },

            ],
            pagination: {
                pageNumber: 0,
                pageSize: 20,
                sortProperty: 'createdDate',
                sortDirection: 'desc'
            },
            mbtDTOptions: {
                pagination: false,
                fixedHeader: true,
                lazyLoading: true,
                isLoading: false
            }
        }
    }


    render() {

        const { t, handleServicesClose, serviceDialogOpen, recentServices, recentServicesLoading, goToServiceList, tokenData } = this.props

        return (
            <Dialog disableEnforceFocus={true} open={serviceDialogOpen} onClose={handleServicesClose} aria-labelledby="form-dialog-title" fullWidth maxWidth='xl' >
                <DialogTitle id="form-dialog-title" className='dialog-head'>
                    <div className='heading-tab'>
                        <span>{t('LABEL_RECENTLY_ENTERED_SERVICES')}</span>
                        <IconButton edge="end" color="inherit" onClick={handleServicesClose} aria-label="close">
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className='center-flex'>

                    <div>
                        {(recentServices && recentServices.content && recentServices.content.length > 0) && (
                            <MBTDataTable
                                data={recentServices.content}
                                columns={this.state.recentServicesColumns}
                                totalRecords={recentServices.content.length}
                                options={this.state.mbtDTOptions}
                                pagination={this.state.pagination}
                                maxHeight={true}
                                RemoveHandIcon={true}
                            />
                        )}

                        {!recentServicesLoading && (!recentServices || !recentServices.content || recentServices.content.length === 0) && (
                            <p className='no-records'>There are no services created by {tokenData && tokenData.name} to display.</p>
                        )}

                        {(recentServicesLoading) && (
                            <TableSkeltonScreen errors={[]} />
                        )}
                    </div>

                </DialogContent>
                <DialogActions>
                    {(recentServices.content && recentServices.content.length > 0) && (
                        <div className='serv-list'>                        
                            <p>To see a complete list of services that you have entered, go to the <span className='link' onClick={goToServiceList}>Service List.</span></p>
                            <Button type="button" color="primary" variant="outline" className='add-button' onClick={handleServicesClose}>{t('BUTTON_CLOSE')}</Button>
                        </div>
                    )}
                </DialogActions>
            </Dialog>
        )
    }
}


const mapStateToProps = ({ general }) => ({
    recentServices: general.recentServices,
    recentServicesLoading: general.recentServicesLoading,
})

const mapDispatchToProps = dispatch => ({
    // fetchRecentServices: _ => dispatch(fetchRecentServicesAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyEnteredServicesDialog)