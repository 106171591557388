import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { StudentInformationDialog } from './StudentInformationDialog'
import { getCUPermInfoDBSIClients } from '../../constants/helpers.js'
import {
    loadClientSuggestions, createStudentsAction, fetchClientStudentSuggestionsAction, fetchStudentInfoAction,
    addStudentGoalAction, studentDocumentAction, studentDocumentFileUploadAction, studentFileRefDataAction
} from '../../actions/generalActions'
import DeleteStudentGoalCellRenderer from './CellRenderers/DeleteStudentGoalCellRenderer'
import DeleteStudentDocumentCellRenderer from './CellRenderers/DeleteStudentDocumentCellRenderer'
import DownloadStudentDocumentCellRenderer from './CellRenderers/DownloadStudentDocumentCellRenderer'
import { toast } from 'react-toastify';
import { SuccessfullyCreated, CodeError } from '../../constants/notifyConstants.jsx'

class StudentInformationDialogContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            client: null,
            clientKeyword: '',
            clientsSuggestions: [],
            clientSuggestionsLoading: false,

            student: null,
            studentKeyword: '',
            studentsSuggestions: [],
            studentSuggestionsLoading: false,
            clientNote: '',

            userType: this.props.state.userType,

            searchButtonLoading: false,
            isSubmit: false,
            skeltonErrors: [],
            totalRecords: 1,
            mbtAgentsKeyword: '',
            pagination: {
                pageNumber: 0,
                pageSize: 10,
                sortProperty: 'name',
                sortDirection: 'asc'
            },
            mbtDTOptions: {
                pagination: false,
                fixedHeader: true,
                lazyLoading: true,
                isLoading: false
            },
            studentInfoCaseLoadFields: [],
            studentInfoGeneralFields: [],
            studentInfoGoalsData: [],
            studentInfoCaseLoadColumns: [
                {
                    name: "clientName",
                    label: "Client",
                },
                {
                    name: "practitioner",
                    label: "Practitioner",
                },
                {
                    name: "groupName",
                    label: "Group Name",
                },
                {
                    name: "addedDate",
                    label: "Added"
                }
            ],
            studentInfoGoalsColumns: [
                {
                    name: "goal",
                    label: "Saved Note/Goal",
                },
                {
                    name: "created",
                    label: "Created",
                    width: 200
                },
                {
                    name: "actionObj",
                    label: "Action",
                    width: 75,
                    type: "CellRenderer",
                    cellRenderer: DeleteStudentGoalCellRenderer,
                }
            ],
            studentDocumentColumns: [
                {
                    name: "fileName",
                    label: "Documents",
                },
                {
                    name: "uploaded",
                    label: "Uploaded",
                },
                {
                    name: "downloadAction",
                    label: "Download",
                    width: 100,
                    type: "CellRenderer",
                    cellRenderer: DownloadStudentDocumentCellRenderer,
                },
                {
                    name: "deleteAction",
                    label: "Delete",
                    width: 100,
                    type: "CellRenderer",
                    cellRenderer: DeleteStudentDocumentCellRenderer,
                }
            ],
            studentDocumentUpload: false,
            studentDocuments: [],
            studentDocumentFile: [],
            studentDocumentFileUploadBtnStatus: true,
            uploadInProgress: false,
            studentFileMaxSizeInMB: 0,
            studentFileSizeValidationMessage: "",
            supportedStudentFileTypes: [],
            studentFileTypeValidationMessage: "",
            studentFileUploadInstruction: "",
            fileSizeError: false,
            fileTypeError: false,
        }

        // this._debounceHandlerAdd = _.debounce(
        //     (value) => {
        //         this.setState({ clientKeyword: value })
        //         this.props.fetchClients({ keyword: value, fieldSet: 'gen' }).then(_ => this.setClients());
        //     }, 1000)

        this._debounceHandler = _.debounce(
            (value) => {
                this.setState({ studentKeyword: value })
                this.props.fetchStudents({ fieldSet: 'si', keyword: value, clientIds: this.state.client.id }).then(_ => this.setStudents());
            }, 1000)

        this.onAddStudentNote = this.onAddStudentNote.bind(this)

    }

    componentDidMount() {
        this.props.studentFileRefDataAction().then((response) => {
            if (response && response.statusCode && response.statusCode === 200) {
                let {
                    studentFileMaxSizeInMB,
                    studentFileSizeValidationMessage,
                    studentFileTypeValidationMessage,
                    studentFileUploadInstruction,
                    supportedStudentFileTypes
                } = response.content;

                this.setState({
                    studentFileMaxSizeInMB: studentFileMaxSizeInMB * 1000000,
                    studentFileSizeValidationMessage: studentFileSizeValidationMessage,
                    studentFileTypeValidationMessage: studentFileTypeValidationMessage,
                    studentFileUploadInstruction: studentFileUploadInstruction,
                    supportedStudentFileTypes: supportedStudentFileTypes ? supportedStudentFileTypes : [],
                });
            }
        });

        if (this.props.state.userType === 'U') {

            let cUPermInfoDBSIClients = getCUPermInfoDBSIClients()

            if (cUPermInfoDBSIClients.length === 1) {
                this.setState({ client: cUPermInfoDBSIClients[0] })
            }

            this.setState({ clientsSuggestions: getCUPermInfoDBSIClients() })

        } else {
            this.props.fetchClients({ fieldSet: 'gen' }).then(_ => this.setClients());
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.clientsSuggestions && prevProps.clientsSuggestions && this.props.clientsSuggestions.content) {
            if (this.props.clientsSuggestions.content !== prevProps.clientsSuggestions.content) {
                this.setState({ clientsSuggestions: this.props.clientsSuggestions.content })
            }
        }

        if (this.props.clientSuggestionsLoading !== prevProps.clientSuggestionsLoading) {
            this.setState({ clientSuggestionsLoading: this.props.clientSuggestionsLoading })
        }

        if (this.props.studentsSuggestions && prevProps.studentsSuggestions && this.props.studentsSuggestions.content) {
            if (this.props.studentsSuggestions.content !== prevProps.studentsSuggestions.content) {
                this.setState({ studentsSuggestions: this.props.studentsSuggestions.content })
            }
        }

        if (this.props.studentSuggestionsLoading !== prevProps.studentSuggestionsLoading) {
            this.setState({ studentSuggestionsLoading: this.props.studentSuggestionsLoading })
        }

        if (this.props.studentInfo.content !== prevProps.studentInfo.content) {
            this.setState({
                studentInfoCaseLoadFields: this.props.studentInfo.content.caseLoadFields,
                studentInfoGeneralFields: this.props.studentInfo.content.generalFields,
                studentInfoGoalsData: this.props.studentInfo.content.studentGoalsData,
            })
        }

        if (prevProps.studentDocuments.content !== this.props.studentDocuments.content) {
            this.setState({ studentDocuments: this.props.studentDocuments.content })
        }
    }

    onSubmit = _ => {
        if (this.state.student && this.state.student.id) {
            this.fetchStudentInfo();
            this.props.studentDocumentAction({ studentId: this.state.student.id }).then(res => {
                if (res && res.statusCode && res.statusCode === 200) {
                    this.setState({ studentDocuments: res.content })
                }
            });
        }
    }


    setStudentInfo() {
        this.setState({ isSubmit: true })

        if (this.props.studentInfo.content && this.props.studentInfo.content.caseLoadFields && this.props.studentInfo.content.generalFields && this.props.studentInfo.content.studentGoalsData) {
            this.setState({
                studentInfoCaseLoadFields: this.props.studentInfo.content.caseLoadFields,
                studentInfoGeneralFields: this.props.studentInfo.content.generalFields,
                studentInfoGoalsData: this.props.studentInfo.content.studentGoalsData,
            })
        }
    }

    setClients() {
        if (this.props.clients && this.props.clients.content) {
            this.setState({ clients: this.props.clients.content })
        }
        if (this.props.clientsSuggestions && this.props.clientsSuggestions.content) {
            this.setState({ clientsSuggestions: this.props.clientsSuggestions.content })
        }
    }

    setStudents() {
        if (this.props.student && this.props.student.content) {
            this.setState({ student: this.props.student.content })
        }
        if (this.props.studentsSuggestions && this.props.studentsSuggestions.content) {
            this.setState({ studentSuggestions: this.props.studentsSuggestions.content })
        }
    }

    handleAutoCompleateInputChange = (value) => {
        // if (value) this._debounceHandlerAdd(value)
    }

    handleStudentInputChange = (value) => {
        this.setState({ isSubmit: false, studentInfoGeneralFields: [] });
        if (value && value.length > 2) this._debounceHandler(value);
        if (this.state.student && this.state.student.name !== value) { this.setState({ student: null }) }
    }

    handleAutoCompleateChange = (name, value) => { this.setState({ [name]: value }) }

    handleTabChange = (event, newValue) => this.setState({ value: newValue })

    handleOnChange = (event) => this.setState({ [event.target.name]: event.target.value })

    fetchStudentInfo = _ => this.props.fetchStudentInfo({ studentId: this.state.student.id }).then(_ => this.setStudentInfo());

    onAddStudentNote() {
        if (this.state.student && this.state.student.id) {
            this.props.addStudentGoal({ studentId: this.state.student.id, goal: this.state.clientNote }).then(_ => {
                this.setState({ clientNote: '' });
                this.fetchStudentInfo()
            });
        }
    }

    uploadStudentDocument = () => {
        if (this.state.studentDocumentFile.name && this.state.student && this.state.student.id) {
            this.setState({
                studentDocumentFileUploadBtnStatus: true,
                uploadInProgress: true
            });
            let params = {
                id: this.state.student.id,
                file: this.state.studentDocumentFile,
            }
            this.props.studentDocumentFileUploadAction(params).then(response => {
                if (response && response.statusCode && (response.statusCode === 200)) {
                    toast.success(<SuccessfullyCreated />)
                    this.setState({
                        studentDocumentUpload: false,
                        studentDocumentFile: [],
                    });
                    this.props.studentDocumentAction({ studentId: this.state.student.id }).then(res => {
                        if (res && res.statusCode && res.statusCode === 200) {
                            this.setState({ studentDocuments: res.content })
                        }
                    });
                } else {
                    if (response && response.validationFailures && response.validationFailures[0] && response.status === 'CLIENT_ERROR') {
                        toast.warning(<CodeError message={response.validationFailures[0].code} />)
                    }
                    this.setState({ uploadInProgress: false });
                }
            })
        }
    }

    addStudentDocument = (files) => {
        if (files.length > 0) {
            this.setState({
                studentDocumentFile: files[0],
                studentDocumentFileUploadBtnStatus: false
            })
        }
    }

    removeStudentDocument = _ => {
        this.setState({
            studentDocumentFile: [],
            studentDocumentFileUploadBtnStatus: true
        })
    }

    studentDocumentUploadOpen = () => {
        this.setState({
            studentDocumentUpload: true,
            studentDocumentFileUploadBtnStatus: true,
            uploadInProgress: false
        });
    }

    studentDocumentUploadClose = () => {
        this.setState({ studentDocumentUpload: false });
        this.removeFileError();
    }

    handleFileRejected = (rejectedFiles) => {
        if (rejectedFiles.length) {
            if (rejectedFiles[0] && rejectedFiles[0].size > this.state.studentFileMaxSizeInMB) {
                this.setState({ fileSizeError: true });
            }

            if (this.state.supportedStudentFileTypes.length && rejectedFiles[0] && !this.state.supportedStudentFileTypes.includes(rejectedFiles[0].type)) {
                this.setState({ fileTypeError: true });
            }
        }
    }

    removeFileError = () => {
        this.setState({
            fileSizeError: false,
            fileTypeError: false,
            studentDocumentFileUploadBtnStatus: true
        });
    }

    render() {

        const { t, state, handleInfoClose } = this.props
        return (
            <StudentInformationDialog
                t={t}
                props={this.props}
                state={this.state}
                onSubmit={this.onSubmit}
                handleInfoClose={handleInfoClose}
                studentInfoDialogOpen={state.studentInfoDialogOpen}
                handleAutoCompleateInputChange={this.handleAutoCompleateInputChange}
                handleStudentInputChange={this.handleStudentInputChange}
                handleAutoCompleateChange={this.handleAutoCompleateChange}
                handleTabChange={this.handleTabChange}
                handleOnChange={this.handleOnChange}
                onAddStudentNote={this.onAddStudentNote}
                redirectClose={this.props.redirectClose}
                uploadStudentDocument={this.uploadStudentDocument}
                studentDocumentUploadOpen={this.studentDocumentUploadOpen}
                studentDocumentUploadClose={this.studentDocumentUploadClose}
                addStudentDocument={this.addStudentDocument}
                removeStudentDocument={this.removeStudentDocument}
                handleFileRejected={this.handleFileRejected}
                removeFileError={this.removeFileError}
            />
        )
    }
}

const mapStateToProps = ({ general }) => ({
    loading: general.loading,
    clientSuggestionsLoading: general.clientsLoading,
    clientsSuggestions: general.clientSuggestions,
    studentSuggestionsLoading: general.studentLoading,
    studentsSuggestions: general.clientStudents,
    studentInfo: general.studentInfo,
    infoSearchLoading: general.infoSearchLoading,
    addGoalLoading: general.addGoalLoading,
    studentDocuments: general.studentDocuments,
    studentDocumentsLoading: general.studentDocumentsLoading,
})

const mapDispatchToProps = dispatch => ({
    fetchClients: keyword => dispatch(loadClientSuggestions(keyword)),
    fetchStudents: keyword => dispatch(fetchClientStudentSuggestionsAction(keyword)),
    createStudents: params => dispatch(createStudentsAction(params)),
    fetchStudentInfo: params => dispatch(fetchStudentInfoAction(params)),
    addStudentGoal: params => dispatch(addStudentGoalAction(params)),
    studentDocumentAction: params => dispatch(studentDocumentAction(params)),
    studentDocumentFileUploadAction: params => dispatch(studentDocumentFileUploadAction(params)),
    studentFileRefDataAction: params => dispatch(studentFileRefDataAction(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentInformationDialogContainer)
