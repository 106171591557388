import React, { Component } from 'react'
import Lottie from 'lottie-react-web'
import { Redirect } from 'react-router-dom'

import animation from './animation.json'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './MBTDataTable.scss'

export default class MBTDataTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            redirect: false,
            onRowClickURL: '',
            scrollAvailable: (typeof this.props.onScroll === 'function') ? true : false,
            name: this.props.name ? this.props.name : '',
            routeParamClientId: ''
        }
        this.handleScroll = this.handleScroll.bind(this)
        this.clickHandler = this.clickHandler.bind(this)
    }

    componentDidMount() {
        const el = document.querySelector('#mbt-data-table' + this.state.name)
        el.addEventListener('wheel', this.handleScroll)
        el.addEventListener('mousemove', this.handleScroll)
    }

    handleScroll = _ => {
        if (this.props.totalRecords && this.props.data) {
            if (this.props.totalRecords > this.props.data.length) {
                const el = document.querySelector('#mbt-data-table' + this.state.name)

                if (typeof this.props.onScroll === 'function' && this.props.options.lazyLoading) {
                    this.props.onScroll(this.isBottom(el))

                }
            }
        }
    }

    isBottom = el => el.getBoundingClientRect().bottom <= window.innerHeight

    clickHandler = param => {
        if (this.props.onRowClickURL) {
            this.setState({ redirect: true })
            this.setState({ id: param.id })
            this.setState({ onRowClickURL: this.props.onRowClickURL })
        }

        if (this.props.routeParamClientId) {
            this.setState({ routeParamClientId: `/${this.props.routeParamClientId}` })
        }
    }

    sortHandler = param => { this.props.onSort(param) }

    newlineText = (props) => {
        if (typeof props === 'string' || props instanceof String) {
            return props.split('\n').map(str => <p style={{ color: 'black' }}>{str}</p>)
        } else {
            return props
        }
    }

    render() {

        const { data, columns, options, totalRecords, pagination, maxHeight, RemoveHandIcon } = this.props
        const { scrollAvailable } = this.state

        if (this.state.redirect) return <Redirect to={`${this.state.onRowClickURL}${this.state.id}${this.state.routeParamClientId}`} />

        return (
            <>
                <div className='body-padding'>

                    {totalRecords && <p className='total-rec'>{data.length} of {totalRecords} Records</p>}

                    <div className={scrollAvailable ? 'container' : (maxHeight ? 'container-auto-max2' : 'container-auto')}>
                        <div className='tWrap'>

                            <div className={scrollAvailable ? 'tWrap__head' : 'tWrap__head__hide'}>
                                <table className='fixed-tbl'>
                                    <thead>
                                        <tr>
                                            {columns.map((column, i) => <th onClick={() => this.sortHandler(column.name)} key={i} name={column.name}
                                                style={column.width ? { width: column.width } : {}}
                                                className={(column.type && column.type === 'CellRenderer' && column.name === 'id') ? 'option' : (column.omitSort ? 'omit-sort' : null)}>
                                                {column.label}  {(pagination && pagination.sortDirection && column.name === pagination.sortProperty) && (pagination.sortDirection === 'asc' ? <ArrowUpwardIcon className='sortIcon' /> : <ArrowDownwardIcon className='sortIcon' />)}
                                            </th>)}
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                            <div className={scrollAvailable ? 'tWrap__body' : 'tWrap__body__hide'}>
                                <table className={`fixed-tbl ${RemoveHandIcon ? '' : 'table-hand-icon'}`}>
                                    <tbody id={`mbt-data-table${this.state.name}`}>
                                        {
                                            data.map(eachRow => {
                                                return (
                                                    <tr>
                                                        {columns.map(column => <td onClick={() => (!column.action || column.action !== true) ? this.clickHandler(eachRow) : false}
                                                            style={column.width ? { width: column.width } : {}}
                                                            className={(column.type && column.type === 'CellRenderer' && column.name === 'id') ? 'option' : null}>

                                                            {column.type && column.type === 'CellRenderer' &&
                                                                <column.cellRenderer params={eachRow[column.name]} />
                                                            }

                                                            {(!column.type || column.type !== 'CellRenderer') &&
                                                                eachRow[column.name] ? (eachRow[column.name] instanceof Array ? eachRow[column.name].map(data => data + ', ') : this.newlineText(eachRow[column.name])) : ''
                                                            }

                                                        </td>)}
                                                    </tr>
                                                )
                                            })
                                        }

                                        {options && options.isLoading &&
                                            <tr className='lottie'>
                                                {columns.map((column, index) => <td className='noBorder'> {index === ((Math.round(columns.length / 2)) - 1) && <Lottie options={{ animationData: animation }} />}  </td>)}
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>



            </>
        )
    }
}