import React, { Component } from 'react'

import { LoginSessionExtendedPopup } from './LoginSessionExtendedPopup'

export default class LoginSessionExtendedPopupContainer extends Component {

    state = {

    }

    handleSessionExtended = _ => this.props.resetTheTimerForAutologout()

    render() {

        const {
            t,
            seconds,
            sessionExtendedDialogOpen,
            handleSignOut
        } = this.props



        return (
            <LoginSessionExtendedPopup
                t={t}
                seconds={seconds}
                handleSessionExtended={this.handleSessionExtended}
                sessionExtendedDialogOpen={sessionExtendedDialogOpen}
                handleSessionExtendedDialogClose={handleSignOut}
                handleSignOut={handleSignOut}
            />
        )
    }
}
