import BackendClient from "./backendClient"

export const fetchDashboardRefData = params => BackendClient.get(`/clis-api/dashboards/dashboard-ref-data`, false, params)

export const fetchClientSuggestions = params => BackendClient.get(`/clis-api/clients/suggestions`, false, params)

export const createStudent = params => BackendClient.post(`/clis-api/students`, params)

export const fetchClientStudentSuggestions = params => BackendClient.get(`/clis-api/students/suggestions`, false, params)

export const fetchStudentInfo = params => BackendClient.get(`/clis-api/students/info`, false, params)

export const addStudentGoal = params => BackendClient.post(`/clis-api/students/${params.studentId}/goals`, params)

export const deleteStudentGoals = params => BackendClient.delete(`/clis-api/students/${params.studentId}/goals/${params.id}`)

export const fetchRecentServices = params => BackendClient.get(`/clas-api/services?fieldSet=rs`)

export const getPasswordResetEmail = params => BackendClient.openPost(`/as-api/users/password-reset`, params)

export const activationTokenVarification = params => BackendClient.openGet(`/as-api/users/account-activations/${params.token}/status`)

export const resendAccountActivationLink = params => BackendClient.openPut(`/as-api/users/account-activations?accountRequestToken=${params.token}`)

export const resendResetPasswordLink = params => BackendClient.openPost(`/as-api/users/password-reset`, params)

export const activateUser = params => BackendClient.openPost(`/as-api/users/account-activations`, params)

export const changePassword = params => BackendClient.patch(`/as-api/users/password`, params)

export const resetPasswordVarification = params => BackendClient.openGet(`/as-api/users/password-reset/${params.token}/status`)

export const resetPassword = params => BackendClient.openPatch(`/as-api/users/password/forgotten`, params)

export const studentDocumentService = params => BackendClient.get(`/dfs-api/students/${params.studentId}/files`)

export const studentDocumentFileUploadService = params => BackendClient.post(`/dfs-api/students/${params.id}/files`, params, true)

export const studentDocumentFileDownloadService = params => BackendClient.getDownload(`/dfs-api/students/${params.id}/files/${params.fileId}`, false, null, params.fileName)

export const studentDocumentFileDeleteService = params => BackendClient.delete(`/dfs-api/students/${params.id}/files/${params.fileId}`)

export const merClientsSuggetions = params => BackendClient.get(`/clis-api/clients/suggestions?fieldSet=mer`, params)

export const merYearSuggetions = params => BackendClient.get(`/clis-api/clients/mer-ref-data`, params)

export const merData = params => BackendClient.get(`/clis-api/clients/mer`, false, params );

export const merFileDownload = (params, fileName) => BackendClient.postDownload(`/dfs-api/enrollment/mer`, false, params, fileName );

export const studentFileRefDataService = params => BackendClient.get(`/dfs-api/students/file-ref-data`);
