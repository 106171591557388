import React, { Component } from 'react'
import { connect } from "react-redux"
// import clsx from 'clsx'
import { compose } from 'redux'
import { withRouter } from "react-router"
import { css } from 'aphrodite-jss'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
// import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { withNamespaces } from 'react-i18next'
import { toast } from 'react-toastify';
// import _ from 'lodash'
import * as decode from 'jwt-decode';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from '../header/header'
import Navigation from '../Navigation/Navigation'
import { logoutUser } from '../../actions/sessionActions'
import { openDrawer, closeDrawer } from '../../actions/navActions'
import {
    checkLoginStatus,
    checkUserSessionActionStartedAlready,
    updateHowMuchTimePassedWithUserActiveSession
} from '../../actions/sessionActions'
import { screenModules } from '../../constants/routesPath'
import { capitalizeFirstLetter } from '../../services/stringHelperService'
import { LocalStorageService } from '../../services/localStorageService'
import LoginSessionExtendedPopupContainer from '../../components/LoginSessionExtendedPopup/LoginSessionExtendedPopupContainer'
import { authByRefreshToken, authenticateByRefreshToken } from '../../services/authService'
import { getTimeoutValues } from '../../constants/helpers.js'

import {
    customScreenType,
    customScreenSizes
} from '../../constants/mediaConstants'

import './layout.scss'
import { styles } from './layout.jsx'

class Layout extends Component {

    constructor(props) {
        super(props)
        this.state = {
            store: "",
            moduleName: "",
            currentLink: "",
            loggedInTime: 0,
            setAutoLogtout: false,
            sessionExtendedDialogOpen: false,
            timerStarted: false,
            timeoutValues: [],
            seconds: 9000
        }
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.intervalId = null
    }
    componentDidMount() {
        window.addEventListener("storage", e =>
            this.setState({ store: e.newValue })
        );
        this.handleSnackClose()
        this.handleSnackClose2()
    }

    componentWillMount() {
        this.checkTheUrlThenUpdateState()
        this.handleTheTopHeaderMobileResponsiveness()
        window.addEventListener("resize", this.updateDimensionsEventHandler, true)
        this.checkForTheUrlHasRelavantPermissions(this.props.permissions, this.props.location.pathname)
    }

    componentWillReceiveProps() {
        const { isLoggedIn, checkUserSessionStarted } = this.props
        this.checkTheUrlThenUpdateState()
        if (isLoggedIn && !checkUserSessionStarted) {
            this.props.checkUserSessionActionStartedAlready()
            // this.intervalId = setInterval(this.checkMouseMovement, 10000)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            const urlSegments = this.props.location.pathname.split('/')
            this.setState({ currentLink: urlSegments[2] ? urlSegments[2] : urlSegments[1] })
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId)
    }

    checkForTheUrlHasRelavantPermissions = (permissions, currentPath) => {
        /* TODO */
        // Once the basic permissions is allocated with each URL then you can implement the method body to fix the permissions handlling
    }

    updateDimensionsEventHandler = _ => this.handleTheTopHeaderMobileResponsiveness()

    handleTheTopHeaderMobileResponsiveness = _ => {
        if (window.innerWidth > customScreenSizes.medium) {
            this.setState({ screenType: customScreenType.large, isMobileView: false })
            this.handleDrawerOpen()
        } else if ((window.innerWidth <= customScreenSizes.medium) && (window.innerWidth > customScreenSizes.small)) {
            this.setState({ screenType: customScreenType.medium, isMobileView: false })
            this.handleDrawerOpen()
        } else if ((window.innerWidth <= customScreenSizes.small) && (window.innerWidth > customScreenSizes.mobile)) {
            this.setState({ screenType: customScreenType.small, isMobileView: true })
            this.handleDrawerClose()
        } else if (window.innerWidth <= customScreenSizes.mobile) {
            this.setState({ screenType: customScreenType.mobile, isMobileView: true })
            this.handleDrawerClose()
        }
    }

    resetTheTimerForAutologout = _ => {
        localStorage.setItem("mouseLastMovedTime", Date.now());
        this.setState({ loggedInTime: 0, sessionExtendedDialogOpen: false })

        const refreshToken = localStorage.getItem('refreshToken')
        const authenticated = authenticateByRefreshToken(refreshToken);

        authenticated.then(resp => {

            if (!resp) {
                LocalStorageService.clearTokens()
                window.location.href = '/'
            }
            if (resp && resp.data && resp.data.access_token) {
                LocalStorageService.updateTokens(resp.data)
            } else {
                LocalStorageService.clearTokens()
                window.location.href = '/'
            }
        }).catch((error) => {
            LocalStorageService.clearTokens()
            window.location.href = '/'
        })
    }

    checkTheUrlThenUpdateState = _ => {
        const urlSegments = window.location.href.split('/')
        const selectedIndex = screenModules.findIndex(el => (el && el.module === urlSegments[4]))
        if (selectedIndex !== -1) {
            this.setState({ moduleName: capitalizeFirstLetter(screenModules[selectedIndex].module) })
            if (urlSegments[5]) {
                this.setState({ currentLink: urlSegments[5] && urlSegments[5] })
            } else {
                this.setState({ currentLink: urlSegments[4] && urlSegments[4] })
            }
        }
    }

    checkMouseMovement = _ => {
        const timeoutValues = getTimeoutValues();
        // since component will mount call twise not +10 but +5
        this.setState({
            loggedInTime: this.state.loggedInTime + 5,
            timerStarted: true,
            timeoutValues: timeoutValues
        });

        if (!localStorage.getItem("mouseLastMovedTime")) {
            localStorage.setItem("mouseLastMovedTime", Date.now());
        } else {

            let expireTimeSec = (this.state.currentLink === 'caseload') ? timeoutValues.clSTimeout : timeoutValues.genSTimeout;
            let expireTimeNotifySec = (this.state.currentLink === 'caseload') ? timeoutValues.clSExpNtyBefore : timeoutValues.genSExpNtyBefore;
            let mouseLastMovedTime = parseInt(localStorage.getItem("mouseLastMovedTime"));
            let expireTime = mouseLastMovedTime + (expireTimeSec * 1000);
            let expireTimeNotify = expireTime - (expireTimeNotifySec * 1000);

            // With out mouse movement. Show session extend pop up (genSExpNtyBefore)
            if (expireTimeNotify <= Date.now() && expireTime > Date.now()) {
                this.setState({ seconds: ((expireTime - Date.now()) / 1000).toFixed(0) });
                this.showLoginTimeExtendedPopupWindow()
                this.startTimer()
            }

            // With out mouse movement. Auto log out
            if (expireTime <= Date.now()) {
                localStorage.removeItem('mouseLastMovedTime')
                this.props.logoutUser()
                LocalStorageService.clearLocalStorage()
                window.location.href = '/'
            }
        }

        // if the user move mouse and active check token time out and refresh automaticaly
        if (this.state.loggedInTime < 20 && this.state.sessionExtendedDialogOpen !== true) {

            const token = localStorage.getItem('accessToken');
            var decodedToken = decode(token, { complete: true });
            var dateNow = new Date();

            if (decodedToken.exp < ((dateNow.getTime()) / 1000) + 200) {
                authByRefreshToken().then(resp => {
                    if (!resp) {
                        LocalStorageService.clearTokens()
                        window.location.href = '/'
                    }
                    if (resp && resp.data && resp.data.access_token) {
                        LocalStorageService.updateTokens(resp.data)
                        return true;
                    } else {
                        LocalStorageService.clearTokens()
                        window.location.href = '/'
                    }
                }).catch((error) => {
                    LocalStorageService.clearTokens()
                    window.location.href = '/'
                })
            }
        }
    }

    showLoginTimeExtendedPopupWindow = _ => this.setState({ sessionExtendedDialogOpen: true })

    handleSignOut = _ => {
        this.setState({ sessionExtendedDialogOpen: false, setAutoLogtout: true })
        this.props.logoutUser()
        LocalStorageService.clearLocalStorage()
        window.location.href = '/'
    }

    handleExpandedSubMenuClose = _ => this.setState({ moduleName: "" })

    handleExpandedSubMenuOpen = menu => this.setState({ moduleName: menu })

    handleDrawerOpen = _ => this.props.openDrawer()

    // handleDrawerClose() { }
    handleDrawerClose = _ => this.props.closeDrawer()

    setMouseMove(e) {
        // e.preventDefault();
        if (!localStorage.getItem("mouseLastMovedTime")) {
            localStorage.setItem("mouseLastMovedTime", Date.now());
        } else {
            const timeoutValues = getTimeoutValues();
            let expireTimeSec = (this.state.currentLink === 'caseload') ? timeoutValues.clSTimeout : timeoutValues.genSTimeout;
            let expireTimeNotifySec = (this.state.currentLink === 'caseload') ? timeoutValues.clSExpNtyBefore : timeoutValues.genSExpNtyBefore;
            let mouseLastMovedTime = parseInt(localStorage.getItem("mouseLastMovedTime"));
            let expireTime = mouseLastMovedTime + (expireTimeSec * 1000);
            let expireTimeNotify = expireTime - (expireTimeNotifySec * 1000);

            if (expireTimeNotify <= Date.now()) {
                this.checkMouseMovement();
            } else {
                localStorage.setItem("mouseLastMovedTime", Date.now());
            }
        }

        this.setState({ loggedInTime: 0 });
        if (this.state.timerStarted === false && !this.intervalId) {
            this.intervalId = setInterval(this.checkMouseMovement, 5000)
        }
    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({ seconds: seconds });

        if (seconds === 0) {
            clearInterval(this.timer);
        }
    }

    handleSnackClose = () => { localStorage.removeItem('GlbSnkTyp') }
    handleSnackClose2 = () => { localStorage.removeItem('GlbSnkTyp2') }
    handleSnackClose3 = () => { localStorage.removeItem('GlbSnkTyp3') }

    handleSnackOpen = () => (localStorage.getItem('GlbSnkTyp')) ? true : false
    handleSnackOpen2 = () => (localStorage.getItem('GlbSnkTyp2')) ? true : false
    handleSnackOpen3 = () => (localStorage.getItem('GlbSnkTyp3')) ? true : false

    getSnackMsg = () => {
        if (localStorage.getItem('GlbSnkTyp') > 0) {
            return "PDF Export Initiated. Please wait."
        }
    }
    getSnackMsg2 = () => {
        if (localStorage.getItem('GlbSnkTyp2') > 0) {
            return "Excel Export Initiated. Please wait."
        }
    }

    getSnackMsg3 = () => {
        if (localStorage.getItem('GlbSnkTyp3') > 0) {
            return "File Download Initiated. Please wait."
        }
    }

    render() {
        toast.configure();

        const {
            t,
            // theme, 
            // classes,
            children,
            drawerOpen,
            isLoggedIn,
            changePwd,
        } = this.props

        const {
            moduleName,
            currentLink,
            sessionExtendedDialogOpen
        } = this.state

        return (
            isLoggedIn && !changePwd ?
                <div className={css(styles.root)} onMouseMove={e => this.setMouseMove(e)} id={`scroll-layout`} onLoad={e => this.setMouseMove(e)} >
                    <CssBaseline />

                    <Snackbar
                        open={this.handleSnackOpen()}
                        className="snackbar"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        message={this.getSnackMsg()}
                        color="inherit"
                        action={
                            <>
                                <CircularProgress size={20} thickness={4} />
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    />

                    <Snackbar
                        open={this.handleSnackOpen2()}
                        className="snackbar"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        message={this.getSnackMsg2()}
                        color="inherit"
                        action={
                            <>
                                <CircularProgress size={20} thickness={4} />
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackClose2}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    />

                    <Snackbar
                        open={this.handleSnackOpen3()}
                        className="snackbar"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        message={this.getSnackMsg3()}
                        color="inherit"
                        action={
                            <>
                                <CircularProgress size={20} thickness={4} />
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackClose3}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    />

                    <AppBar position="fixed" className={css(styles.appBar)}>
                        <Toolbar className="toobar__custom-style">
                            {!drawerOpen &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    id="hamburgerIcon"
                                    aria-label="open drawer"
                                    onClick={this.handleDrawerOpen}
                                    className={css(styles.menuButton)}>

                                    <MenuIcon />
                                </IconButton>
                            }
                            <Header />
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        className={css(styles.drawer)}
                        open={drawerOpen}
                    >
                        <div className={css(styles.toolbar)}>
                            {/* <IconButton className="mini__drawer-btn" onClick={this.handleDrawerClose}><MenuIcon /> </IconButton> */}
                            <Typography variant="h6" noWrap className={css(styles.appbarBgImage)}></Typography>
                        </div>
                        <Navigation
                            moduleName={moduleName}
                            currentLink={currentLink}
                            expandedSubMenuOpen={this.handleExpandedSubMenuOpen}
                            expandedSubMenuClose={this.handleExpandedSubMenuClose}
                        />
                    </Drawer>
                    <LoginSessionExtendedPopupContainer
                        t={t}
                        seconds={this.state.seconds}
                        state={this.state}
                        sessionExtendedDialogOpen={sessionExtendedDialogOpen}
                        resetTheTimerForAutologout={this.resetTheTimerForAutologout}
                        handleSignOut={this.handleSignOut}
                    />
                    <div className={css(styles.content)}>
                        <div className={css(styles.toolbar)} />
                        {children}
                    </div>
                </div> : children
        )
    }

}

const mapStateToProps = ({ navigation, session }) => ({
    expire: session.expire,
    drawerOpen: navigation.open,
    isLoggedIn: session.isLoggedIn,
    changePwd: session.changePwd,
    permissions: session.permissions,
    moduleName: navigation.moduleName,
    checkUserSessionStarted: session.checkUserSessionStarted
})

const mapDispatchToProps = dispatch => ({
    logoutUser: _ => dispatch(logoutUser()),
    openDrawer: _ => dispatch(openDrawer()),
    closeDrawer: _ => dispatch(closeDrawer()),
    checkLoginStatus: _ => dispatch(checkLoginStatus()),
    checkUserSessionActionStartedAlready: _ => dispatch(checkUserSessionActionStartedAlready()),
    updateHowMuchTimePassedWithUserActiveSession: currentValue => dispatch(updateHowMuchTimePassedWithUserActiveSession(currentValue))
})

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(Layout))
