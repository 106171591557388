import BackendClient from './backendClient'
import { grantType } from '../constants/authConstants'
import * as decode from 'jwt-decode';
import { LocalStorageService } from '../services/localStorageService'

export const basicAuthenticate = (username, password, captchaValue) => {
    let authObject = {}
    if (captchaValue) {
        authObject = {
            "grant_type": grantType,
            "username": username,
            "password": password,
            "captcha_response": captchaValue
        }
    } else {
        authObject = {
            "grant_type": grantType,
            "username": username,
            "password": password
        }
    }
    return BackendClient.post("/oauth/token", authObject, false, 'basic', true)
}

export const revokeToken = _ => BackendClient.post("auth/revoke-token")

export const logout = _ => BackendClient.post("/as-api/logout", {}, false)

export const passwordCreateService = params => BackendClient.patch(`/as-api/users/${params.userId}/password/enforced`, params, false);

// const authByRefreshToken = (refreshToken) => {
//     return BackendClient.authPost("/oauth/token", { "grant_type": 'refresh_token', "refresh_token": refreshToken })
// }

export const authByRefreshToken = (refreshToken = localStorage.getItem('refreshToken')) => {
    return BackendClient.authPost("/oauth/token", { "grant_type": 'refresh_token', "refresh_token": refreshToken })
}

export function isValidToken() {

    const accessToken = localStorage.getItem('accessToken')

    const refreshToken = localStorage.getItem('refreshToken')

    if (refreshToken && accessToken) {

        const decryptedToken = decryptToken(accessToken);

        const isAccessTokenValid = checkTokenExpired(decryptedToken);

        if (isAccessTokenValid) {
            return true;
        } else {
            return 'refresh';
        }

    } else if (refreshToken && !accessToken) {

        const decryptedToken = decryptToken(refreshToken);

        const isRefreshTokenValid = checkTokenExpired(decryptedToken);

        if (isRefreshTokenValid) {
            return 'refresh';

        } else {
            LocalStorageService.clearTokens();
            return false;
        }

    } else {
        LocalStorageService.clearTokens();
        return false;
    }
}

export function autoRefresToken() {

    const refreshToken = localStorage.getItem('refreshToken')
    const accessToken = localStorage.getItem('accessToken')

    if (refreshToken) {
        const decryptedRefreshToken = decryptToken(refreshToken);
        const decryptedAccessToken = decryptToken(accessToken);
        const tokenTimeOut = decryptedAccessToken.exp - 300 // token expiration time - 5 mins
        const isRefreshTokenValid = checkTokenExpired(decryptedRefreshToken);

        if (isRefreshTokenValid) {
            return setInterval(function () {
                authByRefToken(refreshToken)
            }, tokenTimeOut);
        }
    }
}

function authByRefToken(refreshToken) {
    authByRefreshToken(refreshToken).then(resp => {
        if (resp && resp.data && resp.data.access_token) {
            LocalStorageService.updateTokens(resp.data)
        } else {
            console.log('Auth By RefreshToken error');
        }
    }).catch((error) => { console.log(error) })

}

export function authenticateByRefreshToken(refreshToken) {
    return authByRefreshToken(refreshToken)
}

const decryptToken = (token) => decode(token)

const checkTokenExpired = (tokenPayload) => tokenPayload.exp > Math.floor(Date.now() / 1000)

export function getTokenAuthorities() {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
        const decryptedToken = decryptToken(accessToken);
        return decryptedToken.authorities ? decryptedToken.authorities : []
    } else {
        return
    }
}

export function getCUPermInfo() {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
        const decryptedToken = decryptToken(accessToken);
        return decryptedToken.cuPermInfo ? decryptedToken.cuPermInfo : []
    } else {
        return
    }
}

export function getUserType() {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
        const decryptedToken = decryptToken(accessToken);
        return decryptedToken.user_type ? decryptedToken.user_type : []
    } else {
        return
    }
}

export function getSessionTimeoutValues() {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
        const decryptedToken = decryptToken(accessToken);
        return decryptedToken.genSTimeout ?
            {
                genSTimeout: decryptedToken.genSTimeout,
                genSExpNtyBefore: decryptedToken.genSExpNtyBefore,
                clSTimeout: decryptedToken.clSTimeout,
                clSExpNtyBefore: decryptedToken.clSExpNtyBefore,
            }
            : []
    } else {
        return []
    }
}

export function isTokenValid() {
    const decryptedToken = decryptToken(localStorage.getItem('accessToken'));
    return checkTokenExpired(decryptedToken);
}
