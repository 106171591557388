import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import GetAppIcon from '@material-ui/icons/GetApp';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { studentDocumentFileDownloadAction } from '../../../actions/generalActions'
import { toast } from 'react-toastify';
import { SuccessfullyCreated } from '../../../constants/notifyConstants';
import { responseCodeSuccess } from '../../../constants/authConstants';

class DownloadStudentDocumentCellRenderer extends Component {

    state = {
        downloadBtnStatus: false
    }

    handleDownloadable = _ => {
        this.setState({ downloadBtnStatus: true });
        let toastId = toast.success(<SuccessfullyCreated message="Requesting file from server..." />);
        let params = {
            fileName: this.props.params.fileName,
            id: this.props.params.studentId,
            fileId: this.props.params.fileId
        }
        this.props.studentDocumentFileDownloadAction(params).then((response) => {
            if (response === responseCodeSuccess) {
                toast.success(<SuccessfullyCreated message="Student Document Downloaded" />)
            }
            toast.dismiss(toastId);
            this.setState({ downloadBtnStatus: false });
        });
    }

    render() {
        return (
            <>
                {this.props.params.allowed &&
                    (!this.state.downloadBtnStatus ?
                        <Tooltip title="Download">
                            <IconButton onClick={this.handleDownloadable}>
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Download in progress">
                            <IconButton>
                                <CircularProgress size={20} color="success" />
                            </IconButton>
                        </Tooltip>
                    )
                }
            </>
        )
    }
}

const mapStateToProps = ({ general }) => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    studentDocumentFileDownloadAction: params => dispatch(studentDocumentFileDownloadAction(params))
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(DownloadStudentDocumentCellRenderer)