import * as actions from "../actions/generalActions"

const initialState = {
    infoSearchLoading: false,
    addGoalLoading: false,
    loading: false,
    clientsLoading: false,
    studentLoading: false,
    clientSuggestions: [],
    clientStudents: [],
    dashboardRefData: [],
    studentInfo: [],
    recentServices: [],
    recentServicesLoading: false,
    studentDocuments: [],
    studentDocumentsLoading: false,
    merClientSuggestions: [],
    merClientSuggestionsLoading: false,
    merYearSuggestions: [],
    merYearSuggestionsLoading: false
}

export const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GENERAL_ACTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.GENERAL_ACTION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.GENERAL_ACTION_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }

        case actions.FETCH_CLIENTS_SUGGESTIONS_REQUEST:
            return {
                ...state,
                clientsLoading: true,
                clientSuggestions: action.payload
            }

        case actions.FETCH_CLIENTS_SUGGESTIONS_SUCCESS_REQUEST:
            return {
                ...state,
                clientsLoading: false,
                clientSuggestions: action.payload
            }

        case actions.FETCH_CLIENT_STUDENTS_SUGGESTION_REQUEST:
            return {
                ...state,
                studentLoading: true
            }

        case actions.FETCH_CLIENT_STUDENTS_SUGGESTION_SUCCESS_REQUEST:
            return {
                ...state,
                clientStudents: action.payload,
                studentLoading: false
            }

        case actions.FETCH_RECENT_SERVICES_REQUEST:
            return {
                ...state,
                recentServicesLoading: true,
                recentServices: []
            }

        case actions.FETCH_RECENT_SERVICES_REQUEST_SUCCESS:
            return {
                ...state,
                recentServices: action.payload,
                recentServicesLoading: false
            }

        case actions.FETCH_DASHBOARD_REF_SUCCESS_REQUEST:
            return {
                ...state,
                dashboardRefData: action.payload,
            }

        case actions.FETCH_STUDENT_INFO_REQUEST:
            return {
                ...state,
                infoSearchLoading: true,
            }

        case actions.FETCH_STUDENT_INFO_SUCCESS_REQUEST:
            return {
                ...state,
                studentInfo: action.payload,
                infoSearchLoading: false,
            }

        case actions.ADD_STUDENT_GOAL_REQUEST:
            return {
                ...state,
                addGoalLoading: true,
            }

        case actions.ADD_STUDENT_GOAL_SUCCESS_REQUEST:
            return {
                ...state,
                addGoalLoading: false,
            }
        case actions.STUDENT_DOCUMENT:
            return {
                ...state,
                studentDocumentsLoading: true
            }
        case actions.STUDENT_DOCUMENT_SUCCESS:
            return {
                ...state,
                studentDocumentsLoading: false,
                studentDocuments: action.payload
            }
        case actions.STUDENT_DOCUMENT_FAILURE:
            return {
                ...state,
                studentDocumentsLoading: false,
            }
        case actions.MER_CLIENT_SUGGESIONS_REQUEST:
            return {
                ...state,
                merClientSuggestionsLoading: true,
                merClientSuggestions: action.payload
            }

        case actions.MER_CLIENT_SUGGESIONS_LIST_SUCCESS:
            return {
                ...state,
                merClientSuggestionsLoading: false,
                merClientSuggestions: action.payload
            }
        case actions.MER_YEAR_SUGGESIONS_REQUEST:
            return {
                ...state,
                merYearSuggestionsLoading: true,
                merYearSuggestions: action.payload
            }

        case actions.MER_YEAR_SUGGESIONS_REQUEST_SUCCESS:
            return {
                ...state,
                merYearSuggestionsLoading: false,
                merYearSuggestions: action.payload
            }
    
        default:
            return state
    }
}