import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { isValidToken, autoRefresToken } from './services/authService'
// import NotFound from './screens/404/404'
import { authorities } from './constants/permissionsEnums'

const PrivateRoute = ({ component: Component, ...rest }) => {

    const allowedModules = localStorage.getItem('allowedModules')
    const permissions = localStorage.getItem('permissions')

    if (rest.location.pathname.includes("/opticlaim/service-entry") && !permissions.includes(authorities.SERVICE_ENTRY_SUBMIT)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/opticlaim/caseload") && !permissions.includes(authorities.CASELOAD_SEARCH)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/opticlaim/service-list") && !permissions.includes(authorities.SERVICE_LIST_SEARCH)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/opticlaim/submissions") && !permissions.includes(authorities.SEARCH_PREVIOUS_SUBMISSIONS)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    }

    else if (rest.location.pathname.includes("/optireports/service-report") && !permissions.includes(authorities.VIEW_EXPORT_SERVICE_REPORT)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/optireports/claim-detail-report") && !permissions.includes(authorities.VIEW_EXPORT_CLAIM_DETAILS_REPORT)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/optireports/practitioner-assessments-report") && !permissions.includes(authorities.VIEW_EXPORT_PRACTITIONER_TRACKING_REPORT)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/optireports/student-report") && !permissions.includes(authorities.VIEW_EXPORT_STUDENT_REPORT)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/optireports/practitioner-report") && !permissions.includes(authorities.VIEW_EXPORT_PRACTITIONER_REPORT)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/optireports/general-clients-information-report") && !permissions.includes(authorities.VIEW_EXPORT_CLIENT_INFO_REPORT)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if (rest.location.pathname.includes("/optireports/practitioner-credentials-report") && !permissions.includes(authorities.VIEW_EXPORT_PRACTITIONER_CREDENTIAL_REPORT)) {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    }

    if ((!allowedModules || !allowedModules.length || !allowedModules.includes("OC")) && rest.path === '/opticlaim') {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if ((!allowedModules || !allowedModules.length || !allowedModules.includes("AD")) && rest.path === '/administration') {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if ((!allowedModules || !allowedModules.length || !allowedModules.includes("OR")) && rest.path === '/optireports') {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if ((!allowedModules || !allowedModules.length || !allowedModules.includes("FP")) && rest.path === '/fileprocessor') {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else if ((!allowedModules || !allowedModules.length || !allowedModules.includes("OL")) && rest.path === '/optilearn') {
        return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/forbidden', state: props.location.pathname }} />)} />);
    } else {

        const validToken = isValidToken()

        let interval = autoRefresToken();

        if (interval > 25) clearInterval(interval)

        if (!validToken) clearInterval(interval)

        if (validToken === true) {
            return (<Route {...rest} render={props => (<Component {...props} />)} />);
        } else if (validToken === 'refresh') {
            return (<Route {...rest} render={props => (<Redirect to={{ pathname: '/authguard', state: props.location.pathname }} />)} />);
        } else {
            localStorage.setItem('prevPath', window.location.href)
            window.location.href = '/'
        }
    }
};

export default PrivateRoute;